import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
// import { Observable } from "rxjs";
import { map } from "rxjs/operators";
// import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from "@angular/material/dialog";
import { ChangeUserPassComponent } from "./change-user-pass/change-user-pass.component";
import { environment } from "../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
// import { DBbackupComponent } from "../../../backup/db-backup/db-backup.component";
// import { ConfirmDialogComponent } from "../../../backup/confirm-dialog/confirm-dialog.component";
// import { CompanyManagementComponent } from "../../../backup/company-management/company-management.component";
import { SocketServiceService } from "../socket-service.service";
import { Howl, Howler } from "howler"; // npm install --save-dev @types/howler
import {
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { CacheServiceService } from "../cache-service.service";
import { NgxSpinnerService } from "ngx-spinner";
// import { BooktableComponent } from "../../../backup/rst-dashboard/booktable/booktable.component";
import { OrderConfirmationComponent } from "./order-confirmation/order-confirmation.component";
import { AutoAcceptDialogeComponent } from "./auto-accept-dialoge/auto-accept-dialoge.component";
import { SharedModule } from "../common.module";
import { AppNavService } from "../_services/app-nav.service";
import { RstDashboardService } from "../_services/rst-dashboard.service";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { CompanyManagementComponent } from "../company-management/company-management.component";
import { BooktableComponent } from "../rst-dashboard/booktable/booktable.component";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DBbackupComponent } from "../db-backup/db-backup.component";

@Component({
  selector: "app-app-nav",
  templateUrl: "./app-nav.component.html",
  styleUrls: ["./app-nav.component.css"],
  standalone: true,
  imports: [SharedModule, SearchBarComponent],
})
export class AppNavComponent {
  helpDeskUrl: any;
  rightMenuOpen = false;
  websiteData:any;
  isMenuOpen = true;
  contentMargin = 250;
  userName: any;
  width: any = window.innerWidth;
  is_trial: any;
  allow_multicompany: any;
  params1: any;
  params2: any;
  connection: any;
  is_connected: boolean = false;
  isDarkTheme: boolean = false;
  checkEnableResturantFlag: boolean = false;
  websiteInvoiceData: any;
  websiteInvoicecount: any;
  checkEnablewebsiteFlag: boolean = false;

  selectedSearchFieldValue: any;
  UnApprovedReservedTablesArr: any = [];
  unApprovedRequestCount: any = 0;
  requestCount: any = 0;
  currentUrl: any;
  panelOpenState: boolean = false;
  isMuted: boolean = false;
  showDetails: boolean = false;
  isApprovedChecked: boolean = false;
  isRejectedChecked: boolean = false;
  permanentMute: boolean = false;
  permanentApproved: boolean = false;
  permanentRejected: boolean = false;
  sound: Howl | null = null;

  // apiService:any
  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    //
    private apiService: AppNavService,
    private apiService2: RstDashboardService,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    private router: Router,
    private cacheService: CacheServiceService,
    private spinner: NgxSpinnerService
  ) {
    this.currency = environment.currency;
  }

  isSearchExpanded: boolean = false;
  searchQuery: any = "";

  toggleSearch() {
    this.isSearchExpanded = !this.isSearchExpanded;
  }


  localset() {
    this.apiService.getInitialSetting().subscribe((result: any) => {
      if (result.Currency) {
        localStorage.setItem("currency", result.Currency);
      }
      if ("Website" in result) {
        localStorage.setItem("Website", result.Website);
      }
      if ("POS" in result) {
        localStorage.setItem("POSEnable", result.POS);
      }
      if ("company" in result) {
        localStorage.setItem("CompanyInfo", JSON.stringify(result));
      }
      if (result.menu.length) {
        localStorage.setItem("menuData", JSON.stringify(result.menu));
      } else {
        this.apiService.getMenuGroupList().subscribe((result: any) => {
          localStorage.setItem("menuData", JSON.stringify(result));
        });
      }
      if (result.menuItem.length) {
        localStorage.setItem("menuItemData", JSON.stringify(result.menuItem));
      } else {
        this.apiService.getMenuPermissionList().subscribe((result: any) => {
          localStorage.setItem("menuItemData", JSON.stringify(result));
        });
      }

      localStorage.setItem("isTransactionGSTslab", result.isTransactionGSTslab);
      localStorage.setItem("isEBillingEnable", result.isEBillingEnable);
      localStorage.setItem("roundoff", result.roundoff);
      

      const orderMode = "website";
      this.apiService
        .getWebsiteInvoice(orderMode)
        .subscribe((result: any) => {
          this.websiteInvoiceData = result.session;
          this.websiteInvoicecount = result.session.length;
          this.setnotificationCount(this.kotCount, this.websiteInvoicecount);
          // this.playWebsiteNotificationSound();
          this.playNotificationSound()
          // localStorage.setItem("websiteinvoce", JSON.stringify(result));
        });

      setTimeout(() => {
        this.load();
        this.generate_token();
      }, 500);
    });
  }

  ngOnInit() {
    const groups = ["Entry", "Report", "Master", "Setting", "Transaction"];
    this.currentUrl = this.router.url;
    const parts = this.currentUrl.split("/"); // Split the path by '/'
    const entry = parts[1]; // Access the first element of the array
    groups.forEach((e: any) => {
      if (e === entry) {
        this.groupname = entry == "Entry" ? "Transaction" : entry;
      }
    });
    this.is_trial = localStorage.getItem("is_trial");
    this.allow_multicompany = localStorage.getItem("allow_multicompany");
    this.isMenuOpen = false;

    this.getNotApprovedKot().then((result: any) => {
      this.playNotificationSound();
      if (this.kotCount == 0) {
        this.rightMenuOpen = false;
      } else {
        this.rightMenuOpen = true;
      }
    });
    this.connectSocket();
    this.localset();

    /* Getting restaurant pos setting */
  }
  ngOnDestroy() {
    if (this.play_sound) clearInterval(this.play_sound);
    this.play_sound = undefined;

    this.connection.unsubscribe();
  }

  connectSocket() {
    this.socketService.emit("join", {
      company_id: localStorage.getItem("company_id"),
    });
    this.socketService.on("reconnect").subscribe((result: any) => {
      this.is_connected = true;
      this.getNotApprovedKot().then((result: any) => {
        this.playNotificationSound();
      });
    });
    this.socketService.on("connect").subscribe((result: any) => {
      this.is_connected = true;
    });
    this.socketService.on("disconnect").subscribe((result: any) => {
      this.is_connected = false;
    });
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        this.websiteInvoice();

        this.getNotApprovedKot().then((result: any) => {
          this.playNotificationSound();
        });
      });

    // Call socket connection for auto acccept order
    this.connection = this.socketService
      .on("autoAccept")
      .subscribe((result: any) => {
        // call api to accept order
        this.onAutoAccept(result);
        // call functions for popup
      });

    this.connection = this.socketService
      .on("websiteInvoice")
      .subscribe((result: any) => {
        // this.websiteInvoice(result)
        this.websiteInvoice()
          .then((result: any) => {
            // this.playWebsiteNotificationSound();
            this.playNotificationSound()
          })
          .catch((error: any) => {
            // Handle any errors that occur during the Promise execution.
            console.error(error);
          });
      });
    this.connection = this.socketService
      .on("tableReservation")
      .subscribe((result: any) => {
        this.getResetvedTableDetails();
      });
    this.connection = this.socketService
      .on("updateAccountMap")
      .subscribe((result: any) => {
        // this.websiteInvoice(result)
        this.websiteEnablestatus();
        // this.websiteInvoice();
      });
    if (this.socketService.checkConnectionStatus()) {
      this.is_connected = true;
    }
  }
  groupname: any = "Home";
  show: boolean = true;
  sliderdata_name: any = [];
  ischecked: boolean = false;
  themes: any = "light";
  playWebsiteNotificationSoundstatus: boolean = true;
  play_sound: any;
  currency: any;
  kotCount: any;
  kotArr: any;
  permissionArr: any;
  menuGroupObj: any;
  menuItemObj: any = [];
  company_name: any;
  userFullName: any;
  role_id: any;
  user_id: any;
  itemArr: any = [];
  version: any;
  windowReference: any;
  userRole: any;
  isrst: any = false;
  isHandset$: any;
  notificationCount: any = 0;

  load() {
    // this.notificationCount = 0
    this.isHandset$ = this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((result) => result.matches));
    const e_pos: any = localStorage.getItem("POSEnable");

    if (e_pos !== null && e_pos !== undefined) {
      this.checkEnableResturantFlag = JSON.parse(e_pos);
      // if (this.checkEnableResturantFlag == true) {
      //   this.router.navigate(["/rst-dashboard"]);
      // } else {
      //   this.router.navigate(["/dashboard"]);
      // }
    } else {
      this.apiService.getEnableResturantPos().subscribe(
        (result: any) => {
          this.checkEnableResturantFlag = result;
          if (this.checkEnableResturantFlag == true) {
            this.router.navigate(["/rst-dashboard"]);
          } else {
            this.router.navigate(["/dashboard"]);
          }
        },
        (result: any) => { }
      );
    }

    this.websiteInvoice();
    this.websiteEnablestatus();
    //Getting enable website setting

    this.userName = localStorage.getItem("userName");
    this.isrst = localStorage.getItem("isrst");
    this.userFullName = localStorage.getItem("userFullName");

    this.role_id = localStorage.getItem("role_id");
    this.user_id = localStorage.getItem("user_id");
    this.userRole = localStorage.getItem("userRole");
    if (!this.userName) {
      this.userName = "Admin";
    }
    const Comp: any = localStorage.getItem("CompanyInfo");
    const parsed = JSON.parse(Comp);
    this.company_name = parsed.company[0].company_name;

    const cachedData = localStorage.getItem("menuData");
    const cachedItemData = localStorage.getItem("menuItemData");

    if (cachedData && cachedItemData) {
      const parsedData = JSON.parse(cachedData);
      const parsedItemData = JSON.parse(cachedItemData);
      this.menuItemObj = parsedItemData;
      this.menuGroupObj = parsedData;
      let menuGroupId = 0;

      this.menuGroupObj.filter((e: any) => {
        const displayGrp =
          this.groupname === "Setting" ? "Setting" : `${this.groupname}s`;
        if (e.menu_group_name === displayGrp) {
          menuGroupId = e.menu_group_id;
        }
      });
      let initMenuItem = this.menuItemObj.filter(
        (e: any) => e.menu_group_id === menuGroupId
      );
      this.sliderdata_name = initMenuItem;
      for (var i = 0; i < this.menuGroupObj.length; i++) {
        this.itemArr = [];
        for (var j = 0; j < this.menuItemObj.length; j++) {
          if (
            this.menuGroupObj[i].menu_group_id ==
            this.menuItemObj[j].menu_group_id
          ) {
            this.itemArr.push(this.menuItemObj[j]);
          }
        }
        this.menuGroupObj[i].menuItem = this.itemArr;
      }
    }
    this.helpDeskUrl = localStorage.getItem("help_Desk_Url");

    //this.spinner.hide();
    this.getResetvedTableDetails();
  }

  websiteEnablestatus() {
    const cachedData: any = localStorage.getItem("Website");
    if (cachedData !== null && cachedData !== undefined) {
      this.checkEnablewebsiteFlag = JSON.parse(cachedData);
    }
  }

  onAutoAccept(kot: any) {
    const dialogRef = this.dialog.open(AutoAcceptDialogeComponent, {
      width: "300px",
      data: {
        title: "Auto Approve KOT",
        preparationTime: 15,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const reqObj: any = {
        transaction_id: kot.transaction_id,
        merchant_id: 330,
        order_id: kot.order_id,
        prepartion_time: result.preparationTime,
      };
      this.apiService
        .approveWeraKot(kot.transaction_id, reqObj)
        .subscribe((result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
            this.printKot(kot);
          }
        });
    });
  }
  onApproveClick($event: any, kot: any) {
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog.open(OrderConfirmationComponent, {
        width: "400px",
        data: {
          title: "Approve",
          preparationTime: 15,
          order_mode: kot.order_mode,
          trans_no: kot.trans_no,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const reqObj: any = {
            transaction_id: kot.transaction_id,
            merchant_id: 330,
            order_id: kot.order_id,
            prepartion_time: result.preparationTime,
          };
          if (result.approved) {
            this.apiService
              .approveWeraKot(kot.transaction_id, reqObj)
              .subscribe((result: any) => {
                if (result.success) {
                  this.getNotApprovedKot();
                  this.printKot(kot);
                } else {
                  // notyf.error( "Something went Wrong");
                }
              });
          }
        }
      });
    } else {
      $event.stopPropagation();
      this.apiService.approveKot(kot.transaction_id).subscribe(
        (result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
            this.printKot(kot);
          }
        },
        (result: any) => { }
      );
    }
  }

  onRejectClick($event: any, kot: any) {
    if (
      kot.order_mode.toLowerCase() === "zomato" ||
      kot.order_mode.toLowerCase() === "swiggy"
    ) {
      const dialogRef = this.dialog
        .open(OrderConfirmationComponent, {
          width: "300px",
          data: {
            title: "Reject",
            order_mode: kot.order_mode,
            trans_no: kot.trans_no,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            const zReqObj: any = {
              transaction_id: kot.transaction_id,
              order_mode: kot.order_mode,
              order_id: kot.order_id,
              rejection_id: result.rejection_id, //go to docs for rejection id and values
            };
            const sReqObj: any = {
              transaction_id: kot.transaction_id,
              order_mode: kot.order_mode,
              order_id: kot.order_id,
              remark: result.rejection_id, //go to docs for rejection id and values
            };
            const data =
              kot.order_mode.toLowerCase() === "swiggy" ? sReqObj : zReqObj;
            if (result.approved) {
              this.apiService
                .rejectWeraKot(kot.transaction_id, data)
                .subscribe((result: any) => {
                  if (result.success) {
                    // notyf.error(result.message);

                    this.getNotApprovedKot();
                  } else {
                    // notyf.error( "Something went Wrong");
                  }
                });
            }
          }
        });
    } else {
      $event.stopPropagation();
      const reason = "";
      this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
        (result: any) => {
          if (result.success) {
            this.getNotApprovedKot();
          }
        },
        (result: any) => { }
      );
    }
  }

  orderAutoAccepted($event: any, kot: any) {
    $event.stopPropagation();

    this.apiService.approveKot(kot.transaction_id).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
          this.printKot(kot);
        }
      },
      (result: any) => { }
    );
  }
  cancelledOrder($event: any, kot: any) {
    $event.stopPropagation();
    const reason = "";
    this.apiService.deleteSalesOrder(kot.transaction_id, reason).subscribe(
      (result: any) => {
        if (result.success) {
          this.getNotApprovedKot();
        }
      },
      (result: any) => { }
    );
  }
  getNotApprovedKot() {
    return new Promise((resolve: any, reject: any) => {
      this.apiService.getSONotApproved().subscribe(
        (result: any) => {
          this.kotArr = result;
          this.kotCount = result.length;
          
          this.setnotificationCount(this.kotCount, this.websiteInvoicecount);
          resolve(true);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }
  convertFloat(val: any) {
    return parseFloat(val);
  }

  // Chnage Password

  changePass() {
    this.dialog.open(ChangeUserPassComponent, {
      height: "auto",
      width: "auto",
      data: "User",
    });
  }

  onToolbarMenuToggle() {
    this.isMenuOpen = !this.isMenuOpen;

    if (this.width <= 768) {
      if (!this.isMenuOpen) {
        this.contentMargin = 0;
      } else {
        this.contentMargin = 0;
      }
    } else {
      if (!this.isMenuOpen) {
        this.contentMargin = 0;
      } else {
        this.contentMargin = 250;
      }
    }
  }
  onrightToolbarMenuToggle() {
    this.rightMenuOpen = !this.rightMenuOpen;
  }
  onBackupClick() {
    this.dialog
      .open(DBbackupComponent, {
        width: "auto",
        height: "auto",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.windowReference = window.open(
            result,
            "_blank",
            "toolbar=0,width=400,height=600,left=500,top=50"
          );
        }
      });
  }
  dashboardClick() {
    this.sliderdata_name = [];
    this.groupname = "Home";
    this.isMenuOpen = !this.isMenuOpen;
    this.apiService2.setDashboardStatus({ key: false });
  }
  get_routerlink() {
    if (this.isrst == "true") {
      return "/rst-dashboard";
    } else {
      return "/dashboard";
    }
  }
  mobilemenuCTRL() {
    if (this.width <= 768) {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }

  redirectToCompanyInfo() {
    this.router.navigate(["/Setting/company-info"]);
  }
  onCompanyManage() {
    this.dialog.open(CompanyManagementComponent, {
      width: "800px",
    });
  }
  generate_token() {
    this.route.queryParams.subscribe((params) => {
      this.params1 = params["code"];
      if (this.params1)
        this.apiService.generate_token(this.params1).subscribe(
          (result: any) => {
            if (result.success) {
              window.close();
            }
          },
          (result: any) => {
            // notyf.error( "Something went Wrong");
          }
        );
    });
  }

  playWebNotificationSound() {
    var sound = new Howl({
      src: ["./assets/ringtone.mp3"],
    });
    if (this.kotCount > 0) {
      if (!this.play_sound) {
        sound.play();
        this.rightMenuOpen = true;
        this.play_sound = setInterval(
          function () {
            // if (this.kotCount == 0) {
            //   clearInterval(this.play_sound);
            //   this.onrightToolbarMenuToggle();
            // } else {
            //   sound.play();
            // }
          }.bind(this),
          5000
        );
      }
    } else {
      if (this.play_sound) clearInterval(this.play_sound);
      this.play_sound = undefined;
    }
  }

  // playWebsiteNotificationSound() {
  //   // var sound = new Howl({
  //   //   src: ["./assets/sound.mp3"],
  //   // });
  //   if (
  //     this.websiteInvoicecount > 0 &&
  //     this.playWebsiteNotificationSoundstatus
  //   ) {
  //     if (!this.play_sound) {
  //       // sound.play();
  //       // this.play_sound = setInterval(
  //       //   function () {
  //       //     if (this.websiteInvoicecount == 0) {
  //       //       clearInterval(this.play_sound);
  //       //     } else {
  //       //       sound.play();
  //       //     }
  //       //   }.bind(this),
  //       //   5000
  //       // );
  //     }
  //   } else {
  //     if (this.play_sound) clearInterval(this.play_sound);
  //     this.play_sound = undefined;
  //   }
  // }


//   playNotificationSound() {
//     // Check the kotCount condition first
//     if (this.kotCount > 0) {
//         // Check if the array is not empty
//         // if (this.websiteInvoiceData.length > 0) {
          
//             // Iterate through each invoice in the array
//             // for (let data of this.websiteInvoiceData) {
//                 // Check the is_muted property
//                 // if (data.is_muted === false) {
//                     if (!this.play_sound) {

//                         this.sound = new Howl({
//                             src: ["./assets/ringtone.mp3"],
//                         });

//                         this.sound?.play(); // Using optional chaining
//                         this.rightMenuOpen = true;
//                         this.play_sound = setInterval(() => {
//                             if (this.kotCount == 0) {
//                                 clearInterval(this.play_sound);
//                                 this.onrightToolbarMenuToggle();
//                             } else {
//                                 this.sound?.play(); // Using optional chaining
//                             }
//                         },60000000);
//                     }
//                     // break; // Exit the loop once a sound is played
//                 // }
//             // }
//         // }
//     } else {
//         // Handle the case when kotCount is 0
//         if (this.websiteInvoiceData && this.websiteInvoiceData.length > 0) {
//             for (let data of this.websiteInvoiceData) {
//                 // Check the is_muted property
//                 if (data.is_muted === false) {
//                     if (this.play_sound) clearInterval(this.play_sound);
//                     this.play_sound = undefined;
//                 }
//             }
//         }
//     }
// }


playNotificationSound() {
    // Initialize the sound
    this.sound = new Howl({
      src: ["./assets/ringtone.mp3"],
    });

    // Check if there are any non-muted invoices
    const hasActiveNotifications =
      this.websiteInvoiceData &&
      this.websiteInvoiceData.some((notification:any) => !notification.is_muted);
    
    if (
      this.websiteInvoicecount > 0 &&
      this.playWebsiteNotificationSoundstatus &&
      hasActiveNotifications
    ) {

      if (!this.play_sound) {
        this.sound.play();

        if (this.websiteInvoiceData.length > 0) {
          // Iterate through each invoice in the array
          this.websiteInvoiceData.forEach((data:any) => {
            const requestBody = {
              display_trans_no: data.display_trans_no,
              is_muted: true, // Set to true for muting
            };

            // Make the API call to update the mute status
            this.apiService
              .updateMuteBtn(data.display_trans_no, requestBody)
              .subscribe((result: any) => {
                if (result.success) {
                  // Update the is_muted value from the response
                  data.is_muted = result.is_muted;

                  // Set the permanentMute flag to true for this specific notification
                  data.permanentMute = true;

                  // Check if all notifications are muted
                  const allMuted = this.websiteInvoiceData.every(
                    (notification:any) => notification.is_muted
                  );

                  if (allMuted && this.play_sound) {
                    clearInterval(this.play_sound);
                    if (this.sound) {
                      this.sound.stop(); // Stop the sound immediately
                    }
                    this.play_sound = undefined;
                  }
                }
              });
          });
        }

        this.play_sound = setInterval(() => {
          if (this.websiteInvoicecount === 0) {
            clearInterval(this.play_sound);
            this.play_sound = undefined; // Ensure play_sound is cleared
          } else {
            if (this.sound) {
              this.sound.play(); // Ensure sound is played periodically
            }
          }
        },60000000);
      }
    } else {
      if (this.play_sound) {
        clearInterval(this.play_sound);
        this.play_sound = undefined; // Ensure play_sound is cleared
      }
    }
    // this.playWebNotificationSound()
  }

  printKot(kot: any) {
    this.apiService.downloadPdfByTransId(kot.transaction_id).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + kot.trans_no + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

  theme(e: any) {
    this.ischecked = !this.ischecked;
    if (this.ischecked == true) {
      this.apiService.settheme(this.ischecked);
      this.themes = "dark";
      localStorage.setItem("theme", "true");
    } else {
      localStorage.setItem("theme", "false");
      this.apiService.settheme(this.ischecked);
    }
  }

  sliderdata(data: any) {
    this.show = false;
    this.sliderdata_name = data.menuItem;
    this.groupname = data.menu_group_name;
    this.isMenuOpen = !this.isMenuOpen;
  }

  searchitems: any;
  newresultedvalue: any = "";
  // search -----------------------------------
  itemControl = new FormControl();

  applyFilter(event: any) {
    let filtename: string = this.itemControl.value;
    if (filtename != "") {
      // filtename = filtename.toLowerCase()
      if (event.key != "ArrowDown" && event.key != "ArrowUp") {
        // if (this.itemControl.value.length > 1) {
        let p: any = [];
        this.menuGroupObj.map((k: any) => {
          k.menuItem.map((j: any) => {
            p.push({ menu_name: j.menu_name, route: j.route });
          });
        });
        p = p.filter((i: any) => {
          let name: string = i.menu_name;
          name = name.toLowerCase();
          if (name.startsWith(filtename)) {
            return i;
          }
        });
        if (p.length <= 0) {
          this.searchitems = [{ menu_name: "Not Found" }];
        } else {
          this.searchitems = p;
        }
        // }
      }
    } else {
      this.searchitems = [];
    }
    if (event.key === "Enter") {
      event.preventDefault();
      this.newresultedvalue = null;
      // this.router.navigate(["/{{r}}"])
    }
  }
  routingdata: any;
  focus(n: any) {
    this.newresultedvalue = null;

    // this.searchitems = []
  }

  result_items: any;
  routing(i: any) {
    this.routingdata = "/" + i.route;
    this.searchitems = [];

    var result_items = this.menuGroupObj.filter((search_item: any) => {
      return search_item.menuItem.some(
        (subItem: any) => subItem.menu_name === i.menu_name
      );
    });
    this.groupname = result_items[0].menu_group_name;
    this.sliderdata_name = result_items[0].menuItem;
    this.selectedSearchFieldValue = "";
  }

  routingByEnterKey(i: any) {
    this.selectedSearchFieldValue = "";
    this.searchitems = [];
    var result_items = this.menuGroupObj.filter((search_item: any) => {
      return search_item.menuItem.some(
        (subItem: any) => subItem.menu_name === i.menu_name
      );
    });
    this.groupname = result_items[0].menu_group_name;
    this.sliderdata_name = result_items[0].menuItem;
    this.router.navigate(["/" + i.route]);
  }

  // --------------websiteNotification----------------
  // websiteInvoice() {
  //   const websiteInvoice = localStorage.getItem('websiteinvoce');
  //   var parsedData = JSON.parse(websiteInvoice);
  //   const result = parsedData
  //   return new Promise(
  //     function (resolve, reject) {
  //       {
  //         this.websiteInvoiceData = result.session;
  //         this.websiteInvoicecount = result.session.length;
  //         this.playWebsiteNotificationSound();
  //         resolve(true);
  //       }
  //       (err: any) => {
  //         reject(err);
  //       }

  //     }.bind(this)
  //   );
  // }
  // websiteInvoice() {
  //   const orderMode = "website";
  //   return new Promise(
  //     function (resolve:any, reject:any) {
  //       this.apiService.getWebsiteInvoice(orderMode).subscribe(
  //         (result: any) => {
  //           // this.kotArr = result.session;
  //           // this.kotCount = result.session.length;
  //           this.websiteInvoiceData = result.session;
  //           this.websiteInvoicecount = result.session.length;
  //           this.playWebsiteNotificationSound();

  //           resolve(true);
  //         },
  //         (err: any) => {
  //           reject(err);
  //         }
  //       );
  //     }.bind(this)
  //   );
  // }

  websiteInvoice() {
    const orderMode = "website";
    return new Promise((resolve, reject) => {
      this.apiService.getWebsiteInvoice(orderMode).subscribe(
        (result: any) => {
          this.websiteInvoiceData = result.session;
          this.websiteInvoicecount = result.session.length;
          this.setnotificationCount(this.kotCount, this.websiteInvoicecount);
          // this.playWebsiteNotificationSound();
          this.playNotificationSound()

          resolve(true);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  // muteAlertRingtone() {
  //   // this.router.navigate(['/rst-dashboard']);
  //   this.playWebsiteNotificationSoundstatus = false;
  //   if (this.play_sound) clearInterval(this.play_sound);
  //   this.play_sound = undefined;
  // }

  muteAlertRingtone(event: any) {
    event.stopPropagation(); // Stop event propagation
    if (!this.isMuted) {
      this.isMuted = true; // Set the muted state to true only on the first click
    }
    // this.router.navigate(['/rst-dashboard']);
    this.playWebsiteNotificationSoundstatus = false;
    if (this.play_sound) {
      clearInterval(this.play_sound);
    }
    this.play_sound = undefined;
  }
  getResetvedTableDetails() {
    this.apiService.getUnApprovedReservedTables().subscribe(
      (result: any) => {
        this.UnApprovedReservedTablesArr = result.tblReservationData;
        this.requestCount = this.UnApprovedReservedTablesArr.length;
        const count = this.UnApprovedReservedTablesArr.filter((e: any) => {
          if (e.approved != "Confirmed") {
            return e;
          }
        });
        if (count.length > 0) {
          this.unApprovedRequestCount = count.length;
        }
        // this.startTimer();
        // this.spinner.hide();
      },
      (err: any) => {
        // this.spinner.hide();
      }
    );
  }
  tableReservationDashboard() {
    this.dialog
      .open(BooktableComponent, {
        width: "800px",
      })
      .afterClosed()
      .subscribe((result) => {
        // if (result == 'Edited') {
        //   this.reset();
        // }
      });
  }

  getOrderImage(order: string): string {
    switch (order) {
      case "swiggy":
        return "assets/images/swiggy.png";
      case "zomato":
        return "assets/images/zomato.png";
      default:
        return "";
    }
  }

  kot(data: any) {
    this.apiService.downloadPdfByTransId(data.kot_transction_id).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + data.trans_no + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }

  invoice(data: any) {
    this.apiService.downloadPdfByTransId(data.transaction_id).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Invoice" + data.transaction_id + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
      },
      (result: any) => { }
    );
  }
  // logOutClicked() {
  //   this.router.navigate(["/logout"]);
  // }

  NotificationSeen(data: any, event: MatCheckboxChange) {
    // Angular Material `MatCheckboxChange` does not have `stopPropagation` directly
    // Remove the call to stopPropagation() as it's not necessary here
  
    // Check if the checkbox is checked
    event.source.checked = true;
  
    // Open the notification sidebar if it's not already open
    if (!this.rightMenuOpen) {
      this.onrightToolbarMenuToggle();
    }
  
    const requestBody = {
      display_trans_no: data.display_trans_no,
      show_details: true, // Set to true for muting
    };
  
    this.apiService.NotificationSeen(data.display_trans_no, requestBody).subscribe(
      (result: any) => {
        if (result.success) {
          // Update the is_muted value from the response
          data.show_details = result.show_details;
  
          // Set the permanentMute flag to true for this specific notification
          data.permanentMute = true;
        }
      },
      (error: any) => {
        console.error("Error muting alert:", error);
      }
    );
  }
  

  siteOrderReject(event: any, data: any) {
    // Ensure the checkbox is checked after click

    // Check if already muted
    if (data.is_muted === false) {
      if (data.is_muted) {
        if (this.sound) {
          this.sound.stop();
        }
        this.play_sound = undefined;
        return; // Exit early if already muted
      }

      const requestBody = {
        display_trans_no: data.display_trans_no,
        is_muted: true, // Set to true for muting
      };


      this.apiService
        .updateMuteBtn(data.display_trans_no, requestBody)
        .subscribe((result: any) => {
          if (result.success) {
            // Update the is_muted value from the response
            data.is_muted = result.is_muted;

            // Set the permanentMute flag to true for this specific notification
            data.permanentMute = true;

            // Check if all notifications are muted
            const allMuted = this.websiteInvoiceData.every(
              (notification: any) => notification.is_muted
            );

            if (allMuted && this.play_sound) {
              clearInterval(this.play_sound);
              if (this.sound) {
                this.sound.stop(); // Stop the sound immediately
              }
              this.play_sound = undefined;
            }
          }
        });
    }
    const requestBodyy = {
      kot_transction_id: data.kot_transction_id,
      inv_transaction_id: data.transaction_id,
      kot_rejected: true, // Set to true for muting
      active: false,
      color: true, // Set to true for muting
      disable: true, // Set to true for muting
    };

    this.apiService.OrderRejected(requestBodyy).subscribe((result: any) => {
      // Update the is_muted value from the response
      data.kot_rejected = result.kot_rejected;

      // Set the permanentMute flag to true for this specific notification
      data.permanentRejected = true;
      if (result.success) {
      }
    });
  }

  siteOrderApprove(event: any, data: any) {
    const requestBodyy = {
      kot_transction_id: data.kot_transction_id,
      kot_approved: true, // Set to true for muting
      color: true, // Set to true for muting
      disable: true, // Set to true for muting
    };

    this.apiService
      .kotApproved(data.kot_transction_id, requestBodyy)
      .subscribe((result: any) => {
        // Update the is_muted value from the response
        data.kot_approved = result.kot_approved;

        // Set the permanentMute flag to true for this specific notification
        data.permanentApproved = true;
        if (result.success) {
          this.apiService
            .downloadPdfByTransId(data.kot_transction_id)
            .subscribe(
              (result: any) => {
                let blob = new Blob([result], {
                  type: "application/pdf",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "kot" + data.trans_no + ".pdf";
                link.click();
                window.URL.revokeObjectURL(link.href);
              },
              (result: any) => { }
            );
        }
      });
  }

  setnotificationCount(webapp: any, website: any) {
    this.notificationCount = webapp;
  }

  riderInvoice(id: any,trans_no:any) {
    if (!trans_no && id) {
        console.error("Invoice data is not provided or empty");
        return;
    }

    // Assuming you want to use the first item from the array
   

    console.log("Selected Invoice Data", this.websiteInvoiceData);
    console.log("transaction_id", id);
    console.log("display_trans_no",trans_no);

    // Ensure websiteData is initialized
    this.websiteData = this.websiteData || {};

    // Assign values from selectedInvoice to websiteData
    this.websiteData.transaction_id = id;
    this.websiteData.display_trans_no = trans_no;
    this.websiteData.trans_type = "Invoice";

    // Call the API to download PDF
    this.apiService.downloadPdfByTransId(id).subscribe(
        (result: any) => {
            let blob = new Blob([result], {
                type: "application/pdf",
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "invoice_" + this.websiteData.display_trans_no + ".pdf";
            link.click();
            window.URL.revokeObjectURL(link.href);
        },
        (error: any) => {
            console.error("Error downloading PDF:", error);
        }
    );
}

}
