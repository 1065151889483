import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router, ActivatedRoute, RouterModule } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
// 
import { CookieService } from "ngx-cookie-service";
import { ElectronService } from "ngx-electron";
import { Notyf } from 'notyf';
// import { DBbackupComponent } from '../db-backup/db-backup.component';
// import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
// import { AddEditTenentComponent } from '../tenent-management/add-edit-tenent/add-edit-tenent.component';
// import { Observable } from 'rxjs';

import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatTabsModule } from "@angular/material/tabs";
import { CommonModule } from "@angular/common";
import { DataService } from "../data.service";
import { Observable } from "rxjs";
import { NotificationService } from "../_services/notification.service";
import { SharedModule } from "../common.module";
import { MaterialModule } from "../material/material.module";
import { LayoutModule } from "@angular/cdk/layout";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  ReCaptchaV3Service,
} from "ng-recaptcha";
import { environment } from "../../environments/environment";

// Initialize Notyf
const notyf = new Notyf({
  position: {
    x: 'right',
    y: 'top',
  },
});


@Component({
  selector: "app-e-login",
  templateUrl: "./e-login.component.html",
  styleUrls: ["./e-login.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    RecaptchaModule,
  ],
  providers: [
    { provide: RECAPTCHA_SETTINGS, useValue: environment.recaptchaSiteKey },
  ],
})
export class ElectronLoginComponent implements OnInit {
  @ViewChild("recaptchaContainer") recaptchaContainer!: ElementRef;
  recaptchaResponse: string | null = null;
  constructor(
    private router: Router,
    private apiService: DataService,
    private route: ActivatedRoute,
    private notifier: NotificationService,
    public dialog: MatDialog,
    private cookieService: CookieService // private recaptchaV3Service: ReCaptchaV3Service,
  ) {}
  model: any = {
    isTrustedCaptchaOtp : false
  };
  MFADetails: any = {}; 
  loading: any = false;
  returnUrl: any;
  windowReference: any;
  companyArr: any;
  fyArr: any;
  subdomain: any;
  isCaptcha: any = true;
  recaptchaSiteKey: any = environment.recaptchaSiteKey;
  email_mobile_status: any = true;
  OTPsended: Boolean = false;
  // timer:number = 95;
  timer: number = 60;
  displayTime: string = "";
  showSlider: boolean = false;
  endpoint: any = localStorage.getItem("endPoint");
  // notifier: any
  //  executeImportantAction(): void {
  //   this.recaptchaV3Service.execute('importantAction')
  //     .subscribe((token) =>{


  //     });
  // }

  ngAfterViewInit() {
    this.adjustRecaptchaScale();
  }

  @HostListener("window:resize")
  onWindowResize() {
    this.adjustRecaptchaScale();
  }

  adjustRecaptchaScale() {
    const containerWidth = this.recaptchaContainer.nativeElement.offsetWidth;
    const scale = containerWidth / 400; // 400 is the default width of the reCAPTCHA widget
    this.recaptchaContainer.nativeElement.style.transform = `scale(${scale})`;
    this.recaptchaContainer.nativeElement.style.transformOrigin = "0 0";
  }

  resolved(captchaResponse: any): void {
    // Handle the captcha response
    this.isCaptcha = captchaResponse;
  }

  ngOnInit() {
    this.getSubdomain();
  }

  load() {
    this.apiService.checkTenentDB().subscribe(
      (result: any) => {
        if (result.success) {
          // if (!result.data.length) {
          //   this.dialog.open(AddEditTenentComponent, {
          //     width: '650px',
          //   });
          // }
        }
      },
      (result: any) => {
        this.loading = false;
      }
    );

    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
    // this.apiService.logout();

    this.apiService.getSubdomainDetails().subscribe(
      (result: any) => {
        this.companyArr = result;
        if (this.companyArr.length == 1) {
          this.model.company_id = this.companyArr[0].company_id;
          this.getFYlist(this.companyArr[0].company_id);
        }
      },
      (result: any) => {
        this.loading = false;
      }
    );
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf(".") < 0 ||
      domain.split(".")[0] === "example" ||
      domain.split(".")[0] === "lvh" ||
      domain.split(".")[0] === "www"
    ) {
      this.subdomain = "";
    } else {
      this.subdomain = domain.split(".")[0];
    }
    if (this.subdomain == "accounts") {
      this.router.navigate(["/admin"]);
    } else {
      this.load();
    }
  }
  verifyOtp(e: any) {
    if (this.isCaptcha) {
      this.loading = true;
      let payload = { ...this.model, deviseID: this.endpoint };
      this.apiService.verifyOtp(payload).subscribe(async (res: any) => {
        let { status, userDetails } = res;

        if (status) {
          this.loading = false;
          if (userDetails) {
            await this.saveDetailsToStorage(userDetails);
          }
        } else {
          this.loading = false;

          this.notifier.showDanger("Incorrect OTP");
        }
      });
    }
  }

  EnabelingMFA(e: any) {
    // Merging model and event payload
    let payload = { ...this.model, ...e };

    // Checking if email is valid or if mobile number exists
    if (this.isValidEmail(payload?.email_id) || payload?.mobile) {
      if (payload?.mobile) {
        // Sending API request to add mobile or email
        this.apiService.addMobileEmail(payload).subscribe(async (res: any) => {
          let { status, userDetails } = res;

          if (status) {
            // Initiating login if status is true
            await this.saveDetailsToStorage(userDetails);
          }
        });
      }
    }
  }

  isValidEmail(email: any) {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  SendOtp(e: any) {
    // Check if captcha is valid before proceeding
    if (this.isCaptcha) {
      let payload = { ...this.model, deviseID: this.endpoint };

      // Send OTP request to the API
      this.apiService.login(payload).subscribe(async (res: any) => {
        
        if (res.success == false) {
          // If success is false, show the error message
          const errorMessage = "Invalid Credentials";
          notyf.error(errorMessage);
          this.OTPsended = false;
          return;  // Exit early since login failed
        }

        // Destructure response
        let { status, message, missingDetails, userDetails } = res;
        if (userDetails) {
          // storing the responses to local and cookies
          await this.saveDetailsToStorage(userDetails);
        } else {
          this.email_mobile_status = message;

          if (missingDetails === "both") {
            this.showSlider = !this.showSlider;
          }

          // OTP sent successfully
          if (message && status) {
            this.OTPsended = true;
            this.timer = 90;

            // Start a countdown timer
            let timerfunc = setInterval(() => {
              this.timer--;
              this.updateDisplayTime();
              if (this.timer === 0) {
                clearInterval(timerfunc);
              }
            }, 1000);
          } else if (!message && status) {
            // Case where status is true but no message
            this.OTPsended = false;
          } else if (!message && !status) {
            // Invalid credentials case
            this.OTPsended = false;
            const errorMessage = res.message || "Invalid Credentials";
            notyf.error(errorMessage);
          }
        }

        // Show slider if both email and mobile details are missing
      });
    }
  }

  resendOTP(e: any) {
    this.SendOtp(e);
  }

  updateDisplayTime() {
    const minutes = Math.floor(this.timer / 60);
    const seconds = this.timer % 60;
    this.displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  onCompanySelection(e: any) {
    const company_id = e.value;
    this.getFYlist(company_id);
   
  }

  getFYlist(company_id: any) {
     const deviceId = localStorage.getItem('endPoint')
    this.apiService.getFyList(company_id, deviceId).subscribe(
      (result: any) => {
        this.fyArr = result;
        this.model.fy_id = this.fyArr[this.fyArr.length - 1].fy_id;
        this.model.validUpTo = this.fyArr[this.fyArr.length - 1].validUpTo;
        this.model.isToday = this.isToday(this.model.validUpTo);
      },
      (result: any) => {
        this.loading = false;
      }
    );
  }

  login() {
    this.loading = true;
    const body = {
      username: this.model.username,
      password: this.model.password,
      electron: true,
      company_id: this.model.company_id,
      fy_id: this.model.fy_id,
    };
  
    this.apiService.login(body).subscribe(
      async (result: any) => {
        this.loading = false;
          console.log('result', result);
        // Check if API returned success false within 200 response
        if (!result.success) {
          // Handle case where success is false, even in 200 response
          const errorMessage = result.message || "Invalid Credentials";
          
          // Show error notification using Notyf
          notyf.error(errorMessage);
        } else {
          // Handle successful login
          const parsedresult = result.dataObj;
  
          await this.cookieService.set('SESSIONID', parsedresult.token, 10);
          await this.cookieService.set('refresh_token', parsedresult.refresh_token, 10);
  
          localStorage.setItem('is_trial', result.is_trial);
          localStorage.setItem('allow_multicompany', result.allow_multicompany);
          localStorage.setItem('userName', parsedresult.userName);
          localStorage.setItem('userFullName', parsedresult.userFullName);
          localStorage.setItem('emailId', parsedresult.emailId);
          localStorage.setItem('userRole', parsedresult.userRole);
          localStorage.setItem('role_id', parsedresult.role_id);
          localStorage.setItem('company_name', parsedresult.company_name);
          localStorage.setItem('user_id', parsedresult.user_id);
          localStorage.setItem('ver', result.app_version);
          localStorage.setItem('fy_id', parsedresult.fy_id);
          localStorage.setItem('company_id', parsedresult.company_id);
          localStorage.setItem('tenent_id', parsedresult.tenent_id);
          localStorage.setItem('isrst', result.isRST);
          localStorage.setItem('last_Backup', JSON.stringify(parsedresult.lastBackup));
          localStorage.setItem('help_Desk_Url', result.helpDeskUrl);
  
          this.notifier.showSuccess(this.cookieService.get('SESSIONID'));
  
          if (result.isRST) {
            this.router.navigate(['/rst-dashboard']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      },
      (error: any) => {
        this.loading = false;
  
        // Display error notification using Notyf for HTTP error response
        notyf.error('Unable to connect with server');
      }
    );
  }

  async saveDetailsToStorage(result: any) {
    this.loading = false;

    let parsedresult = result?.dataObj;

    await this.cookieService.set("SESSIONID", parsedresult.token, 10);
    await this.cookieService.set(
      "refresh_token",
      parsedresult.refresh_token,
      10
    );

    localStorage.setItem("is_trial", result.is_trial);
    localStorage.setItem("allow_multicompany", result.allow_multicompany);
    localStorage.setItem("userName", parsedresult.userName);
    localStorage.setItem("userFullName", parsedresult.userFullName);
    localStorage.setItem("emailId", parsedresult.emailId);
    localStorage.setItem("userRole", parsedresult.userRole);
    localStorage.setItem("role_id", parsedresult.role_id);
    localStorage.setItem("company_name", parsedresult.company_name);
    localStorage.setItem("user_id", parsedresult.user_id);
    localStorage.setItem("ver", result.app_version);
    localStorage.setItem("fy_id", parsedresult.fy_id);
    localStorage.setItem("company_id", parsedresult.company_id);
    localStorage.setItem("tenent_id", parsedresult.tenent_id);
    localStorage.setItem("isrst", result.isRST);
    localStorage.setItem(
      "last_Backup",
      JSON.stringify(parsedresult.lastBackup)
    );
    localStorage.setItem("help_Desk_Url", result.helpDeskUrl);

    if (result.isRST) {
      this.router.navigate(["/rst-dashboard"]);
    } else if (this.cookieService.get("SESSIONID")) {
      this.router.navigate(["/dashboard"]);
    }
  }

  get_last_db_backup() {
    this.apiService.get_last_db_backup().subscribe(
      (result: any) => {
        if (result.message) {
          let backup_date = result.message.split("T")[0].split("-");
          backup_date =
            backup_date[2] + "-" + backup_date[1] + "-" + backup_date[0];
          //   this.dialog.open(ConfirmDialogComponent, {
          //     data: "Your last database backup was on " + backup_date + ", do you want to take back up now ?"

          //   })
          //     .afterClosed().subscribe(result => {
          //       if (result == true) {
          //         this.onBackupClick();
          //       }
          //     });
        } else {
          console.log("result.message---", result.message);
          // if (!result.success) {
          if (result.message) {
            this.notifier.showDanger(result.message);
          }
          // }
        }
      },
      (err: any) => {}
    );
  }

  onCheckboxOtpAndCaptchTrusted(event: any) {
    const isChecked = event.checked;
    if (isChecked) {
      this.model.isTrustedCaptchaOtp = true;
    }else{
      this.model.isTrustedCaptchaOtp = false;
    }
  }

   isToday(dateString : Date) {
    const inputDate = new Date(dateString);
    const today = new Date();
  
    // Compare only the year, month, and date (ignoring time)
    return (
      inputDate.getFullYear() === today.getFullYear() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getDate() === today.getDate()
    );
  }

  onBackupClick() {
    // this.dialog.open(DBbackupComponent, {
    //   width: 'auto',
    //   height: 'auto'
    // }).afterClosed().subscribe(result => {
    //   if (result) {
    //     this.windowReference = window.open(result, '_blank', 'toolbar=0,width=400,height=600,left=500,top=50');
    //   }
    // });
  }
}
