<div id="rst-invoice" class="panelcard">
  <form #f="ngForm" [formGroup]="form">
    <div class="row">
      <!-- Left Section: Order Details -->
      <div class="col-md-8 col-12 tab-details content-scroll">
        <!-- Header Bar -->
        <div class="header-bar">
          <!-- Back Button (Hidden when showBackButton is 'false') -->
          <div
            *ngIf="showBackButton == 'false'"
            class="btn btn-primary back-button"
            routerLink="/rst-dashboard"
          >
            <mat-icon>keyboard_backspace</mat-icon>
          </div>
          <div class="header-bar">
            <span class="header-title">
              Table: {{ table_name }} ({{ tableCapacity }})
            </span>
            <div mat-button (click)="changeTable()">Change Table</div>
          </div>
          <div class="header-actions">
            <div class="btn btn-primary" (click)="redirectToKeyboard()">
              Go to UI Mode
            </div>
            <!-- Clear Session Button (Visible when tableObj is empty and webappActiveTable is true) -->
            <section
              *ngIf="tableObj?.length == 0 && webappActiveTable == true"
              class="form-section"
            >
              <button class="btn btn-primary" (click)="clearTable()">
                Clear Session
              </button>
            </section>
            <!-- Waiter/Water Button (Visible when water or waiter is true) -->
            <section *ngIf="water || waiter" class="form-section">
              <button class="btn btn-primary" (click)="clearwaterwaiter()">
                Waiter/Water
              </button>
            </section>
          </div>
        </div>
        <hr />
        <!-- Search Items Section -->
        <div class="search-items-food">
          <div class="row">
            <div class="col-md-5">
              <mat-form-field class="contract-full-width">
                <input
                  type="text"
                  placeholder="Search Item"
                  matInput
                  [formControl]="itemControl"
                  [matAutocomplete]="auto"
                  (keyup)="search($event)"
                  (focus)="onFocus()"
                  #sitm
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  autoActiveFirstOption
                  [displayWith]="displayFn"
                  (optionSelected)="itemSelection($event)"
                  (keydown.enter)="onEnterKey($event)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    <span class="item-font">({{ option.item_code }})</span>
                    <span class="item-font">{{ option.item_name }}</span
                    ><br />
                    <small>
                      <span class="item-subtitle">Category: </span>
                      <span *ngIf="option.category_name" class="item-rate">
                        {{ option.category_name }}
                      </span>
                    </small>
                    <small>
                      <span class="item-rate-font">SRate: </span>
                      <span *ngIf="option.s_rate" class="item-rate">
                        {{ option.s_rate | number : "1.2-2" }}
                      </span>
                    </small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Qty:"
                  formControlName="item_qty"
                  (keyup)="calculateItemAmt($event)"
                  (change)="calculateItemAmt($event)"
                  (focus)="onQtyFocus($event)"
                  (blur)="onQtyFocus(null)"
                  (keydown.enter)="onEnterKey($event)"
                  #qty1
                  min="1"
                />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Rate:"
                  formControlName="item_rate"
                  [readonly]="!allowChangeRate"
                  (keyup)="calculateItemAmt($event)"
                  (keydown.enter)="onEnterKey($event)"
                  #s_rate1
                />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field class="contract-full-width">
                <input
                  matInput
                  type="number"
                  placeholder="Amount:"
                  formControlName="item_amt"
                  (keydown.enter)="onEnterKey($event)"
                  readonly
                  style="text-align: inherit"
                />
              </mat-form-field>
            </div>
            <div class="col-md-1 pt-3">
              <button
                type="button"
                class="btn btn-sm del-btn"
                (click)="onAddItem()"
                (keydown.enter)="onEnterKey($event)"
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>

        <!-- Items Table -->
        <div class="scroll-sec-itm">
          <table class="table item-tbl">
            <thead>
              <tr>
                <th>#</th>
                <th class="itm-name">Item Name</th>
                <th></th>
                <th class="text-center">Qty</th>
                <th></th>
                <th class="right">Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="itm-tbody">
              <ng-container *ngFor="let item of itemsArr; let i = index">
                <tr>
                  <td data-title="Sl">{{ i + 1 }}</td>
                  <td data-title="Name" class="table-font-arial itm-name">
                    {{ item.item_name }}
                  </td>
                  <td class="table-font-arial">
                    <button
                      mat-icon-button
                      type="button"
                      (click)="decrease_qty1(item, i)"
                      class="qty"
                    >
                      <mat-icon>remove_circle</mat-icon>
                    </button>
                  </td>
                  <td data-title="Qty" class="table-font-arial">
                    <input
                      type="number"
                      class="form-control form-control-xs text-center"
                      value="{{ convertFloat(item.qty) }}"
                      (keyup)="onChangeItemQty($event, i)"
                      min="1"
                    />
                  </td>
                  <td>
                    <button
                      mat-icon-button
                      type="button"
                      (click)="increase_qty1(item, i)"
                      class="qtyright text-center"
                    >
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </td>
                  <td data-title="Amount" class="table-font-arial right">
                    {{ item.amount | number : "1.2-2" }}
                  </td>
                  <td data-title="Actions" class="action-itm">
                    <button
                      type="button"
                      class="btn-sm del-btn btn"
                      (click)="onDeleteItem(i)"
                    >
                      <mat-icon>delete_outline</mat-icon>
                    </button>
                  </td>
                </tr>
                <!-- Customisation Link (Visible if item has variants or options) -->
                <ng-container
                  *ngIf="
                    (item.checkedVariant && item.checkedVariant.length > 0) ||
                    (item.optionsData && item.optionsData.length > 0)
                  "
                >
                  <tr>
                    <td>
                      <a
                        (click)="updateCustomisation(item)"
                        class="customisable-link"
                        >Customisable</a
                      >
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>
        <hr />
        <!-- Keyboard Shortcuts -->
        <div class="shortcut-keys-container">
          <h3>Keyboard Shortcuts</h3>
          <p>
            <strong>Note:</strong> If you are using a Mac, use the
            <kbd>Cmd</kbd> key instead of <kbd>Ctrl</kbd>.
          </p>
          <div class="shortcuts-grid">
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action">Focus on Item Search</span>
                <span class="shortcut-keys"><kbd>F1</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action">Save and Print</span>
                <span class="shortcut-keys"><kbd>F2</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action">Open Receipt Popup</span>
                <span class="shortcut-keys"><kbd>F3</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action">Focus on Contact No.</span>
                <span class="shortcut-keys"><kbd>F5</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action"
                  >Toggle the Change Table Popup</span
                >
                <span class="shortcut-keys"><kbd>F6</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action"
                  >Move one input field to another</span
                >
                <span class="shortcut-keys"><kbd>Tab</kbd></span>
              </div>
            </div>
            <div class="shortcut-card">
              <div class="shortcut-details">
                <span class="shortcut-action"
                  >Move back one input field to another</span
                >
                <span class="shortcut-keys"
                  ><kbd>Shift</kbd> + <kbd>Tab</kbd></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Section: Customer and Billing Details -->
      <div class="col-md-4 col-12 bill-details content-scroll">
        <!-- Customer Detail Section -->
        <div class="customer-detail">
          <h5>Customer Detail</h5>
          <div class="row">
            <div class="col-md-12 pt-1 customer">
              <div class="form-group w-100 row">
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Contact No</mat-label>
                    <input
                      type="text"
                      matInput
                      placeholder="Enter Number"
                      [(ngModel)]="inputValue"
                      formControlName="contact_no"
                      [matAutocomplete]="numberAuto"
                      (keyup)="onInput($event)"
                      (ngModelChange)="handleInputChange(inputValue)"
                      (keyup.enter)="handleEnterKey($event)"
                      #contact_no
                    />
                  </mat-form-field>
                  <mat-autocomplete
                    #numberAuto="matAutocomplete"
                    autoActiveFirstOption
                  >
                    <mat-option
                      *ngFor="let i of listNumber"
                      (click)="searchByPhone(i)"
                      (keyup.enter)="handleEnterKey($event)"
                      [value]="i"
                    >
                      <span class="item-font">{{ i }}</span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Customer Name</mat-label>
                    <input
                      type="text"
                      matInput
                      placeholder="Enter Customer Name"
                      formControlName="customer_name"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-md-12 pt-1 customer">
              <div class="form-group w-100 row">
                <div class="col-sm-12 w-100">
                  <textarea
                  placeholder="Enter Address"
                  style="height: 50px !important;"
                  class="form-control form-control-xs"
                  id="address"
                  formControlName="address"
                  rows="3"
                ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <!-- Billing Amount Section -->
        <div class="customer-detail">
          <h5>Billing Amount</h5>
          <div class="form-group row">
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="fill">
                <mat-label>Amount</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="total_amount"
                  class="text-right"
                  placeholder="0"
                  readonly
                  aria-label="Total Amount"
                />
                <span matPrefix><span>&#8377;</span></span>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="fill">
                <mat-label>Discount %</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="dis_per"
                  placeholder="Enter Discount %"
                  (input)="onDiscountPer($event)"
                  (focus)="onDiscountFocus(true)"
                  (blur)="onDiscountFocus(false)"
                  min="0"
                  aria-label="Discount Percentage"
                />
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-12">
              <mat-form-field appearance="fill">
                <mat-label>Discount Amount</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="discount_amt"
                  class="form-control"
                  (input)="onDiscountAmt($event)"
                  aria-label="Discount Amount"
                />
              </mat-form-field>
            </div>
          </div>
          
        </div>
        <hr />
        <!-- Footer Bar with Save Options -->
        <div class="footer-bar row">
          <div class="col-4">
            <h5>Net Amount:</h5>
            <p>
              <strong
                ><i class="fa fa-{{ currency }}"></i
                >{{ form.value.net_amount }}</strong
              >
            </p>
          </div>
          <div class="kot-trigger-buttom col-8 float-right">
            <div
              [ngClass]="{
                takeAwaySubmitButton:
                  itemsArr.length > 0 && isEbillingEnable == 'true'
              }"
            >
              <!-- <button
                type="button"
                #btnGrp
                class="btn-primary"
                (click)="onOnlyAddKOT()"
                *ngIf="waitforTakeaway === false"
              >
                Save Kot & Print
              </button> -->
              <button
                type="button"
                #btnGrp
                class="btn-primary"
                (click)="onAddKOT()"
                *ngIf="waitforTakeaway === false"
              >
                Save & Print Invoice
              </button>
            </div>
          </div>
        </div>
        <hr />
        <!-- Pending Invoice Section -->
        <h5>Pending Invoice</h5>
        <table mat-table [dataSource]="dueInvoiceObj" matSort>
          <ng-container matColumnDef="customer_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
            <td mat-cell *matCellDef="let row" data-label="Customer">
              {{ row.customer_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="display_trans_no">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice</th>
            <td
              mat-cell
              *matCellDef="let row"
              data-label="Invoice"
              (click)="receiptDialog(row)"
              class="clickable"
            >
              {{ row.display_trans_no }}
            </td>
          </ng-container>
          <ng-container matColumnDef="net_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
            <td
              mat-cell
              *matCellDef="let row"
              data-label="Amount"
              class="text-right"
            >
              {{ row.net_amount | number : "1.2-2" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="advance">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" data-label="Advance">
              <div
                mat-icon-button
                (click)="receiptDialog(row)"
                class="clickable"
              >
                <mat-icon>open_in_new</mat-icon>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{ 'table-danger': row.active == false }"
          ></tr>
        </table>
        <mat-paginator
          [pageSizeOptions]="[5, 10, 25, 100]"
          [pageSize]="10"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </div>
  </form>
</div>
