<div class="login-backgound overflow-auto">
  <div class="container-fluid loghead" style="display: flex">
    <div class="col-sm-6">
      <a target="_blank" href="https://intellibooks.io/">
        <img src="../../assets/images/logoNew.png" alt="" class="logodb"
      /></a>
    </div>
    <div class="learn-more col-sm-6">
      <a href="https://intellibooks.io/" target="_blank">Learn more</a>
    </div>
  </div>
  <div class="main-div mt-5 justify-content-between logins">
    <div class="col-md-1"></div>
    <div class="col-md-3  m-0 " style="overflow-y: auto; height: 80vh;">
      <mat-card class="login-block p-3">
        <div class="slider-component" [ngClass]="{ show: showSlider }">
          <div class="row m-b-20">
            <div class="col-md-12">
              <h3 class="text-center">Sign In</h3>

              <p></p>
              <br />
            </div>
          </div>
          <form name="form" #f="ngForm" novalidate>
            <mat-form-field class="contract-full-width">
              <mat-label>Company</mat-label>
              <mat-select
                placeholder="Company"
                [(ngModel)]="model.company_id"
                name="company_id"
                (selectionChange)="onCompanySelection($event)"
                required
              >
                <mat-option
                  *ngFor="let company of companyArr"
                  [value]="company.company_id"
                >
                  {{ company.company_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="contract-full-width">
              <mat-label>Financial Year</mat-label>
              <mat-select
                placeholder="Financial Year"
                [(ngModel)]="model.fy_id"
                name="fy_id"
                required
              >
                <mat-option *ngFor="let fy of fyArr" [value]="fy.fy_id">
                  {{ fy.fy_year }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span>
              <mat-form-field appearance="outline" class="contract-full-width">
                <mat-label>Enter Username</mat-label>
                <input
                  matInput
                  placeholder="Username"
                  [(ngModel)]="model.username"
                  name="username"
                  #username="ngModel"
                  required
                />
              </mat-form-field>
            </span>
            <div
              *ngIf="f.submitted && !username.valid"
              class="help-block"
            ></div>

            <span>
              <mat-form-field appearance="outline" class="contract-full-width">
                <mat-label>Enter Pasword</mat-label>
                <input
                  matInput
                  placeholder="Password"
                  name="password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  type="password"
                  required
                />
              </mat-form-field>
            </span>
            <div *ngIf="model.validUpTo === null || model.isToday">
            <div #recaptchaContainer class="recaptcha-container">
              <re-captcha
                (resolved)="resolved($event)"
                class="flex"
                [siteKey]="recaptchaSiteKey"
              ></re-captcha>
            </div>
          </div>
            <span *ngIf="OTPsended">
              <mat-form-field appearance="outline" class="contract-full-width">
                <mat-label>Enter OTP</mat-label>
                <input
                  matInput
                  placeholder="OTP"
                  name="otp"
                  [(ngModel)]="model.otp"
                  #password="ngModel"
                  type="number"
                  required
                />
              </mat-form-field>
            </span>
            <!-- <span *ngIf="OTPsended && timer<5"> -->
            <div *ngIf="OTPsended">
              <div class="resendOTP1">
                {{ displayTime }}
              </div>
              <div
                *ngIf="OTPsended"
                [class]="timer < 5 ? 'resendOTP1' : 'resendOTP'"
                (click)="resendOTP(f.form.value)"
                style="margin-bottom: 20px"
              >
                Resend OTP
              </div>
              <div
                *ngIf="email_mobile_status"
                [class]="timer < 5 ? 'resendOTP1' : 'resendOTP'"
                (click)="resendOTP(f.form.value)"
                style="margin-bottom: 20px"
              >
                {{ email_mobile_status }}
              </div>
            </div>
            <div
              *ngIf="f.submitted && !password.valid"
              class="help-block"
            ></div>

            <button
              type="button"
              *ngIf="!OTPsended"
              class="btn-primary btn-md btn-block text-center m-b-20 ripple light"
              (click)="SendOtp(f.form.value)"
            >
              Sign in
              <img
                *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </button>
            
            <section class="example-section" *ngIf="OTPsended">
              <mat-checkbox class="example-margin" (change)="onCheckboxOtpAndCaptchTrusted($event)">Remember me   </mat-checkbox>
            </section>


            <button
              type="button"
              *ngIf="OTPsended"
              class="btn-primary btn-md btn-block text-center m-b-20 ripple light"
              (click)="verifyOtp(f.form.value)"
            >
              Verify OTP
              <img
                *ngIf="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </button>
            <!-- <button [disabled]="loading" (click)="f.form.valid && login()" type="submit"  class="btn-primary btn-md btn-block text-center m-b-20 ripple light submt">Sign
              in</button> -->
            <!-- <img *ngIf="loading"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
          </form>

          <br />
        </div>

        <div class="slider-component1" [ngClass]="{ show: showSlider }">
          <!-- Your slider component content goes here -->
          <div *ngIf="showSlider">
            <div class="row m-b-20">
              <div class="col-md-12">
                <h4 class="text-center text-primary">
                  Complete your profile by your MFA
                </h4>
                <h5 class="text-center text-primary">
                  (Multi Factor Authentication)
                </h5>
                <div class="text-center text-warning border p-3">
                  MFA strengthens the security of your accounts by requiring
                  multiple forms of verification
                </div>

                <br />
              </div>
            </div>
            <form name="form" #f="ngForm" novalidate>
              <mat-form-field appearance="outline" class="contract-full-width">
                <mat-label>Enter Email</mat-label>
                <input
                  matInput
                  placeholder="Email"
                  type="email"
                  [(ngModel)]="MFADetails.email_id"
                  name="email_id"
                  #email_id="ngModel"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="contract-full-width">
                <mat-label>Enter Mobile</mat-label>
                <input
                  matInput
                  type="number"
                  placeholder="Mobile"
                  [(ngModel)]="MFADetails.mobile"
                  name="mobile"
                  #mobile="ngModel"
                  required
                />
              </mat-form-field>

              <button
                type="button"
                class="btn-primary btn-md btn-block text-center m-b-20 ripple light"
                (click)="EnabelingMFA(MFADetails)"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- <div class="col-md-1"></div> -->
    <div class="slide-tab col-md-6">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center">
        <mat-tab label="Restaurant POS">
          <img src="../../assets/images/Untitled design 90.png" alt="" />
        </mat-tab>
        <mat-tab label="Mobile POS">
          <img src="../../assets/images/Untitled design 91.png" alt="" />
        </mat-tab>
        <mat-tab label="Online Ordering">
          <img src="../../assets/images/Untitled design 92.png" alt="" />
        </mat-tab>
        <mat-tab label="Kitchen Display System"
          ><img src="../../assets/images/kds.png" alt=""
        /></mat-tab>
      </mat-tab-group>
    </div>
    <div class="col-md-1"></div>
    <!-- <button (click)="executeImportantAction()">Important action</button> -->
  </div>
</div>

<!-- 
<form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
  <div class='login-container'>
    <div class="login-header">
      <h3>Contract Management</h3>

    </div>
    <div class="login-body form-group">

      <h4>Login</h4>
      <hr />
      <input type='text' class='form-control' name="username"
       [(ngModel)]="model.username" #username="ngModel"
        placeholder="User Name" required />
      <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
      <br />

      <input type='password' class='form-control' name="password" 
      [(ngModel)]="model.password" #password="ngModel"
        placeholder='Password' required />
      <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
      <br />

      <button [disabled]="loading" class="btn btn-primary">Submit</button>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
  </div>
-->
