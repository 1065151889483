<div id="rst-invoice" class="panelcard">
  <form #f="ngForm" [formGroup]="form">
    <div class="col">
      <div class="row">
        <div class="col-md-8 col-12 tab-details content-scroll">
          <div style="border-bottom: 1px solid #d9d9d9; height: 40px">
            <div
              *ngIf="showBackButton == 'false'"
              class="btn btn-primary"
              style="height: 35px"
              routerLink="/rst-dashboard"
            >
              <mat-icon>keyboard_backspace</mat-icon>
            </div>

            <span
              style="
                margin-left: 25px;
                font-weight: 700;
                display: inline-block;
                margin-top: 4px;
              "
            >
              Table:- {{ table_name }} ( {{ tableCapacity }} )
            </span>

            <span style="float: right; display: flex">
              <section
                class="form-section mr-1"
                style="height: 30px"
                *ngIf="tableObj?.length == 0 && webappActiveTable == true"
              >
                <button
                  class="btn btn-primary"
                  style="font-size: 12px; margin-top: 5px"
                  (click)="clearTable()"
                >
                  Clear Session
                </button>
              </section>
              <section class="form-section mr-1" style="height: 30px">
                <button
                  *ngIf="water || waiter"
                  class="btn btn-primary"
                  style="font-size: 12px; margin-top: 5px"
                  (click)="clearwaterwaiter()"
                >
                  Waiter/water
                </button>
              </section>
              <section
                class="form-section"
                style="height: 30px; font-size: 18px"
              >
                <mat-slide-toggle
                  color="primary"
                  class="marginTopToggle"
                  (change)="onAllowLogin($event)"
                  [checked]="allow_login"
                >
                </mat-slide-toggle>
                <Span> Allow Multi Login </Span>
              </section>

              <a class="btn btn-primary" (click)="redirectToKeyboard()">
                Go to keyboard Mode
              </a>
            </span>
          </div>

          <div class="row">
            <div class="col-3">
              <h3 class="color-h3">Categories</h3>
            </div>
            <div
              class="col-9 mt-1"
              style="text-align: end"
              *ngIf="tableObj?.kot?.length !== 0"
            >
              <button
                class="btn btn-primary"
                style="height: 35px"
                (click)="openTransferDialog()"
              >
                Transfer Table
              </button>
            </div>
          </div>
          <!-- category----------------------------------------------------------- -->
          <div class="catogaries" style="overflow: auto">
            <!-- <div *ngFor="let i of catArr
            ">
              <button class="items-catogaries" (click)="showitems(i.category_id)"><img [src]="i.imageurl" alt="">
                <br>
                <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{i.category_name}}</div>
              </button>
            </div> -->
            <mat-button-toggle-group
              name="favoriteColor"
              aria-label="Favorite Color"
              [hideSingleSelectionIndicator]="true"
            >
              <mat-button-toggle
                class="items-catogaries"
                class="catogaries"
                style="margin: 2px; border-radius: 10px"
                *ngFor="let i of catArr"
                value="red"
                (click)="showitems(i)"
                ><img
                  [src]="i.imgurl"
                  (error)="handelerror($event)"
                  alt=""
                  loading="lazy"
                  style="height: 75px; width: 115px; border-radius: 10px"
                />
                <br />
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  matTooltip="{{ i.category_name }}"
                  matTooltipPosition="above"
                >
                  {{ i.category_name }}
                </div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!-- items search--------------------------------------------------- -->
          <div style="margin-top: 5px">
            <div class="row search-items-food">
              <div class="col-md-5">
                <mat-form-field class="contract-full-width">
                  <mat-label>Search Item</mat-label>
                  <input
                    type="text"
                    aria-label="Number"
                    matInput
                    [formControl]="itemControl"
                    [matAutocomplete]="auto"
                    (keyup)="search($event)"
                    (focus)="onFocus($event)"
                    (keydown.enter)="onEnterKey($event)"
                    #sitm
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    autoActiveFirstOption
                    [displayWith]="displayFn"
                    (optionSelected)="itemSelection($event)"
                  >
                    <mat-option
                      *ngFor="let option of filteredOptions | async"
                      [value]="option"
                    >
                      <!-- Your option template -->
                      <span class="item-font">({{ option.item_code }})</span>
                      <span class="item-font"> {{ option.item_name }}</span>
                      <br />
                      <small>
                        <span class="item-subtitle">category: </span>
                        <span *ngIf="option.category_name" class="item-rate">{{
                          option.category_name
                        }}</span>
                      </small>
                      <small>
                        <span class="item-rate-font">SRate: </span>
                        <span *ngIf="option.s_rate" class="item-rate">{{
                          option.s_rate | number : "1.2-2"
                        }}</span>
                      </small>
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <!-- Other columns ... -->
              <div class="col-md-2">
                <mat-form-field class="contract-full-width">
                  <mat-label>Qty:</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="item_qty"
                    (keyup)="calculateItemAmt($event)"
                    (change)="calculateItemAmt($event)"
                    (focus)="onQtyFocus($event)"
                    (blur)="onQtyFocus(null)"
                    (keydown.enter)="onEnterKey($event)"
                    #qty1
                    min="1"
                  />
                  <!-- Add min="0" to prevent negative values -->
                </mat-form-field>
              </div>
              <!-- <div class="col-md-2">
                <mat-form-field class="contract-full-width">
                  <input matInput type="number" placeholder="Qty:" formControlName="item_qty"
                    (keyup)="calculateItemAmt($event)" (focus)="onQtyFocus($event)" (blur)="onQtyFocus(null)"
                    (keydown.enter)="onEnterKey($event)" #qty1>
                </mat-form-field>
              </div> -->
              <div class="col-md-2">
                <mat-form-field class="contract-full-width">
                  <mat-label>Rate:</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="item_rate"
                    [readonly]="!allowChangeRate"
                    (keyup)="calculateItemAmt($event)"
                    (keydown.enter)="onEnterKey($event)"
                    #s_rate1
                  />
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field class="contract-full-width">
                  <mat-label>Amount:</mat-label>
                  <input
                    matInput
                    type="number"
                    formControlName="item_amt"
                    (keydown.enter)="onEnterKey($event)"
                    readonly
                  />
                </mat-form-field>
              </div>
              <div class="col-md-1 pt-3">
                <button
                  type="button"
                  class="btn btn-sm del-btn"
                  (click)="onAddItem()"
                  (keydown.enter)="onEnterKey($event)"
                >
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>
            </div>
            <h3 class="color-h3">
              Select Menu
              <span *ngIf="selectedCategoryName">
                /{{ selectedCategoryName }}</span
              >
            </h3>

            <div class="row">
              <div
                class="col-md-3 col-12"
                *ngIf="sub_category_data && sub_category_data.length > 0"
              >
                <h3 class="color-h3">Sub Categories</h3>
                <div class="container-sub-category">
                  <mat-button-toggle-group
                    name="favoriteColor"
                    aria-label="Favorite Color"
                    class="items-sub-category subcatcss"
                    [hideSingleSelectionIndicator]="true"
                  >
                    <mat-button-toggle
                      style="margin: 2px; border-radius: 10px"
                      *ngFor="let i of sub_category_data"
                      value="red"
                      (click)="showSubitems(i.sub_category_id)"
                      ><img
                        [src]="i.imgurl"
                        (error)="handelerror($event)"
                        alt=""
                        loading="lazy"
                        style="height: 75px; width: 115px; border-radius: 10px"
                      />
                      <br />
                      <div
                        style="
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        "
                      >
                        {{ i.name }}
                      </div>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="col-md-9 col-12">
                <!-- -------------------------------items card -------------------------------------------- -->
                <div class="select-menu">
                  <div class="catogaries">
                    <div class="">
                      <div
                        *ngFor="let i of items_data"
                        class="col-md-3"
                        style="display: contents"
                      >
                        <button
                          [ngClass]="{
                            'add-button': !i.options || i.options.length === 0
                          }"
                          (click)="addItemCardClicked(i)"
                        >
                          <img
                            [src]="i.imgurl"
                            (error)="handelerror($event)"
                            alt=""
                            style="padding-bottom: 5px"
                            loading="lazy"
                          />
                          <br />
                          <div class="name">
                            {{ i.item_name }}
                          </div>
                          <div style="padding-right: 7px">
                            <span class="pull-right item-rate">
                              <i class="fa fa-{{ currency }}"></i
                              >{{ i.s_rate | number : "1.2-2" }}</span
                            >
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 bill-details content-scroll">
          <mat-tab-group (selectedTabChange)="onChange($event)" #tabGroup>
            <mat-tab
              label="Dine-In"
              style="font-weight: 600"
              class="billing-tab-ui"
              *ngIf="dine_in === true"
            >
              <!-- //collapse ----------------------------------------------------------------------->
              <mat-accordion class="example-headers-align">
                <mat-expansion-panel #panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title> KOT </mat-panel-title>
                    <mat-panel-description>
                      Go for KOTs
                      <!-- <mat-icon>kitchen</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <div class="scroll-sec-itm">
                    <table class="table item-tbl">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th class="itm-name">Item Name</th>
                          <th></th>
                          <th class="text-center">Qty</th>
                          <th></th>
                          <th class="right">Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody class="itm-tbody">
                        <ng-container
                          *ngFor="let item of KOT_data; let i = index"
                        >
                          <tr>
                            <td data-title="Sl">{{ i + 1 }}</td>
                            <td
                              data-title="Name"
                              class="table-font-arial itm-name"
                            >
                              {{ item.item_name }}
                            </td>
                            <td class="table-font-arial">
                              <button
                                mat-icon-button
                                type="button"
                                (click)="decrease_qty(item, i)"
                                class="qty"
                              >
                                <mat-icon>remove_circle</mat-icon>
                              </button>
                            </td>
                            <td data-title="Qty" class="table-font-arial">
                              <input
                                type="text"
                                class="form-control form-control-xs text-center"
                                value="{{ convertFloat(item.qty) }}"
                                (keyup)="onChangeItemQty($event, i)"
                              />
                            </td>
                            <td>
                              <button
                                mat-icon-button
                                type="button"
                                (click)="increase_qty(item, i)"
                                class="qtyright text-center"
                              >
                                <mat-icon>add_circle</mat-icon>
                              </button>
                            </td>
                            <td
                              data-title="Amount"
                              class="table-font-arial right"
                            >
                              {{ item.amount | number : "1.2-2" }}
                            </td>
                            <td data-title="Actions" class="action-itm">
                              <button
                                type="button"
                                class="btn-sm del-btn btn"
                                (click)="onDeleteItem1(i)"
                              >
                                <mat-icon>delete_outline</mat-icon>
                              </button>
                            </td>
                          </tr>
                          <!-- <ng-container *ngIf="checkedVariant">
                            <tr *ngFor="let option of item.checkedVariant; let j=index">
                              <td data-title='Name' class="table-font-arial"><strong>{{ option.title }}</strong></td>
                              <td *ngFor="let c_option of option.variants" data-title='Name' class="table-font-arial">{{
                                c_option.title }},</td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="options_array">
                            <tr *ngFor="let option of item.optionsData; let j=index">
                              <td data-title='Name' class="table-font-arial"><strong>{{ option.title }}</strong></td>
                              <td *ngFor="let c_option of option.childOptions" data-title='Name'
                                class="table-font-arial">{{ c_option.title }},</td>
                            </tr>
                          </ng-container> -->
                          <ng-container
                            *ngIf="
                              (item.checkedVariant &&
                                item.checkedVariant.length > 0) ||
                              (item.optionsData && item.optionsData.length > 0)
                            "
                          >
                            <tr>
                              <td>
                                <a
                                  (click)="updateCustomisation(item)"
                                  style="text-decoration: none; color: blue"
                                  >Customisable</a
                                >
                              </td>
                            </tr>
                          </ng-container>

                          <tr>
                            <td colspan="7" class="table-font-arial">
                              <mat-form-field class="contract-full-width">
                                <textarea
                                  matInput
                                  placeholder="Write your cooking instruction here"
                                  formControlName="instruction"
                                  #n
                                  (input)="instrucctiondata(item, n)"
                                ></textarea>
                              </mat-form-field>
                            </td>
                          </tr>
                        </ng-container>
                        <!-- <tr *ngFor="let item of KOT_data;let i=index">
                          <td data-title='Sl'>{{i+1}}</td>
                          <td data-title='Name' class="table-font-arial itm-name">{{item.item_name}}</td>
                          <td class="table-font-arial"> <button mat-icon-button type="button"
                              (click)="decrease_qty(item,i)" class="qty"><mat-icon>remove_circle</mat-icon></button>
                          </td>
                          <td data-title='Qty' class="table-font-arial">
                            <input type="text" class="form-control form-control-xs text-center"
                              value="{{convertFloat(item.qty)}}" (keyup)="onChangeItemQty($event,i)">
                          </td>
                          <td><button mat-icon-button type="button" (click)="increase_qty(item,i)"
                              class="qtyright text-center"><mat-icon>add_circle</mat-icon></button></td>
                          <td data-title='Amount' class="table-font-arial right">{{item.amount | number : '1.2-2'}}</td>
                          <td data-title='Actions' class="action-itm">
                            <button type="button" class="btn-sm del-btn btn" (click)="onDeleteItem1(i)">
                              <mat-icon>delete_outline</mat-icon>
                            </button>
                          </td>
                            <mat-form-field class="contract-full-width">
                              <textarea matInput placeholder="Write your cooking instruction here" formControlName="instruction" #n (input)="instrucctiondata(item,n)"></textarea>
                            </mat-form-field>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                  <!-- <div>
                    <fieldset class="border pl-4 pr-4 form-fieldset">
                      <legend class="w-auto">Add cooking instruction</legend>
                      <mat-form-field class="contract-full-width">
                        <textarea matInput placeholder="Write your cooking instruction here" formControlName="notes"></textarea>
                      </mat-form-field>
                    </fieldset>
                  </div> -->
                  <mat-action-row>
                    <button
                      class="btn-primary"
                      #btnGrp
                      type="button"
                      style="float: right"
                      disabled
                      *ngIf="waitforkot === true"
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></span>
                      wait...
                    </button>
                    <button
                      type="button"
                      style="margin-right: 5px"
                      class="btn-primary btn-sm"
                      (click)="onOnlyAddKOT()"
                      *ngIf="waitforkot === false"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="btn-primary btn-sm"
                      (click)="onAddKOT()"
                      *ngIf="waitforkot === false"
                    >
                      Save & Print
                    </button>
                  </mat-action-row>
                </mat-expansion-panel>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header #opanel>
                    <mat-panel-title> Orders </mat-panel-title>
                    <mat-panel-description>
                      Go for Orders
                      <!-- <mat-icon>order</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="border scr-sec">
                    <div
                      *ngIf="!kotArr.length"
                      class="alert alert-danger text-center mt-2 w-100"
                    >
                      No Pending KOT found
                    </div>
                    <div>
                      <section *ngIf="kotArr.length" class="select-all">
                        <mat-checkbox
                          class="form-margin"
                          (change)="onSelectAllKOT($event.checked)"
                          [checked]="select_all"
                          name="select_all"
                          >Select All
                        </mat-checkbox>
                      </section>
                      <div class="scroll-sec">
                        <div class="list-group" *ngFor="let kot of kotArr">
                          <div class="d-flex w-100 justify-content-between">
                            <section class="kot-sec w-100">
                              <mat-checkbox
                                class="form-margin"
                                (change)="onChangeKOT(kot, $event.checked)"
                                [checked]="kot.added"
                                name="{{ kot.transaction_id }}"
                              >
                              </mat-checkbox>
                              <span
                                class="table-font-arial pl-1 kot-header"
                                data-toggle="collapse"
                                attr.data-target="#Item{{ kot.transaction_id }}"
                                >KOT#:{{ kot.trans_no }}
                                <span class="pull-right table-font-arial"
                                  ><i class="fa fa-{{ currency }}"></i
                                  ><strong>{{
                                    kot.net_amount | number : "1.2-2"
                                  }}</strong></span
                                >
                              </span>
                              <span
                                ><button
                                  type="button"
                                  class="btn btn-sm edit-btn"
                                  (click)="onEditKot(kot.transaction_id)"
                                >
                                  <mat-icon>edit</mat-icon>
                                </button></span
                              >
                            </section>
                          </div>
                          <div
                            *ngFor="let item of kot.items_details"
                            class="pl-2 collapse"
                            attr.id="Item{{ kot.transaction_id }}"
                          >
                            <span
                              >{{ convertFloat(item.qty) }} x
                              {{ item.item_name }}</span
                            >
                            <span class="pull-right table-font-arial"
                              ><i class="fa fa-{{ currency }}"></i
                              >{{ item.amount | number : "1.2-2" }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>

                <mat-expansion-panel #panel3 hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Invoice </mat-panel-title>
                    <mat-panel-description>
                      Go for Invoice
                      <!-- <mat-icon>invoice</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="row itm-sec">
                    <div class="col-md-12 border mb-1">
                      <div class="row">
                        <div class="col-md-12 pt-1 customer">
                          <div *ngIf="tableObj">
                            <label class="font-weight-bold col-sm-6 w-100 p-0"
                              >Table
                              <span style="float: right; margin-right: 30px"
                                >:</span
                              >
                            </label>
                            <label class="col-form-label">{{
                              tableObj.name
                            }}</label>
                          </div>
                        </div>
                        <div class="col-md-12 pt-1 customer">
                          <label class="col-sm-6 w-100 p-0"
                            ><strong
                              >Trans no.
                              <span style="float: right; margin-right: 30px"
                                >:</span
                              ></strong
                            >
                          </label>
                          <label class=""> Auto</label>
                        </div>
                        <div class="col-md-12 pt-1 customer">
                          <div [formGroup]="contactForm">
                          <div class="form-group w-100 row">
                            <label
                              for="contact_no"
                              class="col-sm-6 font-weight-bold pull-right"
                            >
                              Contact No
                              <span style="float: right">:</span>
                            </label>
                            <div class="col-sm-6 w-100 search-button">
                              <input
                                matInput
                                type="text"
                                placeholder="Enter  Number"
                                aria-label="Search"
                                class="form-control form-control-xs"
                                [(ngModel)]="inputValue"
                                id="contact_no"
                                formControlName="contact_no"
                                [matAutocomplete]="numberAuto"
                                (keyup)="onInput($event)"
                                (ngModelChange)="handleInputChange(inputValue)"
                                (keyup.enter)="handleEnterKey($event)"
                                maxlength="10"
                                pattern="[0-9]*"
                              />
                              <mat-autocomplete
                                #numberAuto="matAutocomplete"
                                autoActiveFirstOption
                              >
                                <mat-option
                                  matSuffix
                                  *ngFor="let i of listNumber"
                                  (click)="searchByPhone(i)"
                                  (keyup.enter)="handleEnterKey($event)"
                                  [value]="i"
                                >
                                  <span class="item-font">{{ i }}</span>
                                </mat-option>
                              </mat-autocomplete>
                              <!-- Ensure safe access to contact_no properties -->
                              <div 
                              *ngIf="contact_no?.invalid && (contact_no?.dirty || contact_no?.touched)" 
                              class="text-danger">

                              <!-- Safe access to 'pattern' error -->
                              <div *ngIf="contact_no?.errors?.['pattern']">
                                Contact number must be exactly 10 digits.
                              </div>

                              </div>
                            
                            </div>
                          </div>
                        </div>

                          <div class="form-group w-100 row">
                            <label
                              for="customer_name"
                              class="col-sm-6 font-weight-bold pull-right"
                            >
                              Customer Name<span style="float: right">:</span>
                            </label>
                            <div class="col-sm-6 w-100">
                              <input
                                type="text"
                                class="form-control form-control-xs"
                                id="customer_name"
                                formControlName="customer_name"
                              />
                              <!-- <div
                                  *ngIf="
                                    customer_name.invalid &&
                                    (customer_name.dirty ||
                                      customer_name.touched)
                                  "
                                  class="text-danger"
                                >
                                  <div *ngIf="customer_name.errors?.required">
                                    Customer name is required.
                                  </div>
                                </div> -->
                            </div>
                          </div>
                          
                          <div class="form-group w-100 row">
                            <label
                              for="email"
                              class="col-sm-6 font-weight-bold pull-right"
                            >
                              Email
                              <span style="float: right">:</span>
                            </label>
                            <div class="col-sm-6 w-100">
                              <input
                                type="text"
                                class="form-control form-control-xs"
                                id="email"
                                formControlName="email"
                              />
                            </div>
                          </div>
                          <div class="form-group w-100 row">
                            <label
                              for="email"
                              class="col-sm-6 font-weight-bold pull-right"
                            >
                              Address
                              <span style="float: right">:</span>
                            </label>
                            <div class="col-sm-6 w-100">
                              <textarea
                              style="height: 50px !important;"
                              class="form-control form-control-xs"
                              id="address"
                              formControlName="address"
                              rows="3"
                            ></textarea>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 pt-1">
                          <div class="form-group w-100 row">
                            <label
                              for="gstin"
                              class="col-sm-6 font-weight-bold pull-right"
                              >GSTIN <span style="float: right">:</span></label
                            >
                            <div class="col-sm-6 w-100">
                              <input
                                type="text"
                                class="form-control form-control-xs"
                                id="gstin"
                                style="
                                  border: none;
                                  border-bottom: 1px solid;
                                  border-radius: 0;
                                "
                                formControlName="gstin"
                              />
                            </div>
                          </div>
                          <div class="form-group w-100 row">
                            <label
                              for="date"
                              class="col-sm-6 font-weight-bold pull-right"
                              >Date <span style="float: right">:</span></label
                            >
                            <div
                              class="col-sm-6 w-100"
                              style="display: flex; height: 20px"
                            >
                              <input
                                style="width: 157px; border: none"
                                matInput
                                [matDatepicker]="trans_date"
                                formControlName="trans_date"
                                required
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="trans_date"
                                style="margin-top: -12px"
                              ></mat-datepicker-toggle>
                              <mat-datepicker #trans_date></mat-datepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="scroll-sec-itm">
                    <table class="table item-tbl">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th class="itm-name">Item Name</th>
                          <th class="text-center">Qty</th>
                          <!-- <th class="text-center">Rate</th> -->
                          <th class="right">Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody class="itm-tbody">
                        <tr *ngFor="let item of itemsArr; let i = index">
                          <td
                            data-title="Sl"
                            *ngIf="item.addons_parent_id === null"
                          >
                            {{ i + 1 }}
                          </td>
                          <td
                            data-title="Name"
                            class="table-font-arial itm-name"
                            *ngIf="item.addons_parent_id === null"
                          >
                            {{ item.item_name }}
                          </td>
                          <td
                            data-title="Qty"
                            class="table-font-arial"
                            *ngIf="item.addons_parent_id === null"
                          >
                            <input
                              type="text"
                              class="form-control form-control-xs text-center"
                              value="{{ convertFloat(item.qty) }}"
                              (keyup)="onChangeItemQty($event, i)"
                            />
                          </td>
                          <td
                            data-title="Amount"
                            class="table-font-arial right"
                            *ngIf="item.addons_parent_id === null"
                          >
                            {{ item.amount | number : "1.2-2" }}
                          </td>
                          <td
                            data-title="Actions"
                            class="action-itm"
                            *ngIf="item.addons_parent_id === null"
                          >
                            <button
                              type="button"
                              class="btn-sm del-btn btn"
                              (click)="onDeleteItem(i)"
                            >
                              <mat-icon>delete_outline</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          >Amount &nbsp;<i class="fa fa-{{ currency }}"></i
                        ></span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        formControlName="total_amount"
                        readonly
                      />
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Discount %</div>
                      </div>
                      <input
                        type="number"
                        formControlName="dis_per"
                        class="form-control"
                        (input)="onDiscountPer($event)"
                        (focus)="onDiscountFocus(true)"
                        (blur)="onDiscountFocus(false)"
                        min="0"
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="discount_amt"
                        class="form-control"
                        (input)="onDiscountAmt($event)"
                      />
                    </div>

                    <div
                      *ngIf="form.value.total_tax"
                      class="input-group mb-1 input-group-sm"
                    >
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          Tax &nbsp;
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="total_tax"
                        class="form-control"
                        readonly
                      />
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Other1</div>
                      </div>
                      <input
                        formControlName="other1_label"
                        class="form-control"
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="other1_amt"
                        class="form-control"
                        (input)="onOtherPrice()"
                        min="0"
                      />
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text">Other2</div>
                      </div>
                      <input
                        formControlName="other2_label"
                        class="form-control"
                      />
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="other2_amt"
                        class="form-control"
                        (input)="onOtherPrice()"
                        min="0"
                      />
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          Total &nbsp;
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="gross_amount"
                        class="form-control"
                        readonly
                      />
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          RoundOff &nbsp;
                          <i class="fa fa-{{ currency }}"></i>
                        </div>
                      </div>
                      <input
                        type="number"
                        formControlName="roundoff_amt"
                        class="form-control"
                        readonly
                      />
                    </div>
                    <table class="table table-borderless net-amt-table">
                      <td>
                        <label class="big form-bold-label">Net Amount:</label>
                      </td>
                      <td>
                        <label class="big form-bold-label">
                          <i class="fa fa-{{ currency }}"></i
                          >{{ form.value.net_amount | number : "1.2-2" }}</label
                        >
                      </td>
                    </table>
                  </div>

                  <!---------------------------------------------------------------old one START----------------------------------------------------------------->

                  <!-- <mat-action-row class="col-md-12">
                    <form
                      *ngIf="itemsArr.length > 0 && isEbillingEnable == 'true'"
                      [formGroup]="eBillsForm"
                      style="margin-right: 10px; margin-top: 10px"
                    >
                      <mat-form-field>
                        <mat-select
                          placeholder="E-Bills"
                          multiple
                          formControlName="selectedEbillNames"
                          required
                        >
                          <mat-option
                            *ngFor="let item of ebillData"
                            [value]="item"
                          >
                            {{ item.description }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </form>
                    <ng-container *ngIf="itemsArr.length > 0" class="col-md-12">
                      <div
                        class="input-group-text col-md-5 d-flex align-items-center"
                        style="margin-right: 0px; height: 30px"
                      >
                        <label for="checkbox2" style="margin-right: 8px"
                          >No cost</label
                        >
                        <input
                          type="checkbox"
                          id="checkbox2"
                          style="transform: scale(1.5); margin-left: 8px"
                          (change)="onCheckboxChange()"
                        />
                      </div>
                    </ng-container>
                    <form
                      class="col-md-8"
                      style="padding: 10px; margin-left: 20px"
                      *ngIf="itemsArr.length > 0"
                      [formGroup]="validationForm"
                      (ngSubmit)="onSubmit()"
                    >
                      <div *ngIf="showValidationInput" class="col-md-5">
                        <div *ngIf="!formSubmitted" style="width: 90px">
                          <input
                            formControlName="validationCode"
                            class="form-control"
                            placeholder="OTP"
                            style="width: 95%; height: 20px"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 d-flex justify-content-end">
                          <button
                            class="btn btn-primary btn-sm col-md-4"
                            type="button"
                            (click)="SaveAndPrint()"
                            *ngIf="
                              itemsArr.length > 0 && waitforinvoice === false
                            "
                            [disabled]="
                              showValidationInput &&
                              (!validationForm.valid || !otpVerified)
                            "
                            style="font-size: 10px"
                          >
                            Save & Print
                          </button>
                          <button
                            class="btn btn-primary btn-sm col-md-4"
                            type="button"
                            (click)="SaveAndEbill()"
                            *ngIf="
                              itemsArr.length > 0 &&
                              isEbillingEnable == 'true' &&
                              waitforinvoice === false
                            "
                            [disabled]="
                              showValidationInput &&
                              (!validationForm.valid || !otpVerified)
                            "
                            style="font-size: 10px; margin-left: 3px"
                          >
                            Save & eBill
                          </button>
                          <button
                            class="btn btn-primary btn-sm col-md-4"
                            type="button"
                            (click)="onSubmit()"
                            *ngIf="waitforinvoice === false"
                            [disabled]="
                              showValidationInput &&
                              (!validationForm.valid || !otpVerified)
                            "
                            style="font-size: 10px; margin-left: 3px"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </mat-action-row> -->

                  <!---------------------------------------------------------------new one END----------------------------------------------------------------->

                  <mat-action-row class="col-md-12">
                    <!-- First row for No cost checkbox and OTP input -->
                    <ng-container *ngIf="itemsArr.length > 0" class="col-md-12">
                      <div class="row mt-3">
                        <div
                          class="input-group-text col-md-5 d-flex align-items-center"
                          style="margin-right: 0px; height: 30px"
                        >
                          <label for="checkbox2" style="margin-right: 8px"
                            >No cost</label
                          >
                          <input
                            type="checkbox"
                            id="checkbox2"
                            style="transform: scale(1.5); margin-left: 8px"
                            (change)="onCheckboxChange()"
                          />
                        </div>
                        <div
                          [formGroup]="validationForm"
                          (ngSubmit)="onSubmit()"
                          *ngIf="showValidationInput"
                          class="col-md-5 mt-1"
                        >
                          <div *ngIf="!formSubmitted" style="width: 90px">
                            <input
                              formControlName="validationCode"
                              class="form-control"
                              placeholder="OTP"
                              style="width: 100%; height: 30px"
                            />
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <!-- Second row for buttons -->

                    <form
                      class="col-md-8"
                      style="padding: 10px; margin-left: 20px"
                      *ngIf="itemsArr.length > 0"
                      [formGroup]="validationForm"
                      (ngSubmit)="onSubmit()"
                    >
                      <div class="row">
                        <div class="col-md-12 d-flex justify-content-end">
                          <button
                            class="btn btn-primary btn-sm col-md-4"
                            type="button"
                            (click)="SaveAndPrint()"
                            *ngIf="
                              itemsArr.length > 0 && waitforinvoice === false
                            "
                            [disabled]="
                              showValidationInput &&
                              (!validationForm.valid || !otpVerified)
                            "
                            style="font-size: 10px"
                          >
                            Save & Print
                          </button>
                          <button
                          class="btn btn-primary btn-sm col-md-4"
                          type="button"
                          (click)="SaveAndEbill()"
                          *ngIf="itemsArr.length > 0 && waitforinvoice === false"
                          [disabled]="
                            showValidationInput && 
                            (!validationForm.valid || !otpVerified) ||
                            contact_no?.value?.length !== 10
                          "
                          style="font-size: 10px; margin-left: 3px">
                          Save & eBill
                        </button>
                          <button
                            class="btn btn-primary btn-sm col-md-4"
                            type="button"
                            (click)="onSubmit()"
                            *ngIf="waitforinvoice === false"
                            [disabled]="
                              showValidationInput &&
                              (!validationForm.valid || !otpVerified)
                            "
                            style="font-size: 10px; margin-left: 3px"
                          >
                            Save
                          </button>
                          <button
                            class="btn btn-primary btn-sm col-md-0"
                            type="button"
                            disabled
                            *ngIf="waitforinvoice === true"
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                            ></span>
                            wait...
                          </button>
                        </div>
                      </div>
                      <!-- <div
                        *ngIf="customerForm.invalid"
                        class="text-danger text-center mb-3"
                      >
                        **Fill the customer details first**
                      </div> -->
                    </form>
                  </mat-action-row>
                  <div
                    *ngIf="
                      form.controls['dis_per'].value > 0 &&
                      showValidationDiscount
                    "
                  >
                    <div style="color: red">
                      Please select either Discount or No Cost.
                    </div>
                  </div>
                  <div *ngIf="otpVerified" style="color: green">
                    OTP is verified
                  </div>
                  <div *ngIf="otpInvalid" style="color: red">Invalid OTP</div>
                </mat-expansion-panel>
                <mat-expansion-panel #panel4 hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title> Due Invoice </mat-panel-title>
                    <mat-panel-description>
                      Go for Due Invoice
                      <!-- <mat-icon>invoice</mat-icon> -->
                    </mat-panel-description>
                  </mat-expansion-panel-header>

                  <!-- <div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Cash Receive &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="cash_receive"
                        (keyup)="onCashReceive()">
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Cash &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="cash" (keyup)="onCashOrCard()">
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Card &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="card" (keyup)="onCashOrCard()">
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">UPI &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="upi" (keyup)="onCashOrCard()">
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Card/Upi Ref.No</span>
                      </div>
                      <input type="text" class="form-control" formControlName="card_ref_no">
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Total &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="advance" readonly>
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Balance &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" formControlName="balance" readonly>
                    </div>
                    <div class="input-group mb-1 input-group-sm">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Refund &nbsp;<i class="fa fa-{{currency}}"></i></span>
                      </div>
                      <input type="number" class="form-control" value="{{refund_amt}}" readonly>
                    </div>
                  </div> -->
                  <!-- <mat-datepicker #picker></mat-datepicker> -->

                  <table mat-table [dataSource]="dueInvoiceObj" matSort>
                    <!-- Code Column -->
                    <ng-container matColumnDef="customer_name">
                      <th
                        mat-header-cell
                        *matHeaderCellDef
                        [ngClass]="'customWidthClass'"
                        mat-sort-header
                      >
                        Customer
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let row"
                        data-label="Customer"
                        [ngClass]="'customWidthClass'"
                      >
                        {{ row.customer_name }}
                      </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="display_trans_no">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Invoice
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let row"
                        data-label="Invoice"
                        (click)="receiptDialog(row)"
                        style="color: blue; cursor: pointer"
                      >
                        {{ row.display_trans_no }}
                        <!-- <span *ngIf="!row.active" class="danger">-inactive</span> -->
                      </td>
                    </ng-container>
                    <!--Account Group-->
                    <ng-container matColumnDef="net_amount">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Amount
                      </th>
                      <td
                        mat-cell
                        *matCellDef="let row"
                        data-label="Amount"
                        style="text-align: right"
                      >
                        {{ row.net_amount | number : "1.2-2" }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="advance">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let row" data-label="advance">
                        <button
                          mat-icon-button
                          (click)="receiptDialog(row)"
                          style="float: right; color: blue"
                        >
                          <mat-icon>open_in_new</mat-icon>
                        </button>
                      </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="actions">
                      <th mat-header-cell *matHeaderCellDef>Action</th>
                      <td mat-cell *matCellDef="let row" data-label="Actions">
                        <button mat-icon-button (click)="receiptDialog(row)">
                          <mat-icon>open_in_new</mat-icon>
                        </button>
                      </td>
                    </ng-container> -->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                      mat-row
                      *matRowDef="let row; columns: displayedColumns"
                      [ngClass]="{ 'table-danger': row.active == false }"
                    ></tr>
                  </table>
                  <mat-paginator
                    [pageSizeOptions]="[5, 10, 25, 100]"
                    [pageSize]="10"
                    showFirstLastButtons
                  ></mat-paginator>

                  <mat-action-row>
                    <!-- <button type="button" #btnGrp class="btn-primary" (click)="onReceipt()">Receipt</button> -->
                    <!-- <button mat-button color="warn" (click)="prevStep()">Previous</button>
                <button mat-button color="primary" (click)="nextStep()">End</button> -->
                  </mat-action-row>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-tab>
            <mat-tab
              label="Take Away"
              style="font-weight: 600"
              class="billing-tab-ui"
            >
              <div class="col">
                <div class="">
                  <div class="row">
                    <div class="col-md-12 pt-1 customer">
                      <div *ngIf="tableObj">
                        <label class="font-weight-bold col-sm-6 w-100 p-0"
                          >Table
                          <span style="float: right; margin-right: 30px"
                            >:</span
                          ></label
                        >
                        <label class="col-form-label">{{
                          tableObj.name
                        }}</label>
                      </div>
                    </div>
                    <div class="col-md-12 pt-1 customer">
                      <label class="col-sm-6 w-100 p-0"
                        ><strong
                          >Trans no.
                          <span style="float: right; margin-right: 30px"
                            >:</span
                          ></strong
                        >
                      </label>
                      <label class=""> Auto</label>
                    </div>
                    <div class="col-md-12 pt-1 customer">
                      <div class="form-group w-100 row">
                        <label
                          for="contact_no"
                          class="col-sm-6 font-weight-bold pull-right"
                        >
                          Contact No
                          <span style="float: right">:</span>
                        </label>
                        <div class="col-sm-6 w-100 search-button">
                          <input
                            type="text"
                            placeholder="Enter Number"
                            aria-label="Search"
                            class="form-control form-control-xs"
                            [(ngModel)]="inputValue"
                            id="contact_no"
                            formControlName="contact_no"
                            [matAutocomplete]="numberAuto"
                            (input)="onInput($event)"
                            (ngModelChange)="handleInputChange($event)"
                            (keyup.enter)="handleEnterKey($event)"
                            maxlength="10"
                            pattern="[0-9]*"
                          />
                          <mat-autocomplete
                            #numberAuto="matAutocomplete"
                            autoActiveFirstOption
                          >
                            <mat-option
                              *ngFor="let i of listNumber"
                              (click)="searchByPhone(i)"
                              [value]="i"
                            >
                              <span class="item-font">{{ i }}</span>
                            </mat-option>
                          </mat-autocomplete>

                          <!-- Display error message if the number exceeds 10 digits -->
                        </div>
                      </div>

                      <div class="form-group w-100 row">
                        <label
                          for="customer_name"
                          class="col-sm-6 font-weight-bold pull-right"
                          >Customer Name<span style="float: right"
                            >:</span
                          ></label
                        >
                        <div class="col-sm-6 w-100">
                          <input
                            type="text"
                            class="form-control form-control-xs"
                            id="customer_name"
                            formControlName="customer_name"
                          />
                        </div>
                      </div>
                      <!-- <div class="form-group w-100 row">
                        <label
                          for="contact_no"
                          class="col-sm-6 font-weight-bold pull-right"
                          >Contact No <span style="float: right">:</span></label
                        >
                        <div class="col-sm-6 w-100">
                          <input
                            type="text"
                            class="form-control form-control-xs"
                            id="contact_no"
                            formControlName="contact_no"
                          />
                        </div>
                      </div> -->
                      <div class="form-group w-100 row">
                        <label
                          for="email"
                          class="col-sm-6 font-weight-bold pull-right"
                          >Email <span style="float: right">:</span></label
                        >
                        <div class="col-sm-6 w-100">
                          <input
                            type="text"
                            class="form-control form-control-xs"
                            id="email"
                            formControlName="email"
                          />
                        </div>
                      </div>
                      <div class="form-group w-100 row">
                        <label
                          for="email"
                          class="col-sm-6 font-weight-bold pull-right"
                        >
                          Address
                          <span style="float: right">:</span>
                        </label>
                        <div class="col-sm-6 w-100">
                          <textarea
                          style="height: 50px !important;"
                          class="form-control form-control-xs"
                          id="address"
                          formControlName="address"
                          rows="3"
                        ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pt-1">
                      <div class="form-group w-100 row">
                        <label
                          for="gstin"
                          class="col-sm-6 font-weight-bold pull-right"
                          >GSTIN <span style="float: right">:</span></label
                        >
                        <div class="col-sm-6 w-100">
                          <input
                            type="text"
                            class="form-control form-control-xs"
                            id="gstin"
                            formControlName="gstin"
                            style="
                              border: none;
                              border-radius: 0;
                              border-bottom: 1px solid;
                            "
                          />
                        </div>
                      </div>
                      <!-- <div class="col-sm-6">
                        <mat-form-field class="contract-full-width">
                          <input matInput [matDatepicker]="trans_date" placeholder="Date:" formControlName="trans_date"
                            required>
                          <mat-datepicker-toggle matSuffix [for]="trans_date"></mat-datepicker-toggle>
                          <mat-datepicker #trans_date></mat-datepicker>
                        </mat-form-field>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="scroll-sec-itm">
                  <table class="table item-tbl">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th class="itm-name">Item Name</th>
                        <th></th>
                        <th class="text-center">Qty</th>
                        <th></th>
                        <th class="right">Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody class="itm-tbody">
                      <ng-container
                        *ngFor="let item of itemsArr; let i = index"
                      >
                        <tr>
                          <td data-title="Sl">{{ i + 1 }}</td>
                          <td
                            data-title="Name"
                            class="table-font-arial itm-name"
                          >
                            {{ item.item_name }}
                          </td>
                          <td class="table-font-arial">
                            <button
                              mat-icon-button
                              type="button"
                              (click)="decrease_qty1(item, i)"
                              class="qty"
                            >
                              <mat-icon>remove_circle</mat-icon>
                            </button>
                          </td>
                          <td data-title="Qty" class="table-font-arial">
                            <input
                              type="text"
                              class="form-control form-control-xs text-center"
                              value="{{ convertFloat(item.qty) }}"
                              (keyup)="onChangeItemQty($event, i)"
                            />
                          </td>
                          <td>
                            <button
                              mat-icon-button
                              type="button"
                              (click)="increase_qty1(item, i)"
                              class="qtyright text-center"
                            >
                              <mat-icon>add_circle</mat-icon>
                            </button>
                          </td>
                          <td
                            data-title="Amount"
                            class="table-font-arial right"
                          >
                            {{ item.amount | number : "1.2-2" }}
                          </td>
                          <td data-title="Actions" class="action-itm">
                            <button
                              type="button"
                              class="btn-sm del-btn btn"
                              (click)="onDeleteItem(i)"
                            >
                              <mat-icon>delete_outline</mat-icon>
                            </button>
                          </td>
                        </tr>
                        <ng-container
                          *ngIf="
                            (item.checkedVariant &&
                              item.checkedVariant.length > 0) ||
                            (item.optionsData && item.optionsData.length > 0)
                          "
                        >
                          <tr>
                            <td>
                              <a
                                (click)="updateCustomisation(item)"
                                style="text-decoration: none; color: blue"
                                >Customisable</a
                              >
                            </td>
                          </tr>
                        </ng-container>
                        <tr>
                          <td colspan="7" class="table-font-arial">
                            <mat-form-field class="contract-full-width">
                              <textarea
                                matInput
                                placeholder="Write your cooking instruction here"
                                formControlName="instruction"
                                #n
                                (input)="instructiondataTakeaway(item, n)"
                              ></textarea>
                            </mat-form-field>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <mat-expansion-panel #panel1 hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="input-group mb-1 input-group-sm">
                        <div
                          class="input-group-prepend"
                          *ngFor="let i of disposallist"
                        >
                          <span
                            class="input-group-text"
                            (click)="showdisposalitems(i.category_id)"
                          >
                            {{ i.category_name }}</span
                          >
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="input-group mb-1 input-group-sm">
                    <div
                      class="input-group-prepend"
                      style="list-style-type: none; padding: 2px"
                      *ngFor="let i of disposalItemlist"
                    >
                      <div>
                        <button
                          class="input-group-text"
                          (click)="addItemsone(i)"
                          style="font-size: 0.875rem"
                        >
                          {{ i.item_name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
                <div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Amount &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="total_amount"
                      readonly
                    />
                  </div>

                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Discount %</div>
                    </div>
                    <input
                      type="number"
                      formControlName="dis_per"
                      class="form-control"
                      (input)="onDiscountPer($event)"
                      (focus)="onDiscountFocus(true)"
                      (blur)="onDiscountFocus(false)"
                      min="0"
                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="discount_amt"
                      class="form-control"
                      (input)="onDiscountAmt($event)"
                    />
                  </div>
                  <div
                    *ngIf="form.value.total_tax"
                    class="input-group mb-1 input-group-sm"
                  >
                    <div class="input-group-prepend">
                      <div class="input-group-text">Tax %</div>
                    </div>
                    <input
                      type="number"
                      value="{{ form.value.taxslab }}"
                      class="form-control"
                      readonly
                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="total_tax"
                      class="form-control"
                      readonly
                    />
                  </div>

                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Other1</div>
                    </div>
                    <input
                      formControlName="other1_label"
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="other1_amt"
                      class="form-control"
                      (keyup)="onOtherPrice()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Other2</div>
                    </div>
                    <input
                      formControlName="other2_label"
                      class="form-control"
                    />
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="other2_amt"
                      class="form-control"
                      (keyup)="onOtherPrice()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        Total &nbsp;
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="gross_amount"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        RoundOff &nbsp;
                        <i class="fa fa-{{ currency }}"></i>
                      </div>
                    </div>
                    <input
                      type="number"
                      formControlName="roundoff_amt"
                      class="form-control"
                      readonly
                    />
                  </div>
                  <table class="table table-borderless net-amt-table">
                    <td>
                      <label class="big form-bold-label">Net Amount:</label>
                    </td>
                    <td>
                      <label class="big form-bold-label">
                        <i class="fa fa-{{ currency }}"></i
                        >{{ form.value.net_amount | number : "1.2-2" }}</label
                      >
                    </td>
                  </table>
                </div>
                <div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Cash Receive &nbsp;<i
                          class="fa fa-{{ currency }}00"
                        ></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="cash_receive"
                      (keyup)="onCashReceive()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Cash &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="cash"
                      (keyup)="onCashOrCard()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Card &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="card"
                      (keyup)="onCashOrCard()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >UPI &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="upi"
                      (keyup)="onCashOrCard()"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Card Ref.No</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="card_ref_no"
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Total &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="advance"
                      readonly
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Balance &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      formControlName="balance"
                      readonly
                    />
                  </div>
                  <div class="input-group mb-1 input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        >Refund &nbsp;<i class="fa fa-{{ currency }}"></i
                      ></span>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      value="{{ refund_amt }}"
                      readonly
                    />
                  </div>
                </div>
                <!-- <mat-datepicker #picker></mat-datepicker> -->

                <mat-action-row>
                  <div class="row">
                    <!-- <div class="pull-left">
                      <form
                        *ngIf="
                          itemsArr.length > 0 && isEbillingEnable == 'true'
                        "
                        [formGroup]="eBillsForm"
                        style="margin-right: 10px; margin-top: 10px"
                      >
                        <mat-form-field>
                          <mat-select
                            placeholder="E-Bills"
                            multiple
                            formControlName="selectedEbillNames"
                            required
                          >
                            <mat-option
                              *ngFor="let item of ebillData"
                              [value]="item"
                            >
                              {{ item.description }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </form>
                    </div> -->
                    <div
                      class=""
                      [ngClass]="{
                        takeAwaySubmitButton:
                          itemsArr.length > 0 && isEbillingEnable == 'true'
                      }"
                    >
                      <button
                        class="btn-primary"
                        #btnGrp
                        type="button"
                        style="float: right"
                        disabled
                        *ngIf="waitforTakeaway === true"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                        ></span>
                        wait...
                      </button>

                      <button
                        type="button"
                        #btnGrp
                        class="btn-primary"
                        (click)="onAddKOT()"
                        *ngIf=" itemsArr.length > 0 && waitforTakeaway === false"
                      >
                        Kot & Invoice
                      </button>
                    </div>
                  </div>
                </mat-action-row>
              </div>

              <hr />
              <h5>Pending Invoice</h5>
              <table mat-table [dataSource]="dueInvoiceObj" matSort>
                <!-- Code Column -->
                <ng-container matColumnDef="customer_name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [ngClass]="'customWidthClass'"
                    mat-sort-header
                  >
                    Customer
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    data-label="Customer"
                    [ngClass]="'customWidthClass'"
                  >
                    {{ row.customer_name }}
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="display_trans_no">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Invoice
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    data-label="Invoice"
                    (click)="receiptDialog(row)"
                    style="color: blue; cursor: pointer"
                  >
                    {{ row.display_trans_no }}
                    <!-- <span *ngIf="!row.active" class="danger">-inactive</span> -->
                  </td>
                </ng-container>
                <!--Account Group-->
                <ng-container matColumnDef="net_amount">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Amount
                  </th>
                  <td
                    mat-cell
                    *matCellDef="let row"
                    data-label="Amount"
                    style="text-align: right"
                  >
                    {{ row.net_amount | number : "1.2-2" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="advance">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row" data-label="advance">
                    <button
                      mat-icon-button
                      (click)="receiptDialog(row)"
                      style="float: right; color: blue"
                    >
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <!-- <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let row" data-label="Actions">
                    <button mat-icon-button (click)="receiptDialog(row)">
                      <mat-icon>open_in_new</mat-icon>
                    </button>
                  </td>
                </ng-container> -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="{ 'table-danger': row.active == false }"
                ></tr>
              </table>
              <mat-paginator
                [pageSizeOptions]="[5, 10, 25, 100]"
                [pageSize]="10"
                showFirstLastButtons
              ></mat-paginator>
            </mat-tab>

            <!-- //collapse ----------------------------------------------------------------------->
          </mat-tab-group>
        </div>
      </div>
    </div>

    <!-- Popup -->
    <div *ngIf="showPopup" class="popup-screen" (click)="closePopup($event)">
      <div class="popup-content" (click)="$event.stopPropagation()">
        <h3>Search Items</h3>
        <div class="row search-items-food">
          <div class="col-md-5">
            <mat-form-field class="contract-full-width">
              <input
                id="popup-search-input"
                type="text"
                placeholder="Search Item"
                aria-label="Number"
                matInput
                [formControl]="itemControl"
                [matAutocomplete]="auto"
                (keyup)="searchItems($event)"
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                autoActiveFirstOption
                [displayWith]="displayFn"
                (optionSelected)="selectItem($event)"
              >
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  <!-- Your option template -->
                  <span class="item-font">({{ option.item_code }})</span>
                  <span class="item-font"> {{ option.item_name }}</span>
                  <br />
                  <small>
                    <span class="item-subtitle">category: </span>
                    <span *ngIf="option.category_name" class="item-rate">{{
                      option.category_name
                    }}</span>
                  </small>
                  <small>
                    <span class="item-rate-font">SRate: </span>
                    <span *ngIf="option.s_rate" class="item-rate">{{
                      option.s_rate | number : "1.2-2"
                    }}</span>
                  </small>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input
                matInput
                type="number"
                placeholder="Qty:"
                formControlName="item_qty"
                (keyup)="calculateItemAmt($event)"
                (change)="calculateItemAmt($event)"
                min="1"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input
                matInput
                type="number"
                placeholder="Rate:"
                formControlName="item_rate"
                [readonly]="!allowChangeRate"
                (keyup)="calculateItemAmt($event)"
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field class="contract-full-width">
              <input
                matInput
                type="number"
                placeholder="Amount:"
                formControlName="item_amt"
                readonly
              />
            </mat-form-field>
          </div>
          <div class="col-md-1 pt-3">
            <button
              type="button"
              class="btn btn-sm del-btn"
              (click)="onAddItem()"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>
          </div>
        </div>
        <div>
          <h4>Order List</h4>
          <ul>
            <li *ngFor="let item of orderList">
              {{ item.item_name }} - {{ item.qty }} - {{ item.amount }}
            </li>
          </ul>
        </div>
        <div class="shortcut-keys-note">
          <h4>Shortcut Keys:</h4>
          <p><strong>Ctrl + Alt + O</strong> - Open/Close this popup</p>
          <p><strong>Ctrl + Alt + M</strong> - Move items to KOT list</p>
          <p><strong>Esc</strong> - Close this popup</p>
        </div>
      </div>
    </div>
  </form>
</div>
