import {
  Component,
  OnInit,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { of } from "rxjs";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatExpansionPanel } from "@angular/material/expansion";

import { Router, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DataService } from "../../data.service";
import { RstKotComponent } from "../rst-kot/rst-kot.component";
import { environment } from "../../../environments/environment";
import { SocketServiceService } from "../../socket-service.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ItemsDialogComponent } from "../items-dialog/items-dialog.component";
import { ReceiptDialogComponent } from "../receipt-dialog/receipt-dialog.component";
import { TabletransferDialogComponent } from "../tabletransfer-dialog/tabletransfer-dialog.component";
import { MatTooltipModule } from "@angular/material/tooltip";
// import {MatButtonToggleModule} from '@angular/material/button-toggle';

import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { CacheServiceService } from "../../cache-service.service";
import { TaxSummaryService } from "../../tax-summary.service";
import { SharedModule } from "../../common.module";
import { RstDashboardService } from "../../_services/rst-dashboard.service";
import { Notyf } from "notyf";

import { TableChangeDialogComponent } from "../table-change-dialog/table-change-dialog.component";

import { ErrorStateMatcher } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";

interface ItemInt {
  item_name: string;
  // other properties...
}

const notyf = new Notyf({
  position: {
    x: "right",
    y: "top",
  },
});
@Component({
  selector: "app-rst-invoice",
  templateUrl: "./rst-keyboard.component.html",
  styleUrl: "./rst-keyboard.component.css",
  standalone: true,
  imports: [
    SharedModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class RstKeyboardComponent implements OnInit {
  categoryObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  isEditMode = false;
  editContactId: number | null = null;
  suggestions: any[] = [];
  listNumber: any[] = [];
  inputValue: string = "";
  selectedNumber: string = "";
  pendingInvoice: boolean = false;
  isEnterKeyHandling: boolean = false;
  showBackButton: any = "false";

  showPopup = false;
  kotList: any[] = [];
  orderList: any[] = [];
  showAdvancedSettings = false;
  previousCashReceive: number = 0;
  errorMessage: string | null = null;

  constructor(
    private apiService: RstDashboardService,
    private _Activatedroute: ActivatedRoute,
    private socketService: SocketServiceService,
    public dialog: MatDialog,
    //
    private cacheService: CacheServiceService,
    private spinner: NgxSpinnerService,
    private taxService: TaxSummaryService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.currency = environment.currency;
    _Activatedroute.paramMap.subscribe((params) => {
      if (params.get("id")) {
        this.table_id = params.get("id");
      }
    });

    this.validationForm = this.fb.group({
      validationCode: new FormControl(""),
    });

    this.eBillsForm = this.fb.group({
      selectedEbillNames: new FormControl([]),
    });
    // this.validationForm = this.fb.group({ });
    // this.eBillsForm  = this.fb.group({ });
    // this.form  = this.fb.group({ });

    // Initialize filtered options
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterItems(value))
    );
  }

  @Output() onKotAdd: EventEmitter<any> = new EventEmitter<any>();
  // model_kot: any = { trans_date: new Date(), net_amount: 0, notes: '' }
  model_kot: any = { trans_date: new Date(), net_amount: 0 };

  validationForm: FormGroup;
  eBillsForm: FormGroup;
  show_allow_login: boolean = false;
  allow_login: boolean = false;
  isQtyFocused: boolean = false;
  isdiscountFocused: boolean = false;
  table_id: any;
  table_name: any;
  tableObj: any;
  currency: any;
  kotArr: any = [];
  KOT_data: any = [];
  itemsArr: any = [];
  model: any = {};
  select_all: boolean = true;
  @ViewChild("f") f: any;
  sgst: any = [];
  cgst: any = [];
  igst: any = [];
  customer_item_rate_applicable: boolean = false;
  use_qty_from_sale_history: boolean = false;
  @ViewChild("s_rate1") s_rate1!: ElementRef;
  @ViewChild("qty1") qty1!: ElementRef;
  @ViewChild("sitm") sitm!: ElementRef;
  @ViewChild("itemSearchInput", { static: true }) itemSearchInput!: ElementRef;
  @ViewChild("contact_no") contactNoInput!: ElementRef;
  @ViewChild("cashInput") cashInput!: ElementRef;
  @ViewChild("upiInput") upiInput!: ElementRef;

  width: any = window.innerWidth;
  invoice_no: any;
  contractObj: any;
  ledgerObj: any;
  ledger_state: any;
  transDataObj: any = { items_details: [] };
  items: any = [];
  isAutoFocus: boolean = false;
  refund_amt: any = 0;
  tax: any = [];
  totalAmt: any;
  amount: any;
  totalTax: any;
  totalNetAmt: any;
  roundOffAmt: any;
  totalDis: any;
  totalSGST: any;
  totalCGST: any;
  totalIGST: any;
  ledger_id: any;
  sgstUnqPer: any = [];
  igstUnqPer: any = [];
  sign: any;
  dataObj: any = {};
  _generating_sales_order: boolean = false;

  itemControl = new FormControl();
  options: any;
  filteredOptions: Observable<any[]> = new Observable<any>();
  itemObj: any = [];
  allowChangeRate: boolean = false;
  mapArr: any;
  soIdArr: any = [];
  taxSlabList: any;
  checkEnableResturantFlag: boolean = false;
  catArr: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  itemObject: MatTableDataSource<any> = new MatTableDataSource<any>();
  searchText: any;
  activeType: any = false;
  imagedata: any;
  itemArr: any;
  items_data: any = [];
  @ViewChild("yourExpansionPanel") yourExpansionPanel!: MatExpansionPanel;
  @ViewChild("panel") panel!: MatExpansionPanel;
  @ViewChild("panel3") panel3!: MatExpansionPanel;
  @ViewChild("panel4") panel4!: MatExpansionPanel;
  data: any;
  isTransactionGSTslab: boolean = false;
  transaction_details: any;
  items_invoice: any = [];
  company: any;
  isCash: boolean = true;
  isSOcount: boolean = false;
  so_count: any;
  isDOcount: boolean = false;
  do_count: any;
  customerRates: any;
  isCredit: any;
  stateName: any;
  place_of_supply = new FormControl();
  sbilling_state = new FormControl();
  shipping_state = new FormControl();
  totalamt: any;
  invoice_arr: any = [];
  webappActiveTable: boolean = false;
  closing_balance1: any;
  closing_balance2: any;
  totalAmtReadonlyFlg: boolean = false;
  receiptdata: any;
  dueInvoice: any;
  displayedColumns = [
    "customer_name",
    "display_trans_no",
    "net_amount",
    "advance",
  ];
  dueInvoiceObj: MatTableDataSource<any> = new MatTableDataSource<any>();
  tableDetails: any;
  selectedTable: any;
  take_away = true;
  dine_in = true;
  water: boolean = false;
  waiter: boolean = false;
  notesValueForEdit: any;
  disposalItemData: any;
  disposallist: any;
  disposalItemlist: any;
  disposalItemToggle = false;
  showValidationInput = false;
  showValidationDiscount = false;

  otpVerified = false;
  otpInvalid = false;
  non_receivable = false;
  formSubmitted = false;
  order_tab: any = "Take Away";
  sub_category_data: any;
  subCatArr = [];
  getItems: boolean = false;
  getCategory: boolean = false;
  getSubCategory: boolean = false;
  getDisposalitems: boolean = false;
  getDisposalCategory: boolean = false;
  isLocal: boolean = false;
  tableInfo: any;
  tableCapacity: any = 0;
  optionarray_amount: number = 0;
  options_array: any[] = [];
  checkedVariant: any[] = [];
  checkedVariantAmount: number = 0;
  connection: any;
  selectedCategoryName: any = null;
  ebillData: any = [];
  menuItemsWithPriceList: any = [];
  isEbillingEnable: any = "false";
  roundOffSettingVal:any;
  // selectedEbillNames: string[] = [];
  ngOnInit() {
    this.connectSocket();
    this.load();

    this.validationForm
      .get("validationCode")
      ?.valueChanges.subscribe((value: string) => {
        if (value && value.length === 5) {
          this.otpVerified = false;
          this.otpInvalid = false;
        } else if (value && value.length === 6) {
          // this.verifyEmailOtp(value);
        }
      });

    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterItems(value))
    );
  }

  // Event emitters for actions (local to the user's session)
  private refreshComponent() {
    this.load(); // Reload the component's data for the current user only
  }

  ngOnDestroy() {
    this.connection.unsubscribe();
  }
  resetOTPFields() {
    this.validationForm.reset();
    this.otpVerified = false;
    this.otpInvalid = false;
    this.showValidationDiscount = false;
  }
  onCheckboxChange() {
    if (this.form.controls["dis_per"].value === 0 || null) {
      if (!this.showValidationInput) {
        this.apiService.ncValidation().subscribe(
          (response: any) => {
            this.showValidationDiscount = false;
            this.non_receivable = true;
          },
          (error: any) => {
            console.error("API error:", error);
          }
        );
      } else {
        this.non_receivable = false;
      }
    } else {
      notyf.error("Select either Discount or No Cost");
    }
    this.showValidationInput = !this.showValidationInput;
    this.showValidationDiscount = !this.showValidationDiscount;
    if (!this.showValidationInput) {
      this.resetOTPFields();
    }
  }

  processCategoryList(result: any): void {
    this.catArr = result.filter(
      (category: any) => category.restaurant_menu_items == true
    );
    this.catArr.forEach((category: any) => {
      const cachedImage = this.cacheService.get(
        `categoryImage_${category.category_id}`
      );
      if (cachedImage) {
        this.setImageUrl(category, cachedImage);
      } else {
      }
    });
  }

  setImageUrl(category: any, url: string): void {
    category.imageurl = url;
  }

  connectSocket() {
    this.connection = this.socketService
      .on("refresh")
      .subscribe((result: any) => {
        this.socketService.emit("socketIds", {
          company_id: localStorage.getItem("company_id"),
        });
        this.socketService.on("getSocketIds").subscribe((result: any) => {
        });
        if (!this._generating_sales_order) {
          this.apiService.getTableByTransId(result.transaction_id).subscribe(
            (res: any) => {
              if (res[0].table_id == this.table_id) {
                this.reset();
              }
            },
            (error) => {}
          );
        }
      });
  }

  createImageFromBlob(image: Blob, fn: any) {
    let reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        var reader_result = reader.result;
        fn(reader_result);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
  load() {
    (this.isEbillingEnable = localStorage.getItem("isEBillingEnable")),
      (this.eBillsForm = this.fb.group({
        selectedEbillNames: new FormControl([]),
      }));
    if (this.isEbillingEnable) this.getEbillingdata();

    this.roundOffSettingVal  = localStorage.getItem("roundoff");

    // var keyData = {
    //   roleid: localStorage.getItem('role_id'),
    //   key: 'ChangeMRP'
    // }
    // this.apiService.getActionPermissionByKey(keyData)
    //   .subscribe((result: any) => {
    //     if (result.length) {
    //       this.allowChangeRate = true;
    //     }
    //   }, (error) => {

    //   })

    this.tableInfoFun();

    // -----------------webapp active table-----------------
    this.apiService.getWebActiveTable(this.table_id).subscribe(
      (result: any) => {
        if (result.success !== false) {
          this.webappActiveTable = result[0].webactive;
        }
      },
      (err: any) => {
        console.log("err", err);
      }
    );
  }

  tableInfoFun() {
    var keyData = {
      roleid: localStorage.getItem("role_id"),
      key: "ChangeMRP",
    };
    const data = [];
    data.push({
      getItems: this.getItems,
      getCategory: this.getCategory,
      getSubCategory: this.getSubCategory,
      getDisposalitems: this.getDisposalitems,
      getDisposalCategory: this.getDisposalCategory,
      keyData: keyData,
    });
    this.spinner.show();
    this.apiService.getTableInfo(this.table_id, data).subscribe(
      async (result: any) => {
        this.spinner.hide();
        this.tableInfo = result[0];
        this.tableCapacity = this.tableInfo.tableCapacity;
        //---------mapArr----------------------------------------------
        if (this.tableInfo.accountMapList) {
          this.mapArr = this.tableInfo.accountMapList;
          if (this.mapArr) {
            this.findAccSetting();
          }
        }

        //---------session---------------------------------------------
        if (this.tableInfo.session !== null && this.tableInfo.session.length) {
          this.show_allow_login = true;
          // this.webappActiveTable = this.tableInfo.session[0].active
          this.water = this.tableInfo.session[0].water;
          this.waiter = this.tableInfo.session[0].waiter;
          this.allow_login = this.tableInfo.session[0].allow_login;
          this.form
            .get("customer_name")
            ?.setValue(this.tableInfo.session[0].name);
          this.form
            .get("contact_no")
            ?.setValue(this.tableInfo.session[0].contact_no);
          this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
          this.form.get("email")?.setValue(this.tableInfo.session[0].email);
          this.form.get("gstin")?.setValue(this.tableInfo.session[0].gstin);
        }
        //---------TableListWithKOT------------------------------------
        this.tableObj = this.tableInfo.kot;
        if (this.tableObj) {
          this.tableObj.forEach((kot: any) => {
            kot.added = true;
            this.soIdArr.push(kot.transaction_id);
            this.kotArr.push(JSON.parse(JSON.stringify(kot)));
            kot.items_details.forEach((item: any) => {
              var pos = this.itemsArr.findIndex(
                (e: any) =>
                  e.item_id === item.item_id && e.s_rate == item.s_rate
              );
              if (pos == -1) {
                this.itemsArr.push(item);
              } else {
                this.itemsArr[pos].qty =
                  parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
                this.itemsArr[pos].amount =
                  parseFloat(this.itemsArr[pos].qty) *
                  parseFloat(this.itemsArr[pos].s_rate);
              }
            });
          });
          this.calculateAmtWithTransTax();
        }

        //---------UnpaidInvoice----------------------------------------
        if (this.tableInfo.unpaidInvoiceList) {
          this.dueInvoice = this.tableInfo.unpaidInvoiceList;
          this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
          this.dueInvoiceObj.sort = this.sort;
          this.dueInvoiceObj.paginator = this.paginator;
        } else {
          this.dueInvoiceObj = new MatTableDataSource();
        }

        //--------companyState----------------------------------------
        if (this.tableInfo.companyState) {
          this.company = this.tableInfo.companyState[0];
        }

        // -----------------isLocal-----------------
        this.isLocal = this.tableInfo.isLocal;
        localStorage.setItem("isLocal", JSON.stringify(this.isLocal));

        //---------tableName------------------
        this.table_name = this.tableInfo.tableName
          ? this.tableInfo.tableName
          : null;
        //---------allowChangeRate------------------
        this.allowChangeRate = this.tableInfo.allowChangeRate ? true : false;

        // await this.renderItems(this.tableInfo.tableDetails);
      },
      (result: any) => {
        //notyf.error("unable to load data");
      }
    );
  }

  renderItems(tableInfo: any) {
    const priceListName =
      tableInfo[0].pricelist_name && tableInfo[0].pricelist_name != null
        ? tableInfo[0].pricelist_name
        : "default";
    const keys: any = [];

    // Iterate over each object in the array
    this.menuItemsWithPriceList.forEach((obj: any) => {
      // Get keys from the current object and push them into the keys array
      const objKeys = Object.keys(obj);
      keys.push(objKeys);
    });

    let selectedItems: any = [];
    // switch (priceListName) {
    //   case 'default':
    //     selectedItems = this.menuItemsWithPriceList.find((item:any) => item.default);
    //     break;
    //   default:
    keys.forEach((key: any) => {
      if (priceListName === key[0]) {
        selectedItems = this.menuItemsWithPriceList.find(
          (item: any) => item[key[0]]
        );
      }
    });
    // }

    this.itemArr = selectedItems[priceListName];
    // this.itemObject = this.itemArr;
    // this.itemObject = new MatTableDataSource(this.itemArr);
    // this.itemObject.sort = this.sort;
    // this.itemObject.paginator = this.paginator;
  }
  // isTransactionGSTslabtrue() {
  //   this.apiService.getAccountMapList()
  //     .subscribe((result: any) => {
  //       result.filter(e => {
  //         if (e.key === 'Transaction_gstslab') {
  //           this.isTransactionGSTslab = e.checkbox_value
  //         }
  //       })

  //     }, (result: any) => {
  //       //notyf.error( 'unable to load data');
  //     });
  // }

  // errorImage: string = '/assets/images/NoImage.png';
  handelerror(event: any) {
    if (event.target) {
      event.target.src = "/assets/images/NoImage.png";
    }
  }

  // getPendingInvoice() {
  //   this.apiService.getUnpaidInvoice(this.table_id)
  //     .subscribe((result: any) => {
  //       if (result.success !== false) {
  //         this.dueInvoice = result;
  //         this.dueInvoiceObj = new MatTableDataSource(this.dueInvoice);
  //         this.dueInvoiceObj.sort = this.sort;
  //         this.dueInvoiceObj.paginator = this.paginator;

  //       } else {
  //         this.dueInvoiceObj = new MatTableDataSource()
  //       }
  //     })
  // }
  _filter(value: any) {
    const filterValue = typeof value != "object" ? value.toLowerCase() : value;
    return this.itemObj.filter(
      (item: any) =>
        item.item_code.toLowerCase().includes(filterValue) ||
        item.item_name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(Item: any) {
    return Item ? Item.item_name : "";
  }

  // search(event: any) {
  //   const inputValue = this.itemControl.value || "";

  //   // Ensure inputValue is not null or undefined before checking its length
  //   if (inputValue && inputValue.length > 1) {
  //     const filter = "restaurant_menu_items";
  //     this.apiService.getCustomFilterItemList(inputValue, filter).subscribe(
  //       (result: any) => {
  //         this.itemObj = result;
  //         this.filteredOptions = this.itemControl.valueChanges.pipe(
  //           startWith(""),
  //           map((value) => this._filter(value))
  //         );
  //       },
  //       (error: any) => {
  //         console.error("API error:", error);
  //       }
  //     );
  //   } else {
  //     this.itemObj = [];
  //     this.filteredOptions = this.itemControl.valueChanges.pipe(
  //       startWith(""),
  //       map((value) => this._filter(value))
  //     );
  //   }
  // }

  search(event: any) {
    if (event.key !== "ArrowDown" && event.key !== "ArrowUp") {
      const itemControlValue = this.itemControl?.value || ''; // Add null/undefined check

      if (itemControlValue.length > 1) {
        const filter = "restaurant_menu_items";
        this.apiService
          .getCustomFilterItemList(itemControlValue, filter, this.table_id)
          .subscribe(
            (result: any) => {
              this.itemObj = result;
              this.filteredOptions = this.itemControl.valueChanges.pipe(
                startWith(""),
                map((value) => this._filter(value))
              );
            },
            (error: any) => {
              // Handle error if needed
            }
          );
      } else {
        this.itemObj = [];
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      }
    }
  }

  itemSelection(event: MatAutocompleteSelectedEvent) {
    if (event.option.value != 0) {
      this.focusSetting();
      const selectedItem = event.option.value;
      const item_id = selectedItem ? selectedItem.item_id : undefined;
      const indexofItem = this.itemObj.findIndex((i: any) => i.item_id === item_id);

      // Check if the item already exists in the list
      const existingItemIndex = this.itemsArr.findIndex(
        (item: any) => item.item_id === item_id && item.s_rate === selectedItem.s_rate
      );

      if (existingItemIndex > -1) {
        // If the item already exists, increase its quantity
        this.itemsArr[existingItemIndex].qty++;
        const newAmount =
          this.itemsArr[existingItemIndex].qty * this.itemsArr[existingItemIndex].s_rate;
        this.itemsArr[existingItemIndex].amount = newAmount;
        this.calculateAmtWithTransTax();
      } else {
        // If the item has options or variants, open customization
        if (selectedItem.options.length > 0 || selectedItem.variant_groups.length > 0) {
          const data = { item: this.itemObj[indexofItem], status: "add" };
          this.openCustomization(data);
        } else {
          const isCustomised = false;
          this.populateItemData(this.itemObj[indexofItem], isCustomised);
        }
      }

      // Move focus to qty1 element after item selection
      setTimeout(() => {
        if (this.qty1 && this.qty1.nativeElement) {
          this.qty1.nativeElement.focus();
        }
      }, 0);
    }
  }

  populateItemData(itemDetails: any, isCustomised: any) {
    this.model = itemDetails;
    const qty = itemDetails.qty ? itemDetails.qty : 1;
    const a = this.model.qty * this.model.s_rate;
    const b = this.model.qty * this.optionarray_amount;
    const amount = a + b;
    this.model.amount = amount ? amount : itemDetails.s_rate * qty;
    this.model.qty = qty;

    this.form.get("item_qty")?.setValue(this.model.qty);
    this.form.get("item_rate")?.setValue(this.model.s_rate);
    this.form.get("item_amt")?.setValue(this.model.amount);

    if (isCustomised) this.onAddItem();
  }

  focusSetting() {
    if (this.isAutoFocus) {
      // this.qty1.nativeElement.focus();
    }
  }

  focusSettingSearch() {
    setTimeout(() => {
      if (this.sitm && this.sitm.nativeElement) {
        this.sitm.nativeElement.focus(); // Set focus back to the search input
      }
    }, 0);
  }
  // onQtyFocus(e:any) {
  //   if (e:any) {
  //     this.isQtyFocused = true
  //   } else {
  //     this.isQtyFocused = false;
  //   }
  // }
  // calculateItemAmt(event:any) {

  //   var qty = this.form.value.item_qty;
  //   var s_rate = this.form.value.item_rate;

  //   const a = (qty ? qty : 0) * (s_rate ? s_rate : 0);
  //   const b = (qty ? qty : 0) * this.optionarray_amount

  //   var amount = a + b;
  //   this.form.get('item_amt').setValue(amount);
  //   this.model.qty = qty;
  //   this.model.s_rate = s_rate;
  //   this.model.amount = amount;

  //   if (event.key == "Enter" && !this.isQtyFocused) {
  //     this.onAddItem();
  //     // this.addItems(this.itemsdata);
  //     this.sitm.nativeElement.focus();
  //   }
  //   if (event.key == "Enter" && this.isQtyFocused) {
  //     this.s_rate1.nativeElement.focus()
  //   }
  // }
  onAddItem() {
    if (this.model.qty) {
      console.clear();
      // Check if the item already exists in the KOT_data or itemsArr
      const pos = this.KOT_data.findIndex(
        (e: any) =>
          e.item_id === this.model.item_id && e.s_rate == this.model.s_rate
      );
      const found_item = { ...this.model }; // Copy the model to avoid mutating it directly
      let new_item_Added = false;

      if (pos === -1) {
        // If the item does not exist in KOT_data, mark it as a new item
        new_item_Added = true;
      } else {
        // If the item already exists, update its quantity and amount
        new_item_Added = false;
        if (this.order_tab === "Dine-In") {
          this.KOT_data[pos].qty =
            parseFloat(this.KOT_data[pos].qty) + parseFloat(this.model.qty);
          const a =
            parseFloat(this.KOT_data[pos].qty) *
            parseFloat(this.KOT_data[pos].s_rate);
          const b =
            parseFloat(this.KOT_data[pos].qty) * this.optionarray_amount;

          this.KOT_data[pos].amount = a + b;
          this.panel.open();
        }
        if (this.order_tab === "Take Away") {
          const itemPos = this.itemsArr.findIndex(
            (item: any) => item.item_code === this.model.item_code
          );

          if (itemPos > -1) {
            // If the item exists in itemsArr, increase its quantity
            this.itemsArr[itemPos].qty =
              parseFloat(this.itemsArr[itemPos].qty) +
              parseFloat(this.model.qty);
            const a =
              parseFloat(this.itemsArr[itemPos].qty) *
              parseFloat(this.itemsArr[itemPos].s_rate);
            const b =
              parseFloat(this.itemsArr[itemPos].qty) * this.optionarray_amount;
            this.itemsArr[itemPos].amount = a + b;
            this.calculateAmtWithTransTax();
          } else {
            // If the item does not exist, add it as a new entry
            this.itemsArr.push({
              ...this.model,
              qty: parseFloat(this.model.qty),
              amount:
                parseFloat(this.model.qty) * parseFloat(this.model.s_rate),
            });
            this.calculateAmtWithTransTax();
          }
        }
      }

      if (this.isTransactionGSTslab === false) {
        if (found_item.tax_mode === "GST") {
          if (this.isLocal) {
            found_item.sgst_per = this.model.taxslab / 2;
            found_item.sgst_amt =
              this.model.amount * (this.model.sgst_per / 100) || 0;
            found_item.sgst_amt = Math.round(this.model.sgst_amt * 100) / 100;
            found_item.cgst_per = this.model.taxslab / 2;
            found_item.cgst_amt =
              this.model.amount * (this.model.cgst_per / 100) || 0;
            found_item.cgst_amt = Math.round(this.model.cgst_amt * 100) / 100;
          } else {
            found_item.igst_per = this.model.taxslab;
            found_item.igst_amt =
              this.model.amount * (this.model.igst_per / 100) || 0;
            found_item.igst_amt = Math.round(this.model.igst_amt * 100) / 100;
          }

          found_item.total_tax =
            found_item.sgst_amt ||
            0 + found_item.cgst_amt ||
            0 + found_item.igst_amt ||
            0;
        } else {
          found_item.vat_per = this.model.taxslab;
          found_item.vat_amt =
            this.model.amount * (this.model.igst_per / 100) || 0;
          found_item.vat_amt = Math.round(this.model.igst_amt * 100) / 100;
          found_item.total_tax = found_item.vat_amt || 0;
        }
        found_item.total = this.model.amount + found_item.total_tax;
      }
      if (new_item_Added) {
        // If the item is new, add it to the respective list
        if (this.order_tab === "Dine-In") {
          this.KOT_data.push(found_item);
          this.panel.open();
        }
        if (this.order_tab === "Take Away") {
          // Assuming selectedItem is the item being added and item_qty is the quantity from the form
          const selectedItem = this.itemControl.value as any; // Replace with how you get the selected item
          const selectedQty = this.form.value.item_qty || 1; // Replace with your method of obtaining the quantity

          const existingItemIndex = this.itemsArr.findIndex(
            (item: any) => item.item_code === selectedItem.item_code
          );

          if (existingItemIndex > -1) {
            // If the item exists, increase its quantity
            this.itemsArr[existingItemIndex].qty += selectedQty;
            this.itemsArr[existingItemIndex].amount =
              this.itemsArr[existingItemIndex].qty *
              this.itemsArr[existingItemIndex].s_rate;
          } else {
            // If the item does not exist, add it as a new entry
            this.itemsArr.push({
              ...selectedItem,
              qty: selectedQty,
              amount: selectedQty * selectedItem.s_rate,
            });
          }

          this.calculateAmtWithTransTax();
        }
      }
      this.model = {};
      this.initialiseItem();
      this.form.get("item_qty")?.setValue(null);
      this.form.get("item_rate")?.setValue(null);
      this.form.get("item_amt")?.setValue(null);
    }

    this.focusSettingSearch();
  }

  initialiseItem() {
    this.itemControl.setValue(null);
    this.itemObj = [];
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }
  findAccSetting() {
    var takeAway = this.mapArr.filter((e: any) => {
      return e.key === "Take_Away_Table";
    });
    if (takeAway[0].template_value === this.table_id) {
      (this.dine_in = false), (this.order_tab = "Take Away");
    }

    this.mapArr.filter((e: any) => {
      if (e.key === "Transaction_gstslab") {
        this.isTransactionGSTslab = e.checkbox_value;
      }
    });

    if (this.isTransactionGSTslab) {
      var indexofTrSlabValue = this.mapArr.findIndex(
        (i: any) => i.key === "gstslab_value"
      );

      this.form
        .get("taxslab")
        ?.setValue(this.mapArr[indexofTrSlabValue].template_value);
    }
    var indexofautofocus = this.mapArr.findIndex(
      (i: any) => i.key === "Enable_Auto_Focus"
    );

    if (this.mapArr[indexofautofocus].checkbox_value) {
      this.isAutoFocus = true;
    }
  }

  calculateAmtWithTransTax() {
    if (this.isTransactionGSTslab === false) {
      this.amount = 0;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0,
        vat_per = 0,
        vat_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      for (var i = 0; i < this.itemsArr.length; i++) {
        this.amount += this.itemsArr[i].amount;
        if (this.itemsArr[i].tax_mode === "GST") {
          sgst_amt += this.itemsArr[i].sgst_amt || 0;
          cgst_amt += this.itemsArr[i].cgst_amt || 0;
          igst_amt += this.itemsArr[i].igst_amt || 0;
        } else {
          vat_amt += this.itemsArr[i].vat_amt || 0;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;

      this.totalTax = sgst_amt + cgst_amt + igst_amt + vat_amt;
      const discountPer = this.form.get("dis_per")?.value || 0;
      if (discountPer > 0) {
        const disAmt = (this.totalTax * discountPer) / 100;
        this.totalTax = this.totalTax - disAmt;
      }
      this.totalAmt =
        taxable_amt +
        this.totalTax +
        this.form.value.other1_amt +
        this.form.value.other2_amt;

      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffSettingVal  == 2) {
        // Round after two decimal places
        this.totalNetAmt = Math.round(this.totalAmt * 100) / 100; // Round to two decimal places
        this.roundOffAmt = Math.round((this.totalNetAmt - this.totalAmt) * 100) / 100; // Calculate round-off amount

      } else if (this.roundOffSettingVal  == 1) {
        // Round after one decimal place
        this.totalNetAmt = Math.round(this.totalAmt * 10) / 10; // Round to one decimal place
        this.roundOffAmt = Math.round((this.totalNetAmt - this.totalAmt) * 10) / 10; // Calculate round-off amount

      } else {
        // General round-off calculation
        if (this.roundOffAmt < 0.5) {
          this.totalNetAmt = this.totalAmt - this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.roundOffAmt = -this.roundOffAmt;
          this.sign = "fa-minus";
        } else {
          this.roundOffAmt = 1 - this.roundOffAmt;
          this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
          this.totalNetAmt = this.totalAmt + this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.sign = "fa-plus";
        }
      }

      this.form.get("total_amount")?.setValue(this.amount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("vat_per")?.setValue(vat_per);
      this.form.get("vat_amt")?.setValue(vat_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt);
      this.refreshPayment();
    } else {
      this.amount = 0;
      var tax_slab = this.form.value.taxslab;

      var taxable_amt = 0,
        tax_amt = 0,
        sgst_per = 0,
        sgst_amt = 0,
        cgst_per = 0,
        cgst_amt = 0,
        igst_per = 0,
        igst_amt = 0;
      this.sign = null;
      this.totalAmt = 0;
      this.totalDis = 0;
      this.amount = 0;
      let vatAmount = 0;
      let vatTaxAmount = 0;
      for (var i = 0; i < this.itemsArr.length; i++) {
        if (this.itemsArr[i].tax_mode === "GST") {
          this.amount = this.amount + this.itemsArr[i].amount;
        } else {
          this.itemsArr[i].vat_per = this.itemsArr[i].taxslab;
          this.itemsArr[i].vat_amt =
            this.itemsArr[i].amount * (this.itemsArr[i].vat_per / 100) || 0;
          this.itemsArr[i].vat_amt =
            Math.round(this.itemsArr[i].vat_amt * 100) / 100;
          this.itemsArr[i].total_tax = this.itemsArr[i].vat_amt || 0;

          this.itemsArr[i].total =
            this.itemsArr[i].amount + this.itemsArr[i].total_tax;
          vatTaxAmount += this.itemsArr[i].total_tax;
          vatAmount += this.itemsArr[i].amount;
        }
      }
      taxable_amt = this.amount - this.form.value.discount_amt;
      sgst_per = tax_slab / 2;
      sgst_amt = taxable_amt * (sgst_per / 100);
      sgst_amt = Math.round(sgst_amt * 100) / 100;
      cgst_per = tax_slab / 2;
      cgst_amt = taxable_amt * (cgst_per / 100);
      cgst_amt = Math.round(cgst_amt * 100) / 100;

      tax_amt = sgst_amt + cgst_amt;
      this.totalTax = sgst_amt + cgst_amt + igst_amt + vatTaxAmount;

      this.totalAmt =
        taxable_amt +
        vatAmount +
        tax_amt +
        vatTaxAmount +
        this.form.value.other1_amt +
        this.form.value.other2_amt;
      this.totalAmt = Math.round(this.totalAmt * 100) / 100;
      this.roundOffAmt = this.totalAmt - Math.floor(this.totalAmt);
      this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
      if (this.roundOffSettingVal  == 2) {
        // Round after two decimal places
        this.totalNetAmt = Math.round(this.totalAmt * 100) / 100; // Round to two decimal places
        this.roundOffAmt = Math.round((this.totalNetAmt - this.totalAmt) * 100) / 100; // Calculate round-off amount

      } else if (this.roundOffSettingVal  == 1) {
        // Round after one decimal place
        this.totalNetAmt = Math.round(this.totalAmt * 10) / 10; // Round to one decimal place
        this.roundOffAmt = Math.round((this.totalNetAmt - this.totalAmt) * 10) / 10; // Calculate round-off amount

      } else {
        // General round-off calculation
        if (this.roundOffAmt < 0.5) {
          this.totalNetAmt = this.totalAmt - this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.roundOffAmt = -this.roundOffAmt;
          this.sign = "fa-minus";
        } else {
          this.roundOffAmt = 1 - this.roundOffAmt;
          this.roundOffAmt = Math.round(this.roundOffAmt * 100) / 100;
          this.totalNetAmt = this.totalAmt + this.roundOffAmt;
          this.totalNetAmt = Math.round(this.totalNetAmt);
          this.sign = "fa-plus";
        }
      }

      this.form.get("total_amount")?.setValue(this.amount);
      this.form.get("net_amount")?.setValue(this.totalNetAmt);
      this.form.get("roundoff_amt")?.setValue(this.roundOffAmt);
      this.form.get("gross_amount")?.setValue(this.totalAmt);
      this.form.get("total_tax")?.setValue(this.totalTax);
      this.form.get("sgst_per")?.setValue(sgst_per);
      this.form.get("sgst_amt")?.setValue(sgst_amt);
      this.form.get("cgst_per")?.setValue(cgst_per);
      this.form.get("cgst_amt")?.setValue(cgst_amt);
      this.form.get("igst_per")?.setValue(igst_per);
      this.form.get("igst_amt")?.setValue(igst_amt);
      this.form.get("taxable_amt")?.setValue(taxable_amt);
      this.refreshPayment();
    }
  }

  onDeleteItem1(i: any) {
    this.KOT_data.splice(i, 1);
    this.calculateAmtWithTransTax();
  }

  onDeleteItem(i: any) {
    this.itemsArr.splice(i, 1);
    this.calculateAmtWithTransTax();
  }
  onSelectAllKOT(isChecked: boolean) {
    this.kotArr.forEach((kot: any) => {
      if (isChecked) {
        this.select_all = true;
        if (kot.added == false) {
          kot.added = true;
          this.soIdArr.push(kot.transaction_id);
          kot.items_details.forEach((item: any) => {
            var pos = this.itemsArr.findIndex(
              (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
            );
            if (pos == -1) {
              this.itemsArr.push(JSON.parse(JSON.stringify(item)));
            } else {
              this.itemsArr[pos].qty =
                parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);
              this.itemsArr[pos].amount =
                parseFloat(this.itemsArr[pos].qty) *
                parseFloat(this.itemsArr[pos].s_rate);
            }
          });
        }
      } else {
        this.select_all = false;
        if (kot.added == true) {
          kot.added = false;
          var kotIndex = this.soIdArr.findIndex(
            (e: any) => e === kot.transaction_id
          );
          this.soIdArr.splice(kotIndex, 1);
          kot.items_details.forEach((item: any) => {
            var pos = this.itemsArr.findIndex(
              (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
            );
            if (pos !== -1) {
              if (this.itemsArr[pos].qty == item.qty) {
                this.itemsArr.splice(pos, 1);
              } else {
                this.itemsArr[pos].qty =
                  parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
                this.itemsArr[pos].amount =
                  parseFloat(this.itemsArr[pos].qty) *
                  parseFloat(this.itemsArr[pos].s_rate);
              }
            }
          });
        }
      }
    });
    this.calculateAmtWithTransTax();
  }
  onChangeKOT(kot: any, isChecked: boolean) {
    if (isChecked) {
      kot.added = true;
      this.soIdArr.push(kot.transaction_id);
      kot.items_details.forEach((item: any) => {
        var pos = this.itemsArr.findIndex(
          (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
        );
        if (pos == -1) {
          this.itemsArr.push(JSON.parse(JSON.stringify(item)));
        } else {
          this.itemsArr[pos].qty =
            parseFloat(this.itemsArr[pos].qty) + parseFloat(item.qty);

          const a =
            parseFloat(this.itemsArr[pos].qty) *
            parseFloat(this.itemsArr[pos].s_rate);
          const b =
            parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;

          this.itemsArr[pos].amount = a + b;
        }
      });
    } else {
      kot.added = false;
      var kotIndex = this.soIdArr.findIndex(
        (e: any) => e === kot.transaction_id
      );
      this.soIdArr.splice(kotIndex, 1);
      kot.items_details.forEach((item: any) => {
        var pos = this.itemsArr.findIndex(
          (e: any) => e.item_id === item.item_id && e.s_rate == item.s_rate
        );
        if (pos !== -1) {
          if (this.itemsArr[pos].qty == item.qty) {
            this.itemsArr.splice(pos, 1);
          } else {
            this.itemsArr[pos].qty =
              parseFloat(this.itemsArr[pos].qty) - parseFloat(item.qty);
            const a =
              parseFloat(this.itemsArr[pos].qty) *
              parseFloat(this.itemsArr[pos].s_rate);
            const b =
              parseFloat(this.itemsArr[pos].qty) * this.optionarray_amount;
            this.itemsArr[pos].amount = a + b;
          }
        }
      });
    }
    this.calculateAmtWithTransTax();
    this.select_all = this.checkSelectAll();
  }
  form: FormGroup = new FormGroup({
    customer_name: new FormControl("Customer"),
    email: new FormControl(null),
    address: new FormControl(null),
    trans_no: new FormControl("Auto Generated"),
    trans_date: new FormControl(new Date(), Validators.required),
    ledger_id: new FormControl(null),
    table_id: new FormControl(null),
    gstin: new FormControl(null),
    total_amount: new FormControl(0, Validators.required),
    total: new FormControl(0),
    total_tax: new FormControl(0),
    discount_amt: new FormControl(0),
    roundoff_amt: new FormControl(0),
    other1_label: new FormControl(null),
    other2_label: new FormControl(null),
    other1_amt: new FormControl(0),
    other2_amt: new FormControl(0),
    net_amount: new FormControl(0, Validators.required),
    cash: new FormControl(0, Validators.required),
    card: new FormControl(0, Validators.required),
    upi: new FormControl(0, Validators.required),
    card_ref_no: new FormControl(null),
    advance: new FormControl(0),
    balance: new FormControl(0),
    contact_no: new FormControl(null),
    taxslab: new FormControl(0),
    vattaxslab: new FormControl(0),
    dis_per: new FormControl(0),
    taxable_amt: new FormControl(0),
    sgst_per: new FormControl(0),
    sgst_amt: new FormControl(0),
    cgst_per: new FormControl(0),
    cgst_amt: new FormControl(0),
    igst_per: new FormControl(0),
    igst_amt: new FormControl(0),
    vat_per: new FormControl(0),
    vat_amt: new FormControl(0),
    gross_amount: new FormControl(0),
    item_qty: new FormControl(null),
    item_rate: new FormControl(null),
    item_amt: new FormControl(null),
    cash_receive: new FormControl(0),
    upi_receive: new FormControl(0),
    instruction: new FormControl(""),
  });

  // Initialise or Reset Form Group
  initializeFormGroup() {
    this.form.setValue({
      customer_name: "Customer",
      email: null,
      address: null,
      trans_no: "Auto Generated",
      trans_date: new Date(),
      ledger_id: null,
      table_id: null,
      gstin: null,
      total_amount: 0,
      discount_amt: 0,
      roundoff_amt: 0,
      other1_label: null,
      other2_label: null,
      other1_amt: 0,
      other2_amt: 0,
      net_amount: 0,
      total: 0,
      total_tax: 0,
      cash: 0,
      card: 0,
      upi: 0,
      card_ref_no: null,
      advance: 0,
      balance: 0,
      contact_no: null,
      dis_per: 0,
      taxable_amt: 0,
      sgst_per: 0,
      sgst_amt: 0,
      cgst_per: 0,
      cgst_amt: 0,
      igst_per: 0,
      igst_amt: 0,
      vat_per: 0,
      vat_amt: 0,
      gross_amount: 0,
      taxslab: 0,
      vattaxslab: 0,
      item_qty: null,
      item_rate: null,
      item_amt: null,
      cash_receive: 0,
      upi_receive: 0,
      instruction: null,
    });
    this.itemsArr = [];
    this.calculateAmtWithTransTax();
    this.soIdArr = [];
    this.kotArr
      .filter((x: any) => x.added === true)
      .forEach((x: any) => this.kotArr.splice(this.kotArr.indexOf(x), 1));
    this.allow_login = false;
    window.scroll(0, 0);
  }

  onOtherPrice() {
    if (this.form.value.total_amount) {
      this.calculateAmtWithTransTax();
    }
  }
  onCashOrCard() {
    var total_advance =
      this.form.value.cash + this.form.value.card + this.form.value.upi;
    var balance_amt = this.form.value.net_amount - total_advance;
    this.form.get("advance")?.setValue(total_advance);
    this.form.get("balance")?.setValue(balance_amt);
    if (this.form.value.cash_receive >= this.form.value.cash) {
      this.refund_amt = this.form.value.cash_receive - this.form.value.cash;
    }
  }
  refreshPayment() {
    if (this.form.value.cash_receive >= this.form.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
    }
    this.onCashOrCard();
  }
  onCashReceive() {
    if (this.form.value.cash_receive >= this.form.value.net_amount) {
      this.form.get("cash")?.setValue(this.form.value.net_amount);
      this.onCashOrCard();
    }
  }

  onTaxChange(e: any) {
    this.calculateAmtWithTransTax();
  }
  onFocus() {
    const filter = "restaurant_menu_items";
    this.apiService.getCustomItemList(filter).subscribe(
      (result: any) => {
        this.itemObj = result;
        this.filteredOptions = this.itemControl.valueChanges.pipe(
          startWith(""),
          map((value) => this._filter(value))
        );
      },
      (result: any) => {}
    );
  }
  onChangeItemQty(e: any, i: any) {
    this.itemsArr[i].qty = e.target.value || 1;
    this.itemsArr[i].amount = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }

  increase_qty(e: any, i: any) {
    this.KOT_data[i].qty = e.qty + 1;
    const b = this.KOT_data[i].qty * this.optionarray_amount;
    const a = this.KOT_data[i].qty * this.KOT_data[i].s_rate;

    this.KOT_data[i].amount = a + b;
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }
  decrease_qty(e: any, i: any) {
    if (e.qty >= 2) {
      this.KOT_data[i].qty = e.qty - 1;
      const b = this.KOT_data[i].qty * this.optionarray_amount;
      const a = this.KOT_data[i].qty * this.KOT_data[i].s_rate;
      this.KOT_data[i].amount = a + b;
      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    }
  }

  increase_qty1(e: any, i: any) {
    this.itemsArr[i].qty = e.qty + 1;
    const b = this.itemsArr[i].qty * this.optionarray_amount;
    const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    this.itemsArr[i].amount = a + b;
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
  }
  decrease_qty1(e: any, i: any) {
    if (e.qty >= 2) {
      this.itemsArr[i].qty = e.qty - 1;
      const b = this.itemsArr[i].qty * this.optionarray_amount;
      const a = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
      this.itemsArr[i].amount = a + b;
      this.calculateAmtWithTax();
      this.calculateAmtWithTransTax();
    }
  }

  onChangeItemRate(e: any, i: any) {
    this.itemsArr[i].s_rate = e.target.value;
    this.itemsArr[i].amount = this.itemsArr[i].qty * this.itemsArr[i].s_rate;
    this.calculateAmtWithTransTax();
  }
  waitforinvoice: boolean = false;
  waitforkot: boolean = false;
  waitforTakeaway: boolean = false;

  // onSubmit() {
  //   if (this.showValidationInput) {
  //     if (this.validationForm.valid) {
  //       this.otpVerified = false;
  //       this.otpInvalid = false;
  //       this.formSubmitted = true;
  //       this.showValidationInput = false;
  //       this.showValidationDiscount = false;
  //       this.saveInvoice();
  //     } else {
  //     }
  //   } else {
  //     this.saveInvoice();
  //   }
  // }

  private saveInvoice() {
    this.waitforinvoice = true;
    var orderMode = null;
    if (this.order_tab === "Take Away") {
      orderMode = "Take Out";
    } else {
      orderMode = "Dine In";
    }
    // Accessing the checked data
    const eBillData = this.eBillsForm.get("selectedEbillNames")?.value;

    if (this.form.valid) {
      this.invoice_no = null;
      this.form.get("trans_no")?.setValue(this.invoice_no);
      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.itemsArr;
      this.transDataObj.soIdArr = [...new Set(this.soIdArr)];
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.table_id;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = orderMode;
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = eBillData;
      // if (this.non_receivable) {
      //   this.form.value.net_amount = 0;
      // }
      this.apiService.addInvoice(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {
            this.form.value.net_amount = result.net_amount;
            this.form.value.balance_amt = result.balance_amt;
            this.form.value.total_amount = result.total_amount;
            this.form.value.balance = result.balance;
            this.form.value.taxable_amt = result.taxable_amt;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.display_trans_no = result.display_trans_no;
            this.transDataObj.trans_type = "Invoice";
            this.validationForm.reset();
            this.validationForm.get("validationCode")?.setValue("");
            this.initializeFormGroup();
            this.apiService.downloadPdf(this.transDataObj).subscribe(
              (result: any) => {
                let blob = new Blob([result], {
                  type: "application/pdf",
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download =
                  "invoice" + this.transDataObj.display_trans_no + ".pdf";
                link.click();
                window.URL.revokeObjectURL(link.href);
              },
              (result: any) => {}
            );
            // //notyf.success( "New Invoice added successfully");
            // this.getPendingInvoice()
            if (this.form.controls["advance"].value) {
              this.initializeFormGroup();
              this.load();
              this.data = undefined;
            }
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")?.setValue("");
          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")?.setValue("");
          }
        },
        (result: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.validationForm.reset();
          this.validationForm.get("validationCode")!.setValue("");
        }
      );
    } else {
      window.scroll(0, 0);
      this.waitforinvoice = false;
      this.non_receivable = false;
      this.showValidationInput = false;
      this.showValidationDiscount = false;
      this.validationForm.reset();
      this.formSubmitted = false;
      this.validationForm.get("validationCode")!.setValue("");
    }
    if (this.order_tab === "Dine-In") {
      this.panel4.open();
      this.panel3.close();
    }

    // After the invoice is generated, refresh only this user's component
    this.refreshComponent();
  }

  checkSelectAll() {
    var found = true;
    this.kotArr.forEach((kot: any) => {
      if (kot.added == false) {
        found = false;
      }
    });
    return found;
  }

  downloadClick(dataObj: any, transNo: any) {
    this.apiService.downloadPdf(dataObj).subscribe(
      (result: any) => {
        let blob = new Blob([result], {
          type: "application/pdf",
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "kot" + transNo + ".pdf";
        link.click();
        window.URL.revokeObjectURL(link.href);
        this._generating_sales_order = false;

        this.onKotAdd.emit(true);
        this.SaveAndPrint();
      },
      (result: any) => {}
    );
  }

  getTransNo() {
    return this.model_kot.trans_no ? this.model_kot.trans_no : " Auto";
  }

  kot_Dta: any = [];

  onEditKot(kot_id: any) {
    var dataObj = {
      isAutoFocus: this.isAutoFocus,
      taxslab: this.form.value.taxslab,
      table_id: this.table_id,
      table_name: this.table_name,
      allowChangeRate: this.allowChangeRate,
      kot_id: kot_id,
    };

    this.dialog
      .open(RstKotComponent, {
        width: "900px",
        data: dataObj,
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result == "Edited") {
          this.reset();
        }
      });
  }
  reset() {
    this.itemsArr = [];
    this.soIdArr = [];
    this.kotArr = [];
    this.load();
  }
  convertFloat(val: any) {
    return parseFloat(val);
  }
  onAllowLogin(event: MatSlideToggleChange) {
    this.allow_login = event.checked;
    this.apiService
      .updateSessionAllowLogin(this.allow_login, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("data updated successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => {}
      );
  }

  // showitems(data: any) {
  //   this.items_data = this.itemArr.filter((e: any) => {
  //     return e.category_id === data
  //   })
  // }
  showitems(data: any) {
    this.catArr.forEach((e: any) => {
      if (e.category_id === data) {
        this.selectedCategoryName = e.category_name;
      }
    });
    this.sub_category_data = this.subCatArr.filter((e: any) => {
      this.items_data = [];
      return e.cat_id === data;
    });
    if (!this.sub_category_data.length) {
      this.noSubCategoryItems(data);
    }
  }
  showSubitems(data: any) {
    this.items_data = this.itemArr.filter((e: any) => {
      return e.sub_category_id === data;
    });
  }
  noSubCategoryItems(data: any) {
    this.items_data = this.itemArr.filter((e: any) => {
      return e.category_id === data;
    });
  }

  instructiondataTakeaway(item: any, n: any) {
    this.itemArr = this.itemArr.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  addItems(item: any) {
    // this.optionarray_amount = item.checkedOprionAmount
    // this.options_array = item.checkedOprionArr

    item.s_rate =
      item.checkedVariantAmount && item.checkedVariantAmount > 0
        ? item.checkedVariantAmount
        : item.s_rate;
    // if (item.options.length === 0) {
    if (this.order_tab === "Take Away") {
      // Make sure KOT List is initialized
      if (!this.itemsArr) this.itemsArr = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.itemsArr.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;
      if (result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = item.qty;
        // found_item.amount = +item.s_rate;
        const a = found_item.qty * parseInt(item.s_rate);
        const b = found_item.qty * this.optionarray_amount;
        found_item.amount = a + b;
        // this.itemsArr.push(item);
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        // found_item.qty++;
        found_item.qty = item.qty;
        const a = found_item.qty * parseInt(item.s_rate);
        const b = found_item.qty * this.optionarray_amount;
        found_item.amount = a + b;
        // found_item.amount = found_item.qty * parseInt(item.s_rate);
      }
      if (this.isTransactionGSTslab === false) {
        if (item.tax_mode === "GST") {
          if (this.isLocal) {
            found_item.sgst_per = item.taxslab / 2;
            found_item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
            found_item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
            found_item.cgst_per = item.taxslab / 2;
            found_item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
            found_item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
          } else {
            found_item.igst_per = item.taxslab;
            found_item.igst_amt = item.amount * (item.igst_per / 100) || 0;
            found_item.igst_amt = Math.round(item.igst_amt * 100) / 100;
          }
          found_item.total_tax =
            found_item.sgst_amt ||
            0 + found_item.cgst_amt ||
            0 + found_item.igst_amt ||
            0;
        } else {
          found_item.vat_per = item.taxslab;
          found_item.vat_amt = item.amount * (item.vat_per / 100) || 0;
          found_item.vat_amt = Math.round(item.vat_amt * 100) / 100;
          found_item.total_tax = found_item.vat_amt || 0;
        }
        found_item.total = item.amount + found_item.total_tax;
        found_item.instruction = this.form.value.instruction;
      }

      if (new_item_Added) this.itemsArr.push(found_item);
    } else {
      // Make sure KOT List is initialized
      if (!this.KOT_data) this.KOT_data = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.KOT_data.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;

      if (result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = item.qty;
        // found_item.amount = +item.s_rate;
        const a = found_item.qty * parseInt(item.s_rate);
        const b = found_item.qty * this.optionarray_amount;
        found_item.amount = a + b;
        // found_item.amount = item.qty * parseInt(item.s_rate);
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        found_item.qty = item.qty;
        const a = found_item.qty * parseInt(item.s_rate);
        const b = found_item.qty * this.optionarray_amount;
        found_item.amount = a + b;
        // found_item.amount = found_item.qty * parseInt(item.s_rate);
      }

      if (this.isTransactionGSTslab === false) {
        if (item.tax_mode === "GST") {
          if (this.isLocal) {
            found_item.sgst_per = item.taxslab / 2;
            found_item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
            found_item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
            found_item.cgst_per = item.taxslab / 2;
            found_item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
            found_item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
          } else {
            found_item.igst_per = item.taxslab;
            found_item.igst_amt = item.amount * (item.igst_per / 100) || 0;
            found_item.igst_amt = Math.round(item.igst_amt * 100) / 100;
          }
          found_item.total_tax =
            found_item.sgst_amt ||
            0 + found_item.cgst_amt ||
            0 + found_item.igst_amt ||
            0;
        } else {
          found_item.vat_per = item.taxslab;
          found_item.vat_amt = item.amount * (item.vat_per / 100) || 0;
          found_item.vat_amt = Math.round(item.vat_amt * 100) / 100;
          found_item.total_tax = found_item.vat_amt || 0;
        }
        found_item.total = item.amount + found_item.total_tax;
        found_item.instruction = this.form.value.instruction;
      }
      if (new_item_Added) this.KOT_data.push(found_item);

      if (!this.panel.expanded) {
        this.panel.open();
      }
    }
    // Refersh total amounts
    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
    // }
  }
  instrucctiondata(item: any, n: any) {
    this.KOT_data = this.KOT_data.filter((data: any) => {
      if (item.item_id == data.item_id) {
        data.instruction = n.value;
      }
      return data;
    });
  }

  // --------------------------------Take Away---------------------------------
  onChange(event: any) {
    this.order_tab = event.tab.textLabel;
    this.KOT_data = [];
    if (this.order_tab === "Take Away") {
      notyf.success("Switched to Take Away");
    } else {
      notyf.success("Switched to Dine-In");
    }
  }

  addItemCardClicked(i: any) {
    const data = { item: i, status: "add" };
    const index = this.KOT_data.findIndex(
      (itm: any) => itm.item_id === i.item_id
    );
    if (index !== -1) {
      this.addItemsone(i);
    } else {
      i.options.length > 0 || i.variant_groups.length > 0
        ? this.openCustomization(data)
        : this.addItemsone(i);
    }
  }
  openCustomization(e: any) {
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const item = result.item;
        if (result.status === "add") {
          this.optionarray_amount = item.optionAmount;
          this.options_array = item.optionsData;
          this.checkedVariantAmount = item.checkedVariantAmount;
          this.checkedVariant = item.checkedVariant;

          // Check if the item is already in the KOT list and update quantity
          const existingItemIndex =
            this.order_tab === "Take Away"
              ? this.itemsArr.findIndex(
                (itm: any) => itm.item_id === item.item_id
              )
              : this.KOT_data.findIndex(
                (itm: any) => itm.item_id === item.item_id
              );

          if (existingItemIndex !== -1) {
            // If the item exists, increase its quantity
            if (this.order_tab === "Take Away") {
              this.itemsArr[existingItemIndex].qty += item.qty || 1;
              this.itemsArr[existingItemIndex].amount =
                this.itemsArr[existingItemIndex].qty *
                this.itemsArr[existingItemIndex].s_rate;
            } else {
              this.KOT_data[existingItemIndex].qty += item.qty || 1;
              this.KOT_data[existingItemIndex].amount =
                this.KOT_data[existingItemIndex].qty *
                this.KOT_data[existingItemIndex].s_rate;
            }
          } else {
            // If the item doesn't exist, add it to the respective list
            this.addItems(item);
          }
        } else {
          this.updateOrder(item);
        }

        // Clear the search input and refocus after adding the item
        this.initializeItem();
        this.focusSettingSearch();
      }
    });
  }

  initializeItem() {
    this.itemControl.setValue(""); // Clear the search input
    this.itemObj = []; // Clear the filtered options
    this.filteredOptions = this.itemControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }

  updateOrder(item: any) {
    this.optionarray_amount = item.optionAmount;
    this.options_array = item.optionsData;
    this.checkedVariantAmount = item.checkedVariantAmount;
    this.checkedVariant = item.checkedVariant;
    item.amount =
      item.checkedVariantAmount > 0
        ? (item.checkedVariantAmount + item.optionAmount) *
        (item.qty > 0 ? item.qty : 1)
        : item.optionAmount > 0
          ? item.optionAmount + item.s_rate * item.qty
          : item.qty > 0
            ? item.s_rate * item.qty
            : item.s_rate;
    if (this.isTransactionGSTslab === false) {
      if (item.tax_mode === "GST") {
        if (this.isLocal) {
          item.sgst_per = item.taxslab / 2;
          item.sgst_amt = item.amount * (item.sgst_per / 100) || 0;
          item.sgst_amt = Math.round(item.sgst_amt * 100) / 100;
          item.cgst_per = item.taxslab / 2;
          item.cgst_amt = item.amount * (item.cgst_per / 100) || 0;
          item.cgst_amt = Math.round(item.cgst_amt * 100) / 100;
        } else {
          item.igst_per = item.taxslab;
          item.igst_amt = item.amount * (item.igst_per / 100) || 0;
          item.igst_amt = Math.round(item.igst_amt * 100) / 100;
        }

        item.total_tax =
          item.sgst_amt || 0 + item.cgst_amt || 0 + item.igst_amt || 0;
      } else {
        item.vat_per = item.taxslab;
        item.vat_amt = item.amount * (item.igst_per / 100) || 0;
        item.vat_amt = Math.round(item.igst_amt * 100) / 100;
        item.total_tax = item.vat_amt || 0;
      }
      item.total = item.amount + item.total_tax;
      item.instruction = this.form.value.instruction;
    }
    this.updateOrderItem(item);
  }
  updateOrderItem(item: any) {
    if (this.order_tab === "Take Away") {
      const index = this.itemsArr.findIndex(
        (itm: any) => itm.item_id === item.item_id
      );

      if (index !== -1) {
        this.itemsArr[index] = item;
      }
      this.calculateAmtWithTransTax();
    } else {
      const index = this.KOT_data.findIndex(
        (itm: any) => itm.item_id === item.item_id
      );
      if (index !== -1) {
        this.KOT_data[index] = item;
      }
    }
  }

  openCustomizationsearch(e: any, b: any) {
    this.optionarray_amount = 0;
    this.checkedVariantAmount = 0;
    this.options_array = [];
    this.checkedVariant = [];
    const dialogRef: MatDialogRef<ItemsDialogComponent> = this.dialog.open(
      ItemsDialogComponent,
      {
        data: e,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.optionarray_amount = result.optionAmount;
        this.options_array = result.optionsData;
        this.checkedVariant = result.checkedVariant;
        this.checkedVariantAmount = result.checkedVariantAmount;
        const isCustomised = true;
        this.populateItemData(result, isCustomised);
      }
    });
  }

  // --------------------------clearTable-------------------------------
  clearTable() {
    this.webappActiveTable = false;
    this.apiService
      .updateActiveTable_inactive(this.webappActiveTable, this.table_id)
      .subscribe(
        (result: any) => {
          if (result.success) {
            notyf.success("Table Clear successfully");
          } else {
            notyf.error(result.message);
          }
        },
        (result: any) => {}
      );
  }

  // backToTable() {
  //   this.router.navigate(["/rst-dashboard"]);
  // }

  // -------------------------receiptDialog--------------------

  receiptDialog(e: any) {
    const dialogRef: MatDialogRef<ReceiptDialogComponent> = this.dialog.open(
      ReceiptDialogComponent,
      {
        data: e.display_trans_no,
        width: "600px",
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.load();
      }
    });

    // After the receipt is generated, refresh only this user's component
    this.refreshComponent();
  }

  // ---------------------transfer table -------------------

  openTransferDialog(): void {
    const dialogRef: MatDialogRef<TabletransferDialogComponent> =
      this.dialog.open(TabletransferDialogComponent, {
        width: "300px",
        data: this.table_id,
      });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "true") {
        this.initializeFormGroup();
        this.load();
      }
    });
  }
  // --------------------------clearwaterand waiter-------------------------------
  clearwaterwaiter() {
    this.apiService.updatewaterwaiter(this.table_id).subscribe(
      (result: any) => {
        if (result) {
          notyf.success("Water/waiter Clear successfully");
          this.load();
        } else {
          notyf.error(result.message);
        }
      },
      (result: any) => {}
    );
  }

  onEnterKey(event: any) {
    event.preventDefault();
    if (event.target === this.sitm.nativeElement) {
      this.filteredOptions.subscribe((options) => {
        if (options.length === 1) {
          this.itemControl.setValue(options[0]);
          this.onAddItem();
        }
      });
    }

    if (event.key === "Enter") {
      this.onAddItem();
      this.onFocus(); // Assuming this is the method to add the item to the KOT list
    }
  }
  // ======================disposal
  showdisposalitems(data: any) {
    this.disposalItemlist = this.disposalItemData.filter((e: any) => {
      return e.category_id === data;
    });
  }

  verifyEmailOtp(validationCode: string) {
    const requestBody = { code: validationCode };
    this.apiService.verifyemailotp(requestBody).subscribe(
      (result: any) => {
        if (result && result.success) {
          this.otpVerified = true;
          this.otpInvalid = false;
        }
        if (result && !result.success) {
          this.otpInvalid = true;
          this.otpVerified = false;
        }
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
  // onDiscountFocus(e){
  //   if (e:any) {
  //     this.isdiscountFocused = true;
  //   } else {
  //     this.isdiscountFocused = false;
  //     const discountControl = this.form.get('dis_per');
  //     if (discountControl.value === 0) {
  //       discountControl.setValue(null);
  //     }
  //   }
  // }
  // onDiscountPer(event:any) {
  //   var discount = 0;
  //   discount = this.form.value.total_amount * (this.form.value.dis_per / 100);
  //   discount = Math.round(discount * 100) / 100;
  //   this.form.get('discount_amt').setValue(discount);
  //   this.calculateAmtWithTransTax();
  // }
  // onDiscountAmt(event:any) {
  //   this.form.get('dis_per').setValue(0);
  //   this.calculateAmtWithTransTax();
  // }

  onDiscountFocus(isFocused: boolean) {
    this.isdiscountFocused = isFocused;
    if (!isFocused) {
      const discountControl: any = this.form.get("dis_per");
      if (discountControl.value === 0) {
        discountControl.setValue(null);
      }
    }
  }

  onDiscountPer(event: any) {
    var discount = 0;
    discount = this.form.value.total_amount * (this.form.value.dis_per / 100);
    discount = Math.round(discount * 100) / 100;
    this.form.get("discount_amt")!.setValue(discount);
    this.calculateAmtWithTransTax();
  }

  onDiscountAmt(event: any) {
    this.form.get("dis_per")!.setValue(0);
    this.calculateAmtWithTransTax();
  }

  onQtyFocus(e: any) {
    if (e) {
      this.isQtyFocused = true;
    } else {
      this.isQtyFocused = false;
      // Ensure the input value is not 0
      const itemQtyControl: any = this.form.get("item_qty");
      if (itemQtyControl.value === 0) {
        itemQtyControl.setValue(null);
      }
    }
  }

  calculateItemAmt(event: any) {
    const sitmInputValue = this.itemControl.value;

    if (!sitmInputValue) {
      this.form.get("item_qty")!.setValue(0);
      return;
    }
    const qty = this.form.value.item_qty;
    const s_rate = this.form.value.item_rate;
    let positiveQty = this.form.value.item_qty || 0;
    if (positiveQty < 1) {
      positiveQty = 1;
    }
    const positiveRate = Math.max(s_rate || 0, 0);

    const a = positiveQty * positiveRate;
    const b = positiveQty * this.optionarray_amount;
    const amount = a + b;

    this.form.get("item_amt")!.setValue(amount);
    this.model.qty = positiveQty;
    this.model.s_rate = positiveRate;
    this.model.amount = amount;

    if (event.key === "Enter" && !this.isQtyFocused) {
      this.onAddItem();
      this.sitm.nativeElement.focus();
    }

    if (event.key === "Enter" && this.isQtyFocused) {
      // this.s_rate1.nativeElement.focus();
    }

    // const qty = this.form.get('item_qty')?.value || 1;
    const rate = this.form.get("item_rate")?.value || 0;
    // const amount = qty * rate;
    this.form.get("item_amt")?.setValue(amount);
  }

  addItemsone(item: any) {
    // if (item.options.length === 0) {
    if (this.order_tab === "Take Away") {
      // Make sure KOT List is initialized
      if (!this.itemsArr) this.itemsArr = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.itemsArr.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;
      if (result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = 1;
        found_item.amount = +item.s_rate;
        // this.itemsArr.push(item);
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        found_item.qty++;
        found_item.amount = found_item.qty * parseInt(item.s_rate);
      }

      if (new_item_Added) this.itemsArr.push(found_item);
    } else {
      // Make sure KOT List is initialized
      if (!this.KOT_data) this.KOT_data = [];

      // Check if item.item_id exist in KOT list
      var result_items = this.KOT_data.filter((search_item: any) => {
        return search_item.item_id === item.item_id;
      });
      var found_item = null;
      var new_item_Added = false;

      if (result_items.length > 0) found_item = result_items[0];

      // If item not exist in KOT then add new item
      if (!found_item) {
        found_item = item;
        new_item_Added = true;

        found_item.qty = 1;
        found_item.amount = +item.s_rate;
        found_item.instruction = "";
      } else {
        new_item_Added = false;
        // If Item already exist in KOT then increase qty and calcluate new amount
        found_item.qty++;
        found_item.amount = found_item.qty * parseInt(item.s_rate);
      }

      if (new_item_Added) this.KOT_data.push(found_item);

      if (!this.panel.expanded) {
        this.panel.open();
      }
    }

    // Refersh total amounts

    this.calculateAmtWithTax();
    this.calculateAmtWithTransTax();
    // }
  }

  updateCustomisation(itm: any) {
    let updateItem: any;
    if (this.KOT_data.length > 0) {
      updateItem = this.KOT_data.filter((e: any) => e.item_id == itm.item_id);
    } else {
      updateItem = this.itemsArr.filter((e: any) => e.item_id == itm.item_id);
    }
    const data = { item: updateItem[0], status: "update" };
    this.openCustomization(data);
  }

  calculateAmtWithTax() {
    if (this.isTransactionGSTslab === false) {
      for (var i = 0; i < this.KOT_data.length; i++) {
        if (this.KOT_data[i].tax_mode === "GST") {
          if (this.isLocal) {
            this.KOT_data[i].sgst_per = this.KOT_data[i].taxslab / 2;
            this.KOT_data[i].sgst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].sgst_per / 100) || 0;
            this.KOT_data[i].sgst_amt =
              Math.round(this.KOT_data[i].sgst_amt * 100) / 100;
            this.KOT_data[i].cgst_per = this.KOT_data[i].taxslab / 2;
            this.KOT_data[i].cgst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].cgst_per / 100) || 0;
            this.KOT_data[i].cgst_amt =
              Math.round(this.KOT_data[i].cgst_amt * 100) / 100;
          } else {
            this.KOT_data[i].igst_per = this.KOT_data[i].taxslab;
            this.KOT_data[i].igst_amt =
              this.KOT_data[i].amount * (this.KOT_data[i].igst_per / 100) || 0;
            this.KOT_data[i].igst_amt =
              Math.round(this.KOT_data[i].igst_amt * 100) / 100;
          }

          // this.KOT_data[i].sgst_amt =
          //   (this.KOT_data[i].sgst_amt || 0) *
          //   (this.KOT_data[i].qty ? this.KOT_data[i].qty : 1);
          // this.KOT_data[i].cgst_amt =
          //   (this.KOT_data[i].cgst_amt || 0) *
          //   (this.KOT_data[i].qty ? this.KOT_data[i].qty : 1);
          // this.KOT_data[i].igst_amt =
          //   (this.KOT_data[i].igst_amt || 0) *
          //   (this.KOT_data[i].qty ? this.KOT_data[i].qty : 1);
          // this.KOT_data[i].vat_amt = (this.KOT_data[i].vat_amt || 0) *
          //   (this.KOT_data[i].qty ? this.KOT_data[i].qty : 1);

          this.KOT_data[i].total_tax =
            this.KOT_data[i].sgst_amt ||
            0 + this.KOT_data[i].cgst_amt ||
            0 + this.KOT_data[i].igst_amt ||
            0;
        } else {
          this.KOT_data[i].vat_per = this.KOT_data[i].taxslab;
          this.KOT_data[i].vat_amt =
            this.KOT_data[i].amount * (this.KOT_data[i].vat_per / 100) || 0;
          this.KOT_data[i].vat_amt =
            Math.round(this.KOT_data[i].vat_amt * 100) / 100;
          this.KOT_data[i].total_tax = this.KOT_data[i].vat_amt || 0;
        }
        this.KOT_data[i].total_tax =
          this.KOT_data[i].sgst_amt +
          this.KOT_data[i].cgst_amt +
          this.KOT_data[i].igst_amt +
          this.KOT_data[i].vat_amt;

        this.KOT_data[i].taxable_amt =
          this.amount - this.form.value.discount_amt;

        this.KOT_data[i].net_amount =
          this.KOT_data[i].taxable_amt + this.KOT_data[i].total_tax;
      }
    }
  }

  getEbillingdata() {
    // this.apiService.getEBillAcountMapData().subscribe(
    //   (result) => {
    //     this.ebillData = result;
    //     // Loop through ebillData to set initial selected options based on checkbox_value
    //     this.ebillData.forEach((item:any) => {
    //       if (item.checkbox_value) {
    //         const control = this.eBillsForm.get(
    //           "selectedEbillNames"
    //         ) as FormControl;
    //         const selectedValues = control.value.concat(item);
    //         control.setValue(selectedValues);
    //       }
    //     });
    //   },
    //   (err: any) => {
    //     notyf.error("unable to load e-bill data");
    //   }
    // );
  }

  onOnlyAddKOT() {
    // if(this.data){
    // this.initializeFormGroup();
    //     this.data=undefined
    // }
    // if (this.data === undefined) {
    if (this.order_tab === "Take Away") {
      if (this.form.value.total_amount !== 0) {
        this.waitforTakeaway = true;
        this.model_kot.net_amount = 0;
        this.itemsArr.forEach((itm: any) => {
          this.model_kot.net_amount = this.model_kot.net_amount + itm.amount;
        });
        if (this.itemsArr) {
          this._generating_sales_order = true;
          this.model_kot.items_details = this.itemsArr;
          this.model_kot.table_id = this.table_id;
          this.model_kot.ledger_id = 0;
          this.model_kot.orderMode = "Take Away";

          this.model_kot.instruction = this.form.get("instruction")!.value
            ? this.form.get("instruction")!.value
            : null;
          this.notesValueForEdit = this.form.get("instruction")!.value
            ? this.form.get("instruction")!.value
            : null;
          this.apiService.addSalesOrder(this.model_kot).subscribe(
            (result: any) => {
              if (result) {
                this.soIdArr.push(result.transaction_id);
                this.model_kot.trans_no = result.max_trans_value;
                this.model_kot.transaction_id = result.transaction_id;
                this.model_kot.trans_type = "SalesOrder";
                var tr_no = this.model_kot.trans_no;
                // this.downloadClick(this.model_kot, tr_no);
                this.model_kot = { trans_date: new Date(), net_amount: 0 };
                this.kot_Dta.push(this.itemsArr);
                notyf.success("New KOT added successfully");
                if (this.order_tab === "Dine-In") {
                  this.panel3.open();
                }
                this.waitforTakeaway = false;
                // this.form.get('notes').reset();
                this.form.get("instruction")!.reset();
              } else {
                notyf.error(result.message);
                this.waitforTakeaway = false;
              }
            },
            (result: any) => {
              this._generating_sales_order = false;
              notyf.error("Unable to add new KOT");
              this.waitforTakeaway = false;
            }
          );
        } else {
          notyf.error("Add atleast one item to continue");
          this._generating_sales_order = false;
          this.waitforkot = false;
        }
      } else {
        notyf.error("Please add items");
        this.waitforkot = false;
      }
    } else {
      this.waitforkot = true;

      this.model_kot.net_amount = 0;
      this.KOT_data.forEach((itm: any) => {
        this.model_kot.net_amount =
          this.model_kot.net_amount +
          itm.amount +
          (itm.sgst_amt || 0) +
          (itm.cgst_amt || 0) +
          (itm.igst_amt || 0) +
          (itm.vat_amt || 0);
      });
      if (this.KOT_data.length !== 0) {
        this.model_kot.items_details = this.KOT_data;
        this.model_kot.table_id = this.table_id;
        this.model_kot.orderMode = "Dine-In";
        this.model_kot.ledger_id = 0;
        this.model_kot.instruction = this.form.get("instruction")!.value
          ? this.form.get("instruction")!.value
          : null;
        // this.model_kot.notes = this.form.get('notes').value;
        // this.notesValueForEdit = this.form.get('notes').value;

        this.apiService.addSalesOrder(this.model_kot).subscribe(
          (result: any) => {
            if (result) {
              this.model_kot.trans_no = result.max_trans_value;
              this.model_kot.transaction_id = result.transaction_id;
              this.model_kot.trans_type = "SalesOrder";
              var tr_no = this.model_kot.trans_no;
              // this.downloadClick(this.model_kot, tr_no);
              // this.itemsArr.push(this.KOT_data);
              this.model_kot = { trans_date: new Date(), net_amount: 0 };
              this.KOT_data = [];
              this.onKotAdd.emit(true);
              notyf.success("New KOT added successfully");
              if (this.order_tab === "Dine-In") {
                this.panel3.open();
              }
              this.waitforkot = false;
              // this.form.get('notes').reset();
              this.form.get("instruction")!.reset();
            } else {
              notyf.error(result.message);
              this.waitforkot = false;
            }
          },
          (result: any) => {
            notyf.error("Unable to add new KOT");
            this.waitforkot = false;
          }
        );
      } else {
        notyf.error("Add atleast one item to continue");
        this.waitforkot = false;
      }
    }

    // } else {
    //   this.notifier.notify('error', 'Please clear receipt');
    // }
  }

  saveContact(): void {
    if (this.form.valid) {
      const contactData = {
        name: this.form.value.customer_name,
        email_id: this.form.value.email,
        contact_no: this.form.value.contact_no,
        gstin: this.form.value.gstin,
        shipping_address1: this.form.value.address,
      };

      if (this.isEditMode && this.editContactId) {
        this.apiService
          .editContact({ ...contactData, id: this.editContactId })
          .subscribe(
            (response: any) => {
              if (response.success) {
                this.resetForm();
              }
            },
            (error: any) => {
              console.error("Error editing contact", error);
            }
          );
      } else {
        this.apiService.addContact(contactData).subscribe(
          (response: any) => {
            if (response.success) {
              this.resetForm();
            }
          },
          (error: any) => {
            console.error("Error adding contact", error);
          }
        );
      }
    }
  }

  resetForm(): void {
    // this.customerForm.patchValue({
    //   customer_name: "",
    // });

    this.form.patchValue({
      customer_name: "",
      email: "",
      gstin: "",
      address: "",
      // Reset other controls as needed
    });

    this.isEditMode = false;
    this.editContactId = null;
  }

  // onInput(event: any): void {
  //   event.preventDefault();
  //   const inputElement = event.target as HTMLInputElement;
  //   const value = inputElement.value;
  //   this.searchByPhone(value);
  // }

  // handleInputChange(value: string): void {
  //   if (value.trim() === "") {
  //     this.listNumber = []; // Clear listNumber if input value is empty
  //   } else {
  //     this.apiService.searchNumber(value).subscribe(
  //       (response: any) => {
  //         this.listNumber = [];

  //         response.forEach((e: any) => {
  //           this.listNumber.push(e); // Store response in array
  //         });
  //         // Additional logic to process the response as needed
  //       },
  //       (error: any) => {
  //         console.error("API error:", error);
  //       }
  //     );
  //   }
  // }

  onInput(event: any): void {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove non-numeric characters
    value = value.replace(/[^0-9]/g, "");

    // Limit the length to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10);

      this.errorMessage = "Number should not exceed 10 digits.";
    } else {
      this.errorMessage = null;
    }

    this.inputValue = value;
    inputElement.value = value; // Update the input field value

    this.searchByPhone(value);
  }

  handleInputChange(value: string | null | undefined): void {
    if (!value || value.trim() === "") {
      this.listNumber = []; // Clear listNumber if input value is empty or null/undefined
    } else {
      this.apiService.searchNumber(value.trim()).subscribe(
        (response: any[]) => {
          this.listNumber = response || []; // Directly assign the response if it's an array, otherwise, clear the list
        },
        (error: any) => {
          console.error("API error:", error);
          this.listNumber = []; // Optionally, clear the list on error
        }
      );
    }
  }

  setInputValue(value: string): void {
    this.inputValue = value;
  }
  handleEnterKey(event: any): void {
    if (event.key === "Enter") {
      this.filteredOptions.subscribe((options) => {
        if (options.length === 1) {
          this.itemControl.setValue(options[0]);
          this.addItemToOrderList(options[0]);
        }
      });
    }
  }

  searchByPhone(phone: string): void {
    this.inputValue = phone;
    this.apiService.fetchContactByPhone(phone).subscribe(
      (response: any) => {

        // if (response.success && response.contact) {
        //   this.customerForm.patchValue({
        //     ...this.customerForm.value,
        //     customer_name: response.contact.name,
        //   });
        if (response.success && response.contact) {
          this.form.patchValue({
            ...this.form.value,
            customer_name: response.contact.name,
            email: response.contact.email_id,
            address: response.contact.shipping_address1,

          });
          // }
          this.isEditMode = true;
          this.editContactId = response.contact.id;
        } else {
          this.resetForm();
          this.isEditMode = false;
          this.editContactId = null;
        }
      },
      (error: any) => {
        console.error("Error searching contact by phone", error);
      }
    );
  }

  private saveInvoiceData(action: string) {
    // Ensure the customer name is set to a default value if not provided
    if (
      !this.form.value.customer_name ||
      this.form.value.customer_name.trim() === ""
    ) {
      this.form.get("customer_name")?.setValue("Customer");
    }

    // Common logic to save invoice data
    this.saveContact();
    if (this.showValidationInput) {
      if (this.validationForm.valid) {
        this.otpVerified = false;
        this.otpInvalid = false;
        this.formSubmitted = true;
        this.showValidationInput = false;
        this.showValidationDiscount = false;
        this.Savedata(action);
      } else {
        console.log("Validation code is not valid");
      }
    } else {
      this.Savedata(action);
    }
  }

  private Savedata(action: string) {
    this.waitforinvoice = true;
    let orderMode = this.order_tab === "Take Away" ? "Take Out" : "Dine In";

    // Conditionally modify eBillData
    let eBillData;
    if (action === "print") {
      // For printing action
      eBillData = this.ebillData.map((item: any) => ({
        ...item,
        checkbox_value: false,
      }));
    } else {
      // For eBilling action (including default case for 'submit' action)
      eBillData = this.ebillData;
    }

    if (this.form.valid) {
      this.invoice_no = null;
      this.form.get("trans_no")!.setValue(this.invoice_no);

      this.transDataObj = this.form.value;
      this.transDataObj.items_details = this.itemsArr;
      this.transDataObj.soIdArr = this.soIdArr;
      this.transDataObj.ledger_id = 0;
      this.transDataObj.table_id = this.table_id;
      this.transDataObj.sale_type = "cash";
      this.transDataObj.orderMode = orderMode;
      this.transDataObj.non_Receivable = this.non_receivable;
      this.transDataObj.eBillData = eBillData;
      this.transDataObj.shipping_address1 = this.form.value.address;

      // if (this.non_receivable) {
      //   this.form.value.net_amount = 0;
      // }
      this.apiService.addInvoice(this.transDataObj).subscribe(
        (result: any) => {
          if (result.success) {
            this.form.value.net_amount = result.net_amount;
            this.form.value.balance_amt = result.balance_amt;
            this.form.value.total_amount = result.total_amount;
            this.form.value.balance = result.balance;
            this.form.value.taxable_amt = result.taxable_amt;
            this.transDataObj.transaction_id = result.transaction_id;
            this.transDataObj.display_trans_no = result.display_trans_no;
            this.transDataObj.trans_type = "Invoice";
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
            this.initializeFormGroup();
            if (action === "ebill" || action === "print") {
              this.apiService.downloadPdf(this.transDataObj).subscribe(
                (result: any) => {
                  let blob = new Blob([result], {
                    type: "application/pdf",
                  });
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  link.download =
                    "invoice" + this.transDataObj.display_trans_no + ".pdf";
                  link.click();
                  window.URL.revokeObjectURL(link.href);
                },
                (result: any) => { }
              );
            }
            notyf.success("New Invoice added successfully");
            // this.getPendingInvoice()
            if (this.form.controls["advance"].value) {
              this.initializeFormGroup();
              this.load();
              this.data = undefined;
            }
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
          } else {
            window.scroll(0, 0);
            notyf.error(result.message);
            this.waitforinvoice = false;
            this.non_receivable = false;
            this.showValidationInput = false;
            this.showValidationDiscount = false;
            this.formSubmitted = false;
            this.validationForm.reset();
            this.validationForm.get("validationCode")!.setValue("");
          }
        },
        (result: any) => {
          this.initializeFormGroup();
          notyf.error("unable to add invoice");
          this.waitforinvoice = false;
          this.non_receivable = false;
          this.showValidationInput = false;
          this.showValidationDiscount = false;
          this.formSubmitted = false;
          this.validationForm.reset();
          this.validationForm.get("validationCode")!.setValue("");
        }
      );
    } else {
      window.scroll(0, 0);
      this.waitforinvoice = false;
      this.non_receivable = false;
      this.showValidationInput = false;
      this.showValidationDiscount = false;
      this.validationForm.reset();
      this.formSubmitted = false;
      this.validationForm.get("validationCode")!.setValue("");
    }
    if (this.order_tab === "Dine-In") {
      this.panel4.open();
      this.panel3.close();
    }
  }

  // Wrapper functions for different actions
  onSubmit() {
    this.saveInvoiceData("submit");
  }

  SaveAndPrint() {
    this.saveInvoiceData("print");
  }

  SaveAndEbill() {
    this.saveInvoiceData("ebill");
  }

  focusState: boolean = false; // Track the focus state

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.key.toLowerCase();

    // Handle function keys F1 to F12
    const functionKeyMap: { [key: string]: () => void } = {
      f1: () => this.toggleFocusOnSitm(), // Toggle focus on F1
      f2: () => this.onAddKOT(), // Create both KOT and Invoice           // Save and Print
      f3: () => this.openReceiptPopup(), // Open receipt popup
      f5: () => this.focusContactNo(), // Focus on Contact No.
      f6: () => this.changeTable(), // Change Table
    };

    // Normalize function keys for different systems
    if (event.key.startsWith("F") && functionKeyMap[event.key.toLowerCase()]) {
      event.preventDefault();
      functionKeyMap[event.key.toLowerCase()]();
    }

    // Handle macOS-specific key combinations with function keys
    if (event.metaKey || event.ctrlKey) {
      switch (key) {
        case "f1":
        case "f2":
        case "f3":
        case "f4":
        case "f5":
          event.preventDefault();
          functionKeyMap[key]();
          break;
        default:
          break;
      }
    }
  }

  // createKotAndInvoice() {
  //   if (!this.itemsArr.length && !this.KOT_data.length) {
  //     notyf.error("Please add items to create KOT and Invoice.");
  //     return;
  //   }

  //   this.onAddKOT()   // Create KOT first
  //     .then(() => {
  //       this.SaveAndPrint();  // Then create Invoice
  //     })
  //     .catch((error) => {
  //       notyf.error("Failed to create KOT. Invoice creation aborted.");
  //       console.error("Error creating KOT and Invoice:", error);
  //     });
  // }

  onAddKOT(): Promise<void> {
    return new Promise((resolve, reject) => {
      const email = this.form.value.email;
      const contact_no = this.form.value.contact_no;
      const customer_name = this.form.value.customer_name || "Customer";

      // Contact number validation
      if ( contact_no && contact_no.length !== 10) {
        notyf.error("Contact number must be exactly 10 digits");
        return reject(new Error("Invalid contact number length"));
      }

      // Customer name validation
      if (!customer_name) {
        notyf.error("Customer name is required");
        return reject(new Error("Customer name is required"));
      }

      // Ensure there's a total amount
      if (this.form.value.total_amount === 0) {
        notyf.error("Please add items");
        this.waitforkot = false;
        return reject(new Error("No items to add to KOT"));
      }

      // Set the takeaway order
      this.waitforTakeaway = true;
      this.model_kot.net_amount = 0;

      // Calculate net amount from items
      this.itemsArr.forEach((item: any) => {
        this.model_kot.net_amount += item.amount;
      });

      // Ensure items array is not empty
      if (!this.itemsArr.length) {
        notyf.error("Add at least one item to continue");
        this._generating_sales_order = false;
        this.waitforkot = false;
        return reject(new Error("No items to add to KOT"));
      }

      // Setting up KOT model
      this._generating_sales_order = true;
      this.model_kot.items_details = this.itemsArr;
      this.model_kot.table_id = this.table_id;
      this.model_kot.ledger_id = 0;
      this.model_kot.orderMode = "Take Away";
      this.model_kot.instruction = this.form.get("instruction")?.value || null;

      // Make API call to add sales order
      this.apiService.addSalesOrder(this.model_kot).subscribe(
        (result: any) => {
          if (result) {
            this.soIdArr.push(result.transaction_id);
            this.model_kot.trans_no = result.max_trans_value;
            this.model_kot.transaction_id = result.transaction_id;
            this.model_kot.trans_type = "SalesOrder";

            // Trigger download
            this.downloadClick(this.model_kot, this.model_kot.trans_no);

            // Reset KOT model and form
            this.model_kot = { trans_date: new Date(), net_amount: 0 };
            this.kot_Dta.push(this.itemsArr);
            notyf.success("New KOT added successfully");
            this.waitforTakeaway = false;
            this.form.get("instruction")?.reset();

            // Resolve the promise on success
            resolve();
          } else {
            notyf.error(result.message);
            this.waitforTakeaway = false;
            reject(new Error("Failed to add KOT"));
          }
        },
        (error: any) => {
          this._generating_sales_order = false;
          notyf.error("Unable to add new KOT");
          this.waitforTakeaway = false;
          reject(error); // Reject on API error
        }
      );
    });
  }

  toggleFocusOnSitm() {
    if (this.focusState) {
      this.sitm.nativeElement.blur(); // Unfocus the element
    } else {
      this.sitm.nativeElement.focus(); // Focus the element
      this.openDropdown(); // Ensure the dropdown opens when focused
    }
    this.focusState = !this.focusState; // Toggle the focus state
  }

  // Method to open the dropdown programmatically
  openDropdown() {
    // Trigger the dropdown to open
    const event = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    this.sitm.nativeElement.dispatchEvent(event);
  }

  receiptDialogRef: MatDialogRef<ReceiptDialogComponent> | null = null; // Store the reference to the receipt dialog

  openReceiptPopup() {
    if (this.receiptDialogRef && this.receiptDialogRef.getState() !== 2) {
      // If the receipt dialog is already open, close it
      this.receiptDialogRef.close();
      this.receiptDialogRef = null;
    } else {
      // If the receipt dialog is not open, open it
      if (this.dueInvoice.length > 0) {
        const firstInvoice = this.dueInvoice[0]; // Get the first invoice

        // Open the Receipt dialog and pass the first invoice data
        this.receiptDialogRef = this.dialog.open(ReceiptDialogComponent, {
          data: firstInvoice.display_trans_no, // Pass the invoice data here
          width: "600px",
        });

        // Wait for the dialog to open and then focus on the UPI input
        this.receiptDialogRef.afterOpened().subscribe(() => {
          setTimeout(() => {
            this.focusOnUPIInput();
          }, 300); // Adjust the timeout duration if necessary
        });

        this.receiptDialogRef.afterClosed().subscribe(() => {
          this.receiptDialogRef = null; // Reset the reference after closing
          this.tableInfoFun();
        });
      } else {
        console.error("No invoices available to load");
      }
    }
  }

  focusOnUPIInput() {
    if (this.upiInput && this.upiInput.nativeElement) {
      const netAmount = this.form.get("net_amount")?.value || 0; // Ensure net_amount is not null
      this.upiInput.nativeElement.focus();
      this.upiInput.nativeElement.value = netAmount; // Set the value to the net amount
      this.form.get("upi_receive")?.setValue(netAmount); // Update the form control
    }
  }

  toggleAutoSettlement(): void {
    // Your existing toggle logic
    this.showAdvancedSettings = !this.showAdvancedSettings;

    if (this.showAdvancedSettings) {
      setTimeout(() => {
        const netAmount = this.form.get("net_amount")?.value || 0; // Ensure net_amount is not null
        this.cashInput.nativeElement.focus();
        this.cashInput.nativeElement.value = netAmount; // Set the value to the net amount
        this.previousCashReceive = this.form.get("cash_receive")?.value || 0; // Store the previous value
        this.form.get("cash_receive")?.setValue(netAmount); // Update the form control
      }, 0);
    } else {
      // Revert to previous cash receive amount
      this.form.get("cash_receive")?.setValue(this.previousCashReceive);
      this.cashInput.nativeElement.value = this.previousCashReceive;
    }
  }

  // Function to move focus to the contact no input field
  focusContactNo() {
    this.contactNoInput.nativeElement.focus();
  }

  togglePopup() {
    this.showPopup = !this.showPopup;
    if (this.showPopup) {
      setTimeout(() => {
        const searchInput = document.getElementById("popup-search-input");
        searchInput?.focus();
      }, 100);
    }
  }

  searchItems(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredOptions = of(this.filterItems(query));
  }

  filterItems(query: string): any[] {
    return this.itemsArr.filter((item: any) =>
      item.item_name.toLowerCase().includes(query.toLowerCase())
    );
  }

  selectItem(event: MatAutocompleteSelectedEvent) {
    const selectedItem = event.option.value;
    this.addItemToOrderList(selectedItem);
    setTimeout(() => {
      const searchInput = document.getElementById("popup-search-input");
      searchInput?.focus();
    }, 100);
  }

  addItemToOrderList(item: any) {
    const qty = this.form.get("item_qty")?.value || 1;
    const rate = this.form.get("item_rate")?.value || 0;
    const amount = rate * qty;
    this.orderList.push({ ...item, qty, amount });
    this.form.reset({ item_qty: 1, item_rate: 0, item_amt: 0 });

    const existingItem = this.itemsArr.find(
      (i: any) => i.item_id === item.item_id
    );
    if (existingItem) {
      existingItem.qty++;
      existingItem.amount = existingItem.qty * existingItem.s_rate;
    } else {
      item.qty = 1;
      item.amount = item.s_rate;
      this.itemsArr.push(item);
    }
    this.calculateTotalAmount();
  }

  moveToKotList() {
    this.kotList.push(...this.orderList);
    this.orderList = [];
    this.showPopup = false;
  }

  closePopup(event?: MouseEvent) {
    if (event) {
      const target = event.target as HTMLElement;
      if (!target.closest(".popup-content")) {
        this.showPopup = false;
      }
    } else {
      this.showPopup = false;
    }
  }

  calculateTotalAmount() {
    // this.totalAmount = this.itemsArr.reduce((sum: any, item: any) => sum + item.amount, 0);
  }

  updateItemQty(event: any, item: any) {
    item.qty = event.target.value;
    item.amount = item.qty * item.s_rate;
    this.calculateTotalAmount();
  }

  generateInvoice() {
    notyf.success("Invoice generated successfully!");
    // Add logic to generate invoice
  }

  toggleAdvancedSettings() {
    this.toggleAutoSettlement();
  }

  redirectToKeyboard() {
    const currentUrl = this.router.url;
    const newUrl = currentUrl.replace("rst-keyboard", "rst-invoice");
    this.router.navigateByUrl(newUrl);
  }

  dialogRef: MatDialogRef<TableChangeDialogComponent> | null = null; // Store the reference to the dialog

  changeTable(): void {
    if (this.dialogRef && this.dialogRef.getState() !== 2) {
      // Close the dialog if it's open
      this.dialogRef.close();
      this.dialogRef = null;
    } else {
      // Open the dialog if it's not already open
      this.apiService.getTableListWithKOT().subscribe((tables: any[]) => {
        this.dialogRef = this.dialog.open(TableChangeDialogComponent, {
          width: "40%",
          data: { tables },
        });

        this.dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            // Check if the selected table is different from the current table
            if (result.id !== this.table_id) {
              // Update the table name and capacity with the selected table
              this.table_name = result.name;
              this.tableCapacity = result.capacity;
              this.table_id = result.id;

              // Reload the page with the selected table's ID
              const newUrl = `/rst-keyboard/${result.id}`; // Adjust the URL as needed
              this.router.navigateByUrl(newUrl).then(() => {
                window.location.reload();
              });
            }
          }
          this.dialogRef = null; // Reset the dialogRef when dialog is closed
        });
      });
    }
  }
}
